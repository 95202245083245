import React from 'react'
import { useNavigate } from "react-router-dom";


export function navigateTo(url: string) {
  // const navigate = useNavigate();
  window.location.href = window.location.pathname + '/' + url;
  // navigate(url);
}

export function getLastParams({location}: any) {
    if(location?.pathname) {
      const path = location.pathname.replace(/\/+$/, '');
      const pathSegments = path.split("/");
      return pathSegments?.[pathSegments.length - 1] || null;
    }
    return null;
}