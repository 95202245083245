import { useCallback, useContext, useRef } from "react";
import SidebarContext from "../context/SidebarContext";
import { useTheme } from "@mui/system";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Collapse } from "@mui/material";
import classes from "../styles/sideBarStyles.module.css";
import { useNavigate } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { ExpandMore } from "@mui/icons-material";
import { SidebarMenuItemInterface } from "../Interfaces/SideBarInterfaces";
//@ts-ignore
import { useLayoutStore } from "../store/LayoutStore";
import SearchBox from "../core-components/theme/components/Searchbox/Searchbox";

interface PropsInterface {
  sideBarMenu: SidebarMenuItemInterface[];
  refresh: Function;
  close: Function;
}

const filterMenu = (menuItems: any[], searchString: string): any[] => {
  const lowerSearch = searchString.toLowerCase();
  return menuItems.reduce((acc, item) => {
    const nameMatches = item.name.toLowerCase().includes(lowerSearch);
    let newItem = { ...item };

    if (item.children) {
      newItem.children = filterMenu(item.children, searchString);
    }

    if (nameMatches || (newItem.children && newItem.children.length > 0)) {
      acc.push(newItem);
    }
    return acc;
  }, [] as any[]);
};

export default function MiniDrawer(props: PropsInterface) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { open, setOpen } = useContext(SidebarContext);
  const [searchString, setSearchString] = useState<string>('');
  const [menuItemState, setMenuItemState] = useState<{
    [key: string]: boolean;
  }>({});
  const cacheRef = useRef({});
  const { refresh, close, sideBarMenu } = props;

  const getFilteredMenu = useCallback((menu: any, searchString: string) => {
    const cache: any = cacheRef.current;
    const cacheKey = searchString.toLowerCase();
    if (cache[cacheKey]) {
      return cache[cacheKey];
    }

    const filteredMenu = filterMenu(menu, searchString);
    cache[cacheKey] = filteredMenu;
    return filteredMenu;
  }, []);

  const filteredMenu = getFilteredMenu(sideBarMenu, searchString);

  useEffect(() => {}, []);

  const handleClick = (name: string) => {
    /// menuItemState stores the dropdown state of sidebar menuItems
    if (!menuItemState[name]) menuItemState[name] = true;
    else menuItemState[name] = !menuItemState[name];
    setMenuItemState(menuItemState);
    refresh();
  };

  const menuItem = (item: SidebarMenuItemInterface) => {
    if (item.children) {
      return (
        <>
          <ListItem
            className={classes.menuItem}
            onClick={() => handleClick(item.name)}
          >
            {/* {obj.itemType === "plantName" && (
            <ListItemIcon>
               <PlaceIcon className={classes.icon} />
            </ListItemIcon>
            )} */}

            <ListItemText primary={item.name} className={classes.icon} />

            {menuItemState[item.name] ? (
              <ExpandMore className={classes.icon} />
            ) : (
              <ChevronRightIcon className={classes.icon} />
            )}
          </ListItem>
          <Collapse in={menuItemState[item.name]} timeout="auto" unmountOnExit>
            <div style={{ paddingLeft: 30 }}>
              {item.children.map((child, index) => {
                return menuItem(child);
              })}
            </div>
          </Collapse>
        </>
      );
    } else {
      return (
        <>
          <ListItem
            key={item.name}
            className={classes.menuItem}
            onClick={(e) => {
              e.preventDefault();
              navigate(item.link!);
              close();
            }}
          >
            {/* <ListItemIcon>
              {obj.name === "Data Input" && (
                <Storage className={classes.icon} />
              )}
              {obj.name === "Tasks" && <DataUsage className={classes.icon} />}
              {obj.name === "Video Tutorials" && (
                <VideoLibraryIcon className={classes.icon} />
              )}
              {(obj.name.includes("Report") ||
                obj.name.includes("Analytics")) && (
                <AssessmentIcon className={classes.icon} />
              )}
            </ListItemIcon> */}
            <ListItemText primary={item.name} className={classes.icon} />
          </ListItem>
        </>
      );
    }
  };
  return (
    <>
      <Drawer
        // className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar}>
        <SearchBox onChange={(e) => setSearchString(e.target.value)} onClear={() => setSearchString('')} borderRadius='40px' size='small' placeholder="Search ..."/>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon style={{ color: "#eee" }} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {filteredMenu.map((item: any, index: number) => {
            return menuItem(item);
          })}
        </List>
      </Drawer>
    </>
  );
}
