import {
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MouseEventHandler } from 'react';
import Button from '../Button/Button';

const DialogBoxStyle = styled(MuiDialog, {
  shouldForwardProp: prop => prop !== 'width' && prop !== 'maxWidth',
})<{
  width?: string | number;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string | number;
  minHeight?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string | number;
  height?: string | number;
  dialogStyles?: any;
  minWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | string | number;
  padding?: string | number;
  contentPadding?: string | number;
}>(
  ({
    theme,
    width,
    maxWidth,
    height,
    minHeight,
    minWidth,
    dialogStyles,
    padding,
    contentPadding,
  }) => ({
    '& .MuiDialog-paper': {
      padding: padding || theme.spacing(4),
      margin: 'auto',
      minWidth: minWidth || '200px',
      minHeight: minHeight || '200px',
      width: width || 'auto',
      maxWidth: maxWidth || '1000px',
      borderRadius: '12px',
      height: height || 'auto',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: '16px',
        maxWidth: '100%',
        minWidth: 'auto',
      },
      ...dialogStyles,
    },
    '& .MuiDialogContent-root': {
      padding: contentPadding || '20px 0',
    },
    '& .MuiDialogActions-root': {
      // paddingLeft: theme.spacing(4),
      // paddingRight: theme.spacing(4),
    },
  })
);

const DialogTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  // padding: theme.spacing(0), // Adjusted padding
}));

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  margin: 0,
  // padding: theme.spacing(2),
  paddingRight: 0, // Adjust padding right for close button alignment
  display: 'flex',
  alignItems: 'center',
}));

export default function DialogBox(props: any) {
  const { title, dialogStyles, ...otherProps } = props;
  return (
    <>
      <DialogBoxStyle
        maxWidth={props.maxWidth}
        minWidth={props.minWidth}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        dialogStyles={{ ...dialogStyles }}
        {...otherProps}
      >
        <DialogTitleContainer>
          <DialogTitle
            sx={{ display: 'flex', alignItems: 'center', padding: '0' }}
            id="customized-dialog-title"
          >
            <Typography fontSize={props.TitlefontSize} letterSpacing={0} variant="h1">
              {props.title}
            </Typography>
          </DialogTitle>
          {/* <MuiIconButton aria-label="close" onClick={props.handleClose}>
            <CloseIcon sx={{ color: 'black' }} />
          </MuiIconButton> */}
        </DialogTitleContainer>
        {/* <Divider variant="fullWidth" /> */}

        <MuiDialogContent>{props.children}</MuiDialogContent>
        {props.actions ? (
          <MuiDialogActions sx={{ display: 'flex', justifyContent: 'end', padding: '0', gap: 2 }}>
            {props.actions.map(
              (action: {
                color?: any;
                textColor?: string;
                fullWidth?: boolean;
                variant?: any;
                text: string;
                disabled?: boolean;
                handler: MouseEventHandler<HTMLButtonElement> | undefined;
              }) => (
                <Button
                  sx={{ boxShadow: 'none' }}
                  disabled={action?.disabled}
                  color={action?.color}
                  variant={action?.variant}
                  fullWidth={action?.fullWidth}
                  onClick={action.handler}
                >
                  {action.text}
                </Button>
              )
            )}
          </MuiDialogActions>
        ) : null}
      </DialogBoxStyle>
    </>
  );
}
