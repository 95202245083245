import * as joint from '@joint/plus';
const LEVEL_FLAG = 'level-flag';

export class OS_TANK_SC extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "OS_TANK_SC",
      equipmentType: 'OS_TANK',
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        root: {
            magnet: true
        },
        liquid: {
            x: 4,
            y: 60.6219,
            width: 242,
            height: 156.292,
            fill: "#3DA3F5"
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect @selector="liquid"/>
        <path d="M4 32.3109V214.587C4 216.3 5.38907 217.689 7.10257 217.689H242.897C244.611 217.689 246 216.3 246 214.587V32.3109" stroke="#7A7A7A" stroke-width="3.10256" stroke-linecap="round"/>
        <path d="M60.9154 217.045L116.753 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M66.2931 217.045L122.13 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M71.671 217.045L127.508 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M77.0487 217.045L132.886 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M82.4264 217.045L138.264 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M87.8043 217.045L143.642 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M93.182 217.045L149.019 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M98.5599 217.045L154.397 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M103.938 217.045L159.775 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M109.412 217.045L165.249 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M114.886 217.045L170.724 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M120.361 217.045L176.198 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M125.739 217.045L181.576 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M131.116 217.045L186.954 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M136.494 217.045L192.332 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M141.872 217.045L197.709 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M147.25 217.045L203.087 40.8677" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M152.724 216.95L208.562 40.7732" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M158.102 216.95L213.939 40.7732" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488"/>
        <path d="M111.287 40.6388H216.96" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="3.22667" stroke-linecap="round"/>
        <path d="M109.673 46.0165H215.347" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M108.06 51.3943H213.733" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M106.447 56.7721H212.12" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M104.833 62.1498H210.507" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M103.22 67.5276H208.893" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M101.607 72.9054H207.28" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M99.9934 78.2832H205.667" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M98.3799 83.661H204.053" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M96.7666 89.0388H202.44" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M95.1533 94.4166H200.827" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M93.54 99.7943H199.213" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M91.9268 105.172H197.6" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M90.3132 110.55H195.987" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M88.7 115.928H194.373" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M87.0867 121.305H192.76" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M85.4734 126.683H191.147" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M83.8601 132.061H189.533" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M82.2466 137.439H187.92" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M80.6333 142.817H186.307" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M79.02 148.194H184.693" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M77.4067 153.572H183.08" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M75.7932 158.95H181.467" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M74.1799 164.328H179.853" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M72.5667 169.705H178.24" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M70.9534 175.083H176.627" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M69.3401 180.461H175.013" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M67.7266 185.839H173.4" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M66.1133 191.217H171.787" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M64.5 196.594H170.173" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M62.8867 201.972H168.56" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M61.2734 207.35H166.947" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        <path d="M59.6599 212.728H165.333" stroke="#7A7A7A" stroke-opacity="0.8" stroke-width="1.5488" stroke-linecap="round"/>
        </svg>
       `;
  }
}



export class OS_TANK_SCView extends joint.dia.ElementView {
    // @ts-ignore
    presentationAttributes = joint.dia.ElementView.addPresentationAttributes({
        level: [LEVEL_FLAG],
        liquidColor: [LEVEL_FLAG],
    });

    // @ts-ignore
    // initFlag = [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    initFlag() {
        return [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    }
    confirmUpdate(...args: any[]): any {
        // @ts-ignore
        let flags = joint.dia.ElementView.prototype.confirmUpdate.call(this, ...args);
        if (this.hasFlag(flags, LEVEL_FLAG)) {
            this.updateLevel();
            flags = this.removeFlag(flags, LEVEL_FLAG);
        }
        return flags;
    }

    getElement(element: any) {
        return this.findNode(element)
    }

    updateLevel() {
        const model = this.model;
        const level = Math.max(0, Math.min(100, model.get('level') || 0));
        const liquidColor = model.get('liquidColor') || '#DBA670';

        const liquidEl = this.getElement('liquid');
        const tankEl = this.getElement('tank');

        // @ts-ignore
        const tankBBox = tankEl.getBBox();
        const tankHeight = tankBBox.height;
        const tankBottom = tankBBox.y + tankHeight;
        const maxLiquidHeight = tankHeight * 0.85;
        const newHeight = (maxLiquidHeight * level) / 100;

        // Calculate the y position (from bottom up)
        const newY = tankBottom - newHeight;

        // Animate the liquid element
        liquidEl?.animate(
            {
                height: [`${newHeight}px`],
                y: newY,
                fill: liquidColor,
            },
            {
                fill: 'forwards',
                duration: 800,
                easing: 'ease-in-out',
            }
        );
    }
}