import { useQuery } from "@tanstack/react-query";
import { getQuestionsJointGraph } from "../../../../services/jointjsConfig";

export const useFetchQuestionsAsset = (questionData: any) => {
  return useQuery({
    queryKey: questionData?.queryKey,
    queryFn: () => getQuestionsJointGraph({
      path: questionData?.path,
      payload: questionData?.payload
    }),
    refetchOnWindowFocus: false,
    enabled: questionData?.isEnabled
  });
};