import * as joint from '@joint/plus';

export class CL_TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "CL_TANK",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        outlet: {
          d: "M239.359 57.4197L223.57 52.5126V46.1706L239.359 41.2636C239.527 41.2849 239.658 41.4288 239.658 41.6032V57.0801C239.658 57.2545 239.527 57.3984 239.359 57.4197Z" ,
          fill: "white",
          stroke: "#818181",
          strokeWidth: 0.68461,
          strokeLinecap: "round",
          magnet: true
        },
        inlet: {
          d: "M117.103 25.3402L117.122 25.3423H117.141C118.426 25.3423 119.258 25.6153 119.775 26.1322C120.291 26.6491 120.564 27.4804 120.564 28.7654V145.301C120.564 145.49 120.411 145.643 120.222 145.643H114.745C114.556 145.643 114.403 145.49 114.403 145.301V32.5307C114.403 31.9636 113.943 31.5038 113.376 31.5038H11C10.6368 31.5038 10.3423 31.2093 10.3423 30.8461V15.2365C10.3423 14.2479 11.2021 13.4791 12.1845 13.5891L117.103 25.3402Z",
          fill: "white",
          stroke: "#7A7A7A", 
          strokeWidth: 0.68461,
          strokeLinecap: "round",
          magnet: true
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
          <path d="M10.6848 52.7266V161.024C10.6848 164.447 12.8071 167.511 16.0115 168.715L108.199 203.341L111.765 212.583H122.86L126.426 203.341L218.614 168.715C221.818 167.511 223.941 164.447 223.941 161.024V52.7266H10.6848Z" fill="url(#paint0_linear_3092_5845)"/>
          <path d="M141.098 196.09L141.098 180.915L205.696 156.598L205.696 171.772L141.098 196.09Z" fill="#D9D9D9" stroke="#808080" stroke-width="0.68461"/>
          <path d="M141.541 190.798L141.541 185.224L205.428 161.13L209.553 163.375L205.283 166.758L141.541 190.798Z" fill="#808080" stroke="#808080" stroke-width="0.68461"/>
          <path d="M93.551 196.09L93.551 180.915L28.9531 156.598L28.9531 171.772L93.551 196.09Z" fill="#D9D9D9" stroke="#808080" stroke-width="0.68461"/>
          <path d="M93.1086 190.798L93.1084 185.224L29.2211 161.13L25.0967 163.375L29.3661 166.758L93.1086 190.798Z" fill="#808080" stroke="#808080" stroke-width="0.68461"/>
          <path d="M110.411 101.998L36.1313 159.163" stroke="#808080" stroke-width="0.68461"/>
          <path d="M124.436 102.019L198.716 159.184" stroke="#808080" stroke-width="0.68461"/>
          <path d="M118.168 211.214H116.799C116.232 211.214 115.772 210.754 115.772 210.187V55.1228C115.772 54.5556 116.232 54.0958 116.799 54.0958H118.168C118.735 54.0958 119.195 54.5556 119.195 55.1228V210.187C119.195 210.754 118.735 211.214 118.168 211.214Z" fill="white" stroke="#7A7A7A" stroke-width="0.68461" stroke-linecap="round"/>
          <path @selector="inlet" />
          <path d="M121.591 218.745H155.822C156.011 218.745 156.164 218.898 156.164 219.087V224.564C156.164 224.753 156.011 224.906 155.822 224.906H117.826C116.541 224.906 115.71 224.633 115.193 224.116C114.676 223.599 114.403 222.768 114.403 221.483V209.16C114.403 208.971 114.556 208.818 114.745 208.818H120.222C120.411 208.818 120.565 208.971 120.565 209.16V217.718C120.565 218.285 121.024 218.745 121.591 218.745Z" fill="white" stroke="#7A7A7A" stroke-width="0.68461" stroke-linecap="round"/>
          <rect x="0.68461" y="-0.68461" width="7.87301" height="5.47688" rx="0.68461" transform="matrix(1 0 0 -1 112.692 209.845)" fill="#D9D9D9" stroke="#808080" stroke-width="1.36922"/>
          <path d="M93.8645 39.0344H141.103V56.492C141.103 57.8153 140.03 58.8881 138.706 58.8881H96.2607C94.9373 58.8881 93.8645 57.8153 93.8645 56.492V39.0344Z" fill="#D9D9D9" stroke="#808080" stroke-width="0.68461"/>
          <rect x="110.295" y="100.649" width="14.3768" height="2.39613" rx="1.02691" fill="white" stroke="#7A7A7A" stroke-width="0.68461" stroke-linecap="round"/>
          <path d="M109.476 202.848L109.255 202.275L108.68 202.059L16.4924 167.433C13.8221 166.43 12.0535 163.877 12.0535 161.024V45.196C12.0535 41.415 15.1186 38.3499 18.8996 38.3499H215.725C219.506 38.3499 222.571 41.415 222.571 45.196V161.024C222.571 163.877 220.802 166.43 218.132 167.433L125.944 202.059L125.369 202.275L125.148 202.848L121.92 211.214H112.704L109.476 202.848Z" stroke="#818181" stroke-width="2.73844"/>
          <rect x="93.5224" y="183.145" width="47.5804" height="10.2691" fill="#D9D9D9" stroke="#808080" stroke-width="0.68461"/>
          <rect width="6.44576" height="2.15316" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 136.55 35.1162)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <rect width="6.44576" height="2.15316" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 123.164 35.1162)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <rect width="7.68006" height="0.274288" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 123.37 35.7334)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M138.703 32.8807C138.703 34.0698 137.739 35.0338 136.55 35.0338L136.55 28.7389C137.739 28.7389 138.703 29.7029 138.703 30.8921L138.703 32.8807Z" fill="url(#paint1_linear_3092_5845)" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M135.584 33.9778C135.696 33.9778 135.791 34.054 135.816 34.1566C135.823 34.186 135.798 34.2109 135.768 34.2109H135.178C135.148 34.2109 135.123 34.186 135.13 34.1566C135.155 34.054 135.25 33.9778 135.363 33.9778H135.584Z" fill="white" stroke="#181818" stroke-width="0.0822863"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M135.584 32.5103C135.696 32.5103 135.791 32.5865 135.816 32.6891C135.823 32.7185 135.798 32.7434 135.768 32.7434H135.178C135.148 32.7434 135.123 32.7185 135.13 32.6891C135.155 32.5865 135.25 32.5103 135.363 32.5103H135.584Z" fill="white" stroke="#181818" stroke-width="0.0822863"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M135.584 31.0428C135.696 31.0428 135.791 31.1191 135.816 31.2216C135.823 31.2511 135.798 31.276 135.768 31.276H135.178C135.148 31.276 135.123 31.2511 135.13 31.2216C135.155 31.1191 135.25 31.0428 135.363 31.0428H135.584Z" fill="white" stroke="#181818" stroke-width="0.0822863"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M135.584 29.5753C135.696 29.5753 135.791 29.6516 135.816 29.7541C135.823 29.7835 135.798 29.8085 135.768 29.8085H135.178C135.148 29.8085 135.123 29.7835 135.13 29.7541C135.155 29.6516 135.25 29.5753 135.363 29.5753H135.584Z" fill="white" stroke="#181818" stroke-width="0.0822863"/>
          <rect width="7.10405" height="11.0264" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.396 35.4454)" fill="url(#paint2_linear_3092_5845)" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 35.2972)" fill="#7A7A7A"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 35.0671)" fill="#7A7A7A" fill-opacity="0.8"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 34.8369)" fill="#7A7A7A" fill-opacity="0.8"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 34.6068)" fill="#7A7A7A" fill-opacity="0.8"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 34.3767)" fill="#7A7A7A" fill-opacity="0.8"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 34.1466)" fill="#7A7A7A" fill-opacity="0.8"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 33.9165)" fill="#7A7A7A" fill-opacity="0.8"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 33.6864)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 33.4563)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 33.2261)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 32.996)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 32.7659)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 32.5358)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 32.3057)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 32.0756)" fill="#7A7A7A" fill-opacity="0.6"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 31.8455)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 31.6152)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 31.3851)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 31.155)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 30.9249)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 30.6948)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 30.4647)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 30.2346)" fill="#7A7A7A" fill-opacity="0.4"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 30.0044)" fill="#7A7A7A" fill-opacity="0.2"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 29.7743)" fill="#7A7A7A" fill-opacity="0.2"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 29.5442)" fill="#7A7A7A" fill-opacity="0.2"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 29.3141)" fill="#7A7A7A" fill-opacity="0.2"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 29.084)" fill="#7A7A7A" fill-opacity="0.2"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 28.8539)" fill="#7A7A7A" fill-opacity="0.1"/>
          <rect width="0.076707" height="11.0075" rx="0.0283226" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 134.389 28.6238)" fill="#7A7A7A" fill-opacity="0.1"/>
          <rect width="0.891435" height="9.57552" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 133.659 30.1105)" fill="#181818"/>
          <rect width="0.397717" height="9.57552" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 133.683 32.0852)" fill="#181818"/>
          <rect width="1.44001" height="5.47523" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 132.812 32.6064)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <rect width="0.891435" height="9.57552" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 133.659 34.554)" fill="#181818"/>
          <path d="M132.97 36.8855C133.152 36.8855 133.3 36.7381 133.3 36.5564L133.3 35.5003C133.3 35.47 133.275 35.4455 133.245 35.4455L127.32 35.4455C127.29 35.4455 127.265 35.47 127.265 35.5003L127.265 36.5564C127.265 36.7381 127.413 36.8855 127.594 36.8855L132.97 36.8855Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <circle cx="0.548576" cy="0.548576" r="0.548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 132.586 36.7072)" fill="#181818"/>
          <path d="M133.245 28.3416C133.275 28.3416 133.3 28.317 133.3 28.2867L133.3 27.2307C133.3 27.0489 133.152 26.9015 132.97 26.9015L127.594 26.9015C127.413 26.9015 127.265 27.0489 127.265 27.2307L127.265 28.2867C127.265 28.317 127.29 28.3416 127.32 28.3416L133.245 28.3416Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <circle cx="0.548576" cy="0.548576" r="0.548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 132.586 28.1632)" fill="#181818"/>
          <path d="M123.075 34.1013L121.018 34.1013" stroke="#7A7A7A" stroke-width="0.109715" stroke-linecap="square"/>
          <path d="M123.075 33.6625L121.018 33.6625" stroke="#7A7A7A" stroke-width="0.109715" stroke-linecap="square"/>
          <path d="M123.075 31.1664L121.018 31.1664" stroke="#7A7A7A" stroke-width="0.109715" stroke-linecap="square"/>
          <rect width="2.60573" height="1.19315" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 122.684 33.1962)" fill="white" stroke="#7A7A7A" stroke-width="0.0983111"/>
          <rect width="0.521147" height="0.548576" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 122.643 35.4454)" fill="#181818"/>
          <rect width="0.521147" height="0.548576" rx="0.0548576" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 122.643 28.8624)" fill="#181818"/>
          <path @selector="outlet" />
          <defs>
          <linearGradient id="paint0_linear_3092_5845" x1="117.313" y1="52.7266" x2="117.313" y2="212.583" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3DA3F5"/>
          <stop offset="0.529" stop-color="#0A70C2"/>
          <stop offset="0.764" stop-color="#664019"/>
          </linearGradient>
          <linearGradient id="paint1_linear_3092_5845" x1="137.626" y1="28.7389" x2="137.626" y2="35.0338" gradientUnits="userSpaceOnUse">
          <stop stop-color="#8A8A8A"/>
          <stop offset="0.1" stop-color="#B1B1B1"/>
          <stop offset="0.2" stop-color="#E3E3E3"/>
          <stop offset="0.4" stop-color="white"/>
          <stop offset="0.6" stop-color="white"/>
          <stop offset="0.8" stop-color="#E3E3E3"/>
          <stop offset="0.9" stop-color="#B1B1B1"/>
          <stop offset="1" stop-color="#848685"/>
          </linearGradient>
          <linearGradient id="paint2_linear_3092_5845" x1="7.10405" y1="5.51318" x2="0" y2="5.51318" gradientUnits="userSpaceOnUse">
          <stop stop-color="#8A8A8A"/>
          <stop offset="0.1" stop-color="#B1B1B1"/>
          <stop offset="0.2" stop-color="#E3E3E3"/>
          <stop offset="0.4" stop-color="white"/>
          <stop offset="0.6" stop-color="white"/>
          <stop offset="0.8" stop-color="#E3E3E3"/>
          <stop offset="0.9" stop-color="#B1B1B1"/>
          <stop offset="1" stop-color="#848685"/>
          </linearGradient>
          </defs>
        </svg>
       `;
  }
}