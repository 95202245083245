import { useQuery } from "@tanstack/react-query";
import { getAssetGraph, getQuestionsJointGraph } from "../services/jointjsConfig";


export const useFetchGraphDetails = (payload: any, enabled: boolean ) => {
  let queryKey = ['getAssetGraph', (payload?.payload ? payload.payload : '')];
  console.log('payload', payload);;
  return useQuery({
    queryKey: queryKey,
    queryFn: () => getAssetGraph(payload),
    enabled: enabled,
    refetchOnWindowFocus: false
  });
};

export const useFetchQuestionForJointGraph = (payload: any,) => {
  return useQuery({
    queryKey: [payload],
    queryFn: () => getQuestionsJointGraph(payload),
    refetchOnWindowFocus: false
  });
}