import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, CircularProgress, Checkbox } from '@mui/material';
import { QueryClient, useQuery } from '@tanstack/react-query';
import { getAutoCompleteList } from '../../services/genricService';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

interface Option {
    [key: string]: any,
    name?: any
  }

interface PropsInterface {
    onSelect: (value: any, event: any) => void,
    apiPath: string,
    multiple?: boolean,
    selectedOptions?: Option[] | null | any,
    defaultData?: Option,
    searchKey?: string,
    searchedValue?: string | undefined | null,
    [key: string]: any;
    label: string,
}


const ApiBasedAutoComplete = ({ onSelect, searchedValue = '', apiPath='', searchKey='name', keyToPick, idKey, limitTags, label, defaultData={}, multiple, selectedOptions = [], customActionMethod = 'POST', hideCheckbox,  ...props }: PropsInterface) => {
    const [inputValue, setInputValue] = useState<any>('');
    const [debouncedQuery, setDebouncedQuery] = useState<string>('');
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);
    const queryClient = new QueryClient();

    const { data, isLoading, isError, refetch } = useQuery({
            queryKey: ['searchResults', apiPath, debouncedQuery],
            queryFn: () => getAutoCompleteList(apiPath, customActionMethod, {[searchKey]: inputValue || null, ...defaultData}),
            refetchOnWindowFocus: false,
            enabled: (debouncedQuery?.length > 0)
    });
  
    const handleInputChange = (event: any) => {
      const value = event.target.value;
      setInputValue(value);
  
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
  
      const timeoutId = setTimeout(() => {
        setDebouncedQuery(value);
      }, 500);
  
      setDebounceTimeout(timeoutId);
    };
  
    const handleSelect = (event: any, newValue: any) => {
      onSelect(newValue, event);
    };

    const handleBlur = () => {
      if (debouncedQuery) {
        invalidateQuery()
      }
    }

    const invalidateQuery = () => {
      queryClient.removeQueries({queryKey: ['searchResults', apiPath, debouncedQuery]})
      setDebouncedQuery('');
    }

    const options = data?.data || [];

    function handleFocus() {
      refetch();
    }
  
    return (
        <Autocomplete
          onChange={handleSelect}
          options={options}
          value={selectedOptions}
          blurOnSelect
          multiple={multiple}
          limitTags={limitTags || -1}
          filterOptions={(x) => x}
          getOptionLabel={(option: any) => {
              if(typeof option === 'string') return option;
              return `${option?.[keyToPick]}`
            }
          }
            isOptionEqualToValue={(option, value) => option?.[idKey] === value?.[idKey]}
            renderOption={(props, option, { selected }) => {
              return (
                  <li {...props} key={option?.[idKey]}>
                    {
                      multiple && !hideCheckbox &&
                      <span>
                        <Checkbox
                          icon={icon}
                          checked={selected}
                          style={{ marginRight: 8 }}
                        />
                      </span>
                    }
                    <span>{option?.[keyToPick] || ''}</span>
                  </li>
              )
            }
            }
          renderInput={(params) => (
            <>
              <TextField
                {...params}
                label={label}
                variant={props?.variant || "outlined"}
                fullWidth
                onBlur={handleBlur}
                onChange={(value) => handleInputChange(value)}
                value ={params.inputProps}
                error={props?.error}
                onFocus={handleFocus}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {
                        isLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          params.InputProps.endAdornment
                        )
                      }
                    </React.Fragment>
                  ),
                }}
              />
            </>
          )}
          noOptionsText={isLoading ? 'Loading...' : isError ? 'Error fetching data' : options.length === 0 ? 'No results found' : 'Type to search'}
          {...props}
          
        />
    );
  };
  
  export default ApiBasedAutoComplete;