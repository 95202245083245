import { Button, Stack } from "@mui/material";
import FormField from "../../../../../components/formFieldCollection";
import { useState } from "react";
import { Primary } from "../../../../../core-components/theme/color-palette/colors";

const RenderInspectorQuestions = ({questionsData, handleSaveAnswers}: any) => {
  const [formData, setFormData] = useState<any>(questionsData);  

  const handleChange = ({name, newValue, type, index}: any) => {
    const newFormData = [...formData];
    newFormData[index][name] = newValue;
    setFormData(newFormData);
  }

  return (
    <Stack rowGap={6}>
       {
          formData && formData?.length > 0 && formData?.map((question: any, index: number) => {
            return (
              <Stack rowGap={2} key={index}>
                <FormField key={question?._id} {...question} onChange={(name, newValue) => {handleChange({name, newValue, type: question?.type, index})}}  />
              </Stack>
            )
          })
        }
        <Button
          variant="contained"
          size="small"
          sx={{
            border: `1px solid ${Primary[500]}`,
            borderRadius: '4px',
            // padding: '10px 70px',
            backgroundColor: `${Primary[500]}`,
            color: '#fff',
            // flex: 1,
            boxShadow: 'none',
            ':hover': {backgroundColor: `${Primary[500]}`, boxShadow: 'none' },
          }}
          onClick={() => {handleSaveAnswers(formData)}}
        >
          Add
        </Button>
    </Stack>
  )
}

export default RenderInspectorQuestions;