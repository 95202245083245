import * as joint from '@joint/plus';

export class DE_TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'DE_TANK',
      size: {
        width: 250,
        height: 250,
      },
      attrs: {
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <rect x="124.727" y="91.1858" width="105.737" height="91.8352" fill="#3DA3F5"/>
              <path d="M124.614 74.5513V183.477H228.641C229.648 183.477 230.464 182.661 230.464 181.654V74.5513" stroke="#7A7A7A" stroke-width="1.82303" stroke-linecap="round"/>
              <rect x="18.9905" y="91.1858" width="105.737" height="91.8352" fill="#3DA3F5"/>
              <path d="M18.8767 74.5513V181.654C18.8767 182.661 19.6929 183.477 20.6997 183.477H124.727V74.5513" stroke="#7A7A7A" stroke-width="1.82303" stroke-linecap="round"/>
              <path d="M18.7263 86.0586L230.464 86.0586" stroke="#7A7A7A" stroke-width="1.82303" stroke-linecap="square"/>
              <path d="M59.8245 170.366L59.8245 176.243L66.6127 176.243L66.6127 170.366L59.8245 170.366Z" fill="url(#paint0_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 58.0242 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M42.8462 170.366L42.8462 176.243L49.6345 176.243L49.6345 170.366L42.8462 170.366Z" fill="url(#paint1_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 41.0457 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M93.7805 170.366L93.7805 176.243L100.569 176.243L100.569 170.366L93.7805 170.366Z" fill="url(#paint2_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 91.9807 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M76.8027 170.366L76.8027 176.243L83.591 176.243L83.591 170.366L76.8027 170.366Z" fill="url(#paint3_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 75.0022 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M36.1955 176.188C36.1955 175.525 36.7328 174.987 37.3957 174.987H106.208C106.871 174.987 107.408 175.525 107.408 176.188C107.408 176.85 106.871 177.388 106.208 177.388H37.3957C36.7328 177.388 36.1955 176.85 36.1955 176.188Z" fill="white" stroke="#7A7A7A" stroke-width="0.800144" stroke-linecap="round"/>
              <path d="M165.561 170.366L165.561 176.243L172.35 176.243L172.35 170.366L165.561 170.366Z" fill="url(#paint4_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 163.761 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M148.583 170.366L148.583 176.243L155.371 176.243L155.371 170.366L148.583 170.366Z" fill="url(#paint5_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 146.782 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M199.517 170.366L199.517 176.243L206.306 176.243L206.306 170.366L199.517 170.366Z" fill="url(#paint6_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 197.718 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M182.54 170.366L182.54 176.243L189.328 176.243L189.328 170.366L182.54 170.366Z" fill="url(#paint7_linear_3092_7050)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 180.739 167.422)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M141.932 176.188C141.932 175.525 142.47 174.987 143.133 174.987H211.945C212.608 174.987 213.145 175.525 213.145 176.188C213.145 176.85 212.608 177.388 211.945 177.388H143.133C142.47 177.388 141.932 176.85 141.932 176.188Z" fill="white" stroke="#7A7A7A" stroke-width="0.800144" stroke-linecap="round"/>
              <defs>
              <linearGradient id="paint0_linear_3092_7050" x1="59.8245" y1="173.305" x2="66.6127" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_3092_7050" x1="42.8462" y1="173.305" x2="49.6345" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint2_linear_3092_7050" x1="93.7805" y1="173.305" x2="100.569" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint3_linear_3092_7050" x1="76.8027" y1="173.305" x2="83.591" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint4_linear_3092_7050" x1="165.561" y1="173.305" x2="172.35" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint5_linear_3092_7050" x1="148.583" y1="173.305" x2="155.371" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint6_linear_3092_7050" x1="199.517" y1="173.305" x2="206.306" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint7_linear_3092_7050" x1="182.54" y1="173.305" x2="189.328" y2="173.305" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
  }
}
