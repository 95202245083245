import { useQuery } from "@tanstack/react-query";
import { getAssetStencil } from "../services/jointjsConfig";

export const useFetchStencilData = (payload: any, enabled: boolean) => {
  return useQuery({
    queryKey: ['getAssetStencil', payload],
    queryFn: () => getAssetStencil(payload),
    refetchOnWindowFocus: false,
    enabled: enabled
  });
};