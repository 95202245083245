import { useMutation } from "@tanstack/react-query";
import { saveGraphLayout } from "../services/jointjsConfig";
import { toast } from "react-toastify";

export const useSaveGraphLayout = ( ) => {
  return useMutation({
    mutationFn: saveGraphLayout,
    onSuccess: data => {
      console.log(data);
      return data
    },
    onError: error => {
      toast.error(error?.message || `Failed to Save Layout`);
    },
  });
};