const BREAKPOINT_OPTIONS: any = {
  xl: { value: 'inherit', displayName: 'Window' },
  lg: { value: 1200, displayName: 'Large Tablet' },
  md: { value: 996, displayName: 'Small Tablet' },
  sm: { value: 768, displayName: 'Mobile' },
  xs: { value: 480, displayName: 'Small Mobile' },
};

const NEW_BREAKPOINT_OPTIONS: any = {
  tablet: '@media (min-width: 768px)',
  desktop: '@media (min-width: 1024px)',
};

export { BREAKPOINT_OPTIONS, NEW_BREAKPOINT_OPTIONS };
