import { useQuery } from "@tanstack/react-query";
import { fetchEquipmentSensorList } from "../../../assetConfiguration/config/scadaConfigurationConfig";

export const useFetchSensorList = (equipmentId: any = '') => {
  return useQuery({
    queryKey: ['equipmentSensorList', equipmentId],
    queryFn: () => fetchEquipmentSensorList({
      equipmentId
    }),
    refetchOnWindowFocus: false,
    enabled: !!equipmentId
  });
};