import { ui, dia, util, shapes } from '@joint/plus';
import { stencilSensors, stencilShapes } from '../config/stencil';
import * as appShapes from '../../shapes/app-shapes';
import { renderEquipment } from '../../visualizationHelper/shapesHelper';
export class StencilService {

    stencil: ui.Stencil;

    constructor(private readonly stencilContainer: HTMLElement | null) { }

    create(paperScroller: ui.PaperScroller, snaplines: ui.Snaplines) {
        this.stencil = new ui.Stencil({
            paper: paperScroller,
            snaplines: snaplines,
            width: 300,
            height: null, 
            // groupsToggleButtons: true,
            overFlowContainer: this.stencilContainer,
            paperOptions: function () {
                return {
                    model: new dia.Graph({}, {
                        cellNamespace: appShapes.namespace
                    }),
                    cellViewNamespace: appShapes.namespace
                };
            },
            search: {
                '*': ['type', 'name']
            },
            layout: {
                columnWidth: "auto",
                columnHeight: 'auto',
                columns: 2,
                rowHeight: "auto",
                rowGap: 30,
                columnGap: 30,
                // marginX: 10,
                // marginY: 10,
                // resizeToFit: true
            },
            dragStartClone: (cell: dia.Cell) => {
                const clone = this.createFromStencilElement(cell);
                clone?.attr({
                    label: {
                        text: cell.get('name')
                    }
                });
                clone?.unset('name')
                return clone;
            },
            el: this.stencilContainer,
            cellCursor: 'grab',
            dropAnimation: {
                duration: 300,
                easing: 'linear'
            },
            dragAnimation: {
                duration: 300,
                easing: 'linear'
            }
        });
        // this.stencil.el.querySelector('.search').placeholder = 'My Placeholder';

        this.stencil.render();
    }

    createFromStencilElement(el: dia.Cell) {
        try {
            if(el.get('customAttr')) {
                return renderEquipment(el.get('type'))
            }
        } catch (error) {
            console.log('Error creating stencil element: ', error);
        }
        
        const clone = el.clone();
        clone.prop(clone.get('targetAttributes'));
        clone.removeProp('targetAttributes');

        return clone;

    }

    setShapes(type: string) {
        if(type === 'sensor') {
                this.stencil.load(this.getStencilSensors());
        }
        else {
            this.stencil.load(this.getStencilShapes());
        }
        // this.stencil.render();
    }

    getStencilShapes() {
        return stencilShapes();
    }

    getStencilSensors() {
        return stencilSensors();
    }
}
