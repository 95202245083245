import * as joint from '@joint/plus';

export class SOFTNER extends joint.dia.Element {
    defaults() {
        return {
            ...super.defaults,
            type: "SOFTNER",
            size: {
                width: 250,
                height: 250
            },
            attrs: {
              
            }

        };
    }

    preinitialize() {
        this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <path d="M78.9478 169.959L78.9478 156.251L82.2189 156.251L82.2189 169.959L78.9478 169.959Z" fill="url(#paint0_linear_2920_864)" stroke="#7A7A7A" stroke-width="0.646037"/>
              <path d="M78.9478 170.426C78.8617 170.426 78.792 170.356 78.792 170.27L78.792 155.784C78.792 155.698 78.8617 155.628 78.9478 155.628V155.628C79.0338 155.628 79.1035 155.698 79.1035 155.784L79.1035 163.027L79.1035 170.27C79.1035 170.356 79.0338 170.426 78.9478 170.426V170.426Z" fill="#7A7A7A" stroke="#7A7A7A" stroke-width="0.646037"/>
              <path d="M101.113 38.0823C100.809 38.0823 100.657 38.0823 100.562 38.1769C100.467 38.2715 100.467 38.4238 100.467 38.7283L100.467 40.3434L104.667 40.3434L104.667 38.7283C104.667 38.4238 104.667 38.2715 104.572 38.1769C104.477 38.0823 104.325 38.0823 104.02 38.0823L101.113 38.0823Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.646037"/>
              <path d="M101.113 25C100.809 25 100.657 25 100.562 25.0946C100.467 25.1892 100.467 25.3415 100.467 25.646L100.467 26.6151C100.467 26.9196 100.467 27.0719 100.562 27.1665C100.657 27.2611 100.809 27.2611 101.113 27.2611L104.02 27.2611C104.325 27.2611 104.477 27.2611 104.572 27.1665C104.667 27.0719 104.667 26.9196 104.667 26.6151L104.667 25.646C104.667 25.3415 104.667 25.1892 104.572 25.0946C104.477 25 104.325 25 104.02 25L101.113 25Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.646037"/>
              <path d="M101.275 27.2612L101.275 38.0824L104.021 38.0824L104.021 27.2612L101.275 27.2612Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.646037"/>
              <path d="M145.771 211.716C145.771 212.325 145.771 212.63 145.581 212.819C145.392 213.008 145.088 213.008 144.478 213.008L109.108 213.008C108.499 213.008 108.194 213.008 108.005 212.819C107.816 212.63 107.816 212.325 107.816 211.716L107.816 210.908C107.816 210.299 107.816 209.995 108.005 209.806C108.194 209.616 108.499 209.616 109.108 209.616L144.478 209.616C145.088 209.616 145.392 209.616 145.581 209.806C145.771 209.995 145.771 210.299 145.771 210.908L145.771 211.716Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.29207"/>
              <path d="M111.596 209.612C110.899 209.612 110.55 209.612 110.455 209.461C110.36 209.309 110.546 208.924 110.918 208.152C111.086 207.804 111.175 207.438 111.175 207.061C111.175 206.684 111.086 206.319 110.918 205.97C110.546 205.199 110.36 204.813 110.455 204.662C110.55 204.51 110.899 204.51 111.596 204.51L141.99 204.511C142.688 204.511 143.036 204.511 143.131 204.662C143.226 204.813 143.04 205.199 142.668 205.97C142.5 206.319 142.411 206.684 142.411 207.061C142.411 207.438 142.5 207.804 142.668 208.152C143.04 208.924 143.226 209.309 143.131 209.461C143.036 209.612 142.688 209.612 141.99 209.612L111.596 209.612Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.29207"/>
              <path d="M145.771 32.8761C145.771 32.267 145.771 31.9624 145.581 31.7732C145.392 31.584 145.088 31.584 144.478 31.584L109.108 31.584C108.499 31.584 108.194 31.584 108.005 31.7732C107.816 31.9624 107.816 32.267 107.816 32.8761L107.816 33.6836C107.816 34.2927 107.816 34.5972 108.005 34.7865C108.194 34.9757 108.499 34.9757 109.108 34.9757L144.478 34.9757C145.088 34.9757 145.392 34.9757 145.581 34.7865C145.771 34.5972 145.771 34.2927 145.771 33.6836L145.771 32.8761Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.29207"/>
              <path d="M111.596 34.9801C110.899 34.9801 110.55 34.9801 110.455 35.1313C110.36 35.2826 110.546 35.6683 110.918 36.4397C111.086 36.7883 111.175 37.154 111.175 37.5308C111.175 37.9077 111.086 38.2734 110.918 38.622C110.546 39.3934 110.36 39.7791 110.455 39.9303C110.55 40.0815 110.899 40.0815 111.596 40.0815L141.99 40.0815C142.688 40.0815 143.036 40.0815 143.131 39.9303C143.226 39.7791 143.04 39.3934 142.668 38.622C142.5 38.2733 142.411 37.9077 142.411 37.5308C142.411 37.154 142.5 36.7883 142.668 36.4397C143.04 35.6683 143.226 35.2826 143.131 35.1313C143.036 34.9801 142.688 34.9801 141.99 34.9801L111.596 34.9801Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.29207"/>
              <path d="M90.1305 200.776L90.1305 196.415L98.852 196.415L98.852 200.776L90.1305 200.776ZM98.852 225C98.852 227.408 96.8996 229.361 94.4912 229.361C92.0828 229.361 90.1305 227.408 90.1305 225L98.852 225ZM98.852 200.776L98.852 225L90.1305 225L90.1305 200.776L98.852 200.776Z" fill="#7A7A7A"/>
              <path d="M163.456 200.774V196.413H154.734V200.774H163.456ZM154.734 223.95C154.734 226.359 156.687 228.311 159.095 228.311C161.504 228.311 163.456 226.359 163.456 223.95H154.734ZM154.734 200.774V223.95H163.456V200.774H154.734Z" fill="#7A7A7A"/>
              <path d="M93.084 37.0925C93.084 36.6465 93.4455 36.2849 93.8915 36.2849H96.9138C97.3598 36.2849 97.7214 36.6465 97.7214 37.0925V42.0865C97.7214 42.5325 97.3598 42.8941 96.9138 42.8941H93.8915C93.4455 42.8941 93.084 42.5325 93.084 42.0865V37.0925Z" fill="#7A7A7A"/>
              <path d="M94.1541 38.6926C94.1541 38.0523 94.6732 37.5332 95.3135 37.5332C95.9538 37.5332 96.4728 38.0523 96.4728 38.6926C96.4728 39.3329 95.9538 39.8519 95.3135 39.8519C94.6732 39.8519 94.1541 39.3329 94.1541 38.6926Z" fill="white"/>
              <path d="M155.865 36.735C155.865 36.289 156.226 35.9275 156.672 35.9275H159.695C160.141 35.9275 160.502 36.289 160.502 36.735V41.7291C160.502 42.1751 160.141 42.5367 159.695 42.5367H156.672C156.226 42.5367 155.865 42.1751 155.865 41.7291V36.735Z" fill="#7A7A7A"/>
              <path d="M156.935 38.3352C156.935 37.6949 157.454 37.1758 158.094 37.1758C158.735 37.1758 159.254 37.6949 159.254 38.3352C159.254 38.9755 158.735 39.4945 158.094 39.4945C157.454 39.4945 156.935 38.9755 156.935 38.3352Z" fill="white"/>
              <rect x="82.3779" y="39.8933" width="88.8301" height="164.957" rx="19.3811" fill="url(#paint1_linear_2920_864)" stroke="#7A7A7A" stroke-width="1.29207"/>
              <defs>
              <linearGradient id="paint0_linear_2920_864" x1="84.5273" y1="156.212" x2="84.5273" y2="169.92" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_864" x1="171.208" y1="207.848" x2="82.3779" y2="207.848" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>

        `;
    }
}