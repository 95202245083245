import { toast } from "react-toastify";
import axiosInstance from "../constants/axiosInstance";
import { ScadaConfigurationPathConstants } from "../pages/visualization/assetConfiguration/assetConstants/pathConstants";


const getAssetStencil = async (reqData: any) => {
  try {
    const { data } = await axiosInstance().post(
      reqData.path,
      {...reqData.payload}
    );
    return data;
  } catch (err) {
    console.error('Stencil Listing api Error');
    throw err;
  }
};

const getAssetGraph = async (reqData: any) => {
  try {
    const { data } = await axiosInstance().post(
      reqData?.path,
      {...reqData.payload}
    );
    return data?.results?.layout;
  } catch (err) {
    console.error('Graph fetching Error');
    throw err;
  }
};


const getQuestionsJointGraph = async (reqData: any) => {
  try {
    const { data } = await axiosInstance().post(
      reqData?.path,
      {...reqData.payload}
    );
    return data?.results;
  } catch (err: any) {
    toast.error(err?.message || 'Problem in fetching Questions');
    return err;
  }
};

const saveGraphLayout = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(
      ScadaConfigurationPathConstants.SAVE_CONFIGURATION,
      payload
    );
    return data?.details;
  } catch (err) {
    console.error('Graph fetching Error');
    throw err;
  }
};

export {
  getAssetStencil,
  getAssetGraph,
  getQuestionsJointGraph,
  saveGraphLayout
}