import { ComponentType } from 'react';
import usePermissionCheck from '../../utilities/customHooks/usePermissionCheck'
import PermissionDeniedComponent from './PermissionDeniedComponent';
import LayoutComponent from '../../components/LayoutComponent';



function withPermissionCheck<P>(
  WrappedComponent: ComponentType<P>,
  permissionTag: string,
  assetId?: string | undefined,
): ComponentType<P> {
  const WithPermission = (props: any) => {
    const { hasPermission, loading } = usePermissionCheck(permissionTag, assetId);
    return (
      <LayoutComponent factoryResetContainer>
        {
          (loading || !hasPermission)  &&
          <PermissionDeniedComponent loading={loading} permissionDenied={!hasPermission} />
        }
        {
          (!loading && hasPermission) &&
          <WrappedComponent {...props} />
        }
      </LayoutComponent>
    )
  };

  return WithPermission;
}

export default withPermissionCheck;