import { useEffect, useRef, useState } from "react";
import * as joint from '@joint/plus';
import './style.scss';
import { EventSourcePolyfill } from "event-source-polyfill";
import { Pipe } from "../../../shapes/equipment/Pipe";
import * as appShapes from "../../../shapes/app-shapes";
import withPermissionCheck from "../../../../../hoc/withRoutePermission";
import { PERMISSION_TAGS } from "../../../../../constants/permissions";
import { ZOOM_SETTINGS } from "../../services/navigator-service";

const ScadaView = ({graphDetails, sseApiUrl, assetId}: any) => {
  const paperContainer = useRef<HTMLDivElement>(null);
  const backendApi = import.meta.env.VITE_APP_ENDPOINT;

  console.log("sseApiUrl ::: ", sseApiUrl);
  console.log("backendApi ::: ", backendApi);

  const [graph, setGraph] = useState(null);
  const [graphLoaded, setGraphLoaded] = useState(false);

  useEffect(() => {
      const graph = new joint.dia.Graph({}, {
          cellNamespace: appShapes.namespace
      });

      const paper = new joint.dia.Paper({
        width: 1000,
        height: 1000,
        gridSize: 10,
        drawGrid: true,
        model: graph,
        cellViewNamespace: appShapes.namespace,
        autoFreeze: true,
        cursor: 'move',
        interactive: false,
        // linkPinning: false,
        async: true,
        sorting: joint.dia.Paper.sorting.APPROX,
        frozen: true,
        validateConnection: () => false
    });
    const paperScroller = new joint.ui.PaperScroller({
        paper,
        autoResizePaper: true,
        scrollWhileDragging: true,
        borderless: true,
        cursor: 'grab'
    });
    paper.on({
      'paper:pinch': (_evt, ox, oy, scale) => {
          const zoom = paperScroller.zoom();
          paperScroller.zoom(zoom * scale, { min: ZOOM_SETTINGS.min, max: ZOOM_SETTINGS.max, ox, oy, absolute: true });
      },
    })
    paperContainer.current?.appendChild(paperScroller.el);
    paperScroller.center();
    graph.fromJSON(graphDetails);
    // paper.freeze();
    setGraph(graph);
    setGraphLoaded(true);

  }, [])

  useEffect(() => {
    if(graphLoaded) {
      const events = new EventSourcePolyfill(
        `${backendApi}/${sseApiUrl}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          heartbeatTimeout: 9000000,
          //@ts-ignore
          mode: 'cors',
          // body: {
          //   assetId: assetId
          // }
        }
      );

      events.onmessage = (event: any) => {
        const plantLayoutData = JSON.parse(event.data);
        console.log("fdsafdafas::: ", plantLayoutData);
        updateGraphLayout(plantLayoutData);
      };
      return () => {
       events.close();
     }
    }
  }, [graphLoaded])

  const updateGraphLayout = (plantLayoutData: any) => {
    if(Object.keys(plantLayoutData).length === 0) return;
    Object.keys(plantLayoutData).map((key: any) => {
      let equipmentId = key;
      let value = plantLayoutData[key];
      const foundElement = graph?.getCells().find((cell: any) => {
        return cell.get('equipmentId') === equipmentId
      });
      if(foundElement) {
        updateStatus(foundElement, value, foundElement.get('type'));
      }
    })
    let nodes = plantLayoutData?.nodes;
    console.log("plantLayoutData :: ", plantLayoutData)
    // nodes.map((node: any) => {
    //   let equipmentId = n;
    //   const foundElement = graph?.getCells().find((cell: any) => cell.attr('customId') === equipmentId);
    //   console.log(foundElement)
    //   if(foundElement) {
    //     updateStatus(foundElement, value, foundElement.attr('type'));
    //   }
    // })
    // let value = 20;
  }


  const updateStatus = (element: any, value: any, equipmentType?: any) => { 
    console.log("equipmentI :: ", element);
    console.log("value :: ", value);
    console.log("equipmentType :: ", equipmentType);    
      switch (equipmentType) {
        case 'Pipe':
          element.set('flow', value);
          break;
        case 'OS_TANK':
          element.set('level', value);
          break;
      }
  }

  return (
    <div className="sls-container" ref={paperContainer}></div>
  )
}

export default ScadaView;