import * as joint from '@joint/plus';
import { OP_TANK } from './OP_TANK';

export class MOTOR extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "MOTOR",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        root: {
          magnet: true
        },
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect x="76.5598" y="36.326" width="96.772" height="32.326" rx="0.823591" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.47597"/>
        <rect x="76.5592" y="213.674" width="96.772" height="32.326" rx="0.823591" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.47597"/>
        <rect x="67.2945" y="210.585" width="115.303" height="4.11796" rx="0.823591" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.47597"/>
        <rect x="71.8808" y="68.4911" width="106.525" height="142.225" fill="url(#paint0_linear_2920_940)" stroke="#7A7A7A" stroke-width="1.47597"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M110.121 4.00024C92.2684 4.00024 77.7952 18.473 77.7952 36.3262H172.302C172.302 18.473 157.829 4.00024 139.976 4.00024H110.121Z" fill="url(#paint1_linear_2920_940)" stroke="#7A7A7A" stroke-width="1.47597"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M93.6486 50.8307C93.6486 49.1353 92.5039 47.7142 90.9643 47.337C90.5225 47.2288 90.1483 47.6076 90.1483 48.0625L90.1483 56.9161C90.1483 57.3709 90.5225 57.7498 90.9643 57.6416C92.5039 57.2643 93.6486 55.8433 93.6486 54.1479L93.6486 50.8307Z" fill="white" stroke="#181818" stroke-width="1.23539"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M115.68 50.8307C115.68 49.1353 114.535 47.7142 112.996 47.337C112.554 47.2288 112.18 47.6076 112.18 48.0625L112.18 56.9161C112.18 57.3709 112.554 57.7498 112.996 57.6416C114.535 57.2643 115.68 55.8433 115.68 54.1479L115.68 50.8307Z" fill="white" stroke="#181818" stroke-width="1.23539"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M137.711 50.8307C137.711 49.1353 136.566 47.7142 135.027 47.337C134.585 47.2288 134.211 47.6076 134.211 48.0625L134.211 56.9161C134.211 57.3709 134.585 57.7498 135.026 57.6416C136.566 57.2643 137.711 55.8433 137.711 54.1479L137.711 50.8307Z" fill="white" stroke="#181818" stroke-width="1.23539"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M159.742 50.8307C159.742 49.1353 158.597 47.7142 157.057 47.337C156.616 47.2288 156.242 47.6076 156.242 48.0625L156.242 56.9161C156.242 57.3709 156.616 57.7498 157.057 57.6416C158.597 57.2643 159.742 55.8433 159.742 54.1479L159.742 50.8307Z" fill="white" stroke="#181818" stroke-width="1.23539"/>
        <rect x="73.6074" y="68.4911" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A"/>
        <rect x="77.0622" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="80.5171" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="83.9719" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="87.4268" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="90.8817" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="94.3365" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="97.7914" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="101.246" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="104.701" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="108.156" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="111.611" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="115.066" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="118.521" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="121.975" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="125.43" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="128.885" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="132.34" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="135.795" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="139.25" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="142.705" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="146.16" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="149.614" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="153.069" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="156.524" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="159.979" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="163.434" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="166.889" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="170.344" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.1"/>
        <rect x="173.798" y="68.4913" width="1.15162" height="142.225" rx="0.425215" fill="#7A7A7A" fill-opacity="0.1"/>
        <rect x="151.713" y="79.7275" width="13.3834" height="120.725" rx="0.823591" fill="#181818"/>
        <rect x="122.065" y="79.3585" width="5.97104" height="120.725" rx="0.823591" fill="#181818"/>
        <rect x="114.24" y="92.4442" width="21.6193" height="70.6866" rx="0.823591" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.47597"/>
        <rect x="85.0021" y="79.7275" width="13.3834" height="120.725" rx="0.823591" fill="#181818"/>
        <path d="M49.9999 90.0657C49.9999 87.3366 52.2123 85.1241 54.9415 85.1241H70.7956C71.2505 85.1241 71.6192 85.4929 71.6192 85.9477V174.896C71.6192 175.35 71.2505 175.719 70.7956 175.719H54.9415C52.2123 175.719 49.9999 173.507 49.9999 170.778V90.0657Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.47597"/>
        <circle cx="60.9124" cy="104.067" r="8.23591" fill="#181818"/>
        <path d="M178.273 85.9477C178.273 85.4929 178.642 85.1241 179.097 85.1241H194.951C197.68 85.1241 199.892 87.3366 199.892 90.0657V170.778C199.892 173.507 197.68 175.719 194.951 175.719H179.097C178.642 175.719 178.273 175.35 178.273 174.896V85.9477Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="1.47597"/>
        <circle cx="189.186" cy="104.067" r="8.23591" fill="#181818"/>
        <path d="M91.7957 215.013L91.7957 245.897" stroke="#7A7A7A" stroke-width="1.64718" stroke-linecap="square"/>
        <path d="M98.3861 215.013L98.3861 245.897" stroke="#7A7A7A" stroke-width="1.64718" stroke-linecap="square"/>
        <path d="M135.859 215.013L135.859 245.897" stroke="#7A7A7A" stroke-width="1.64718" stroke-linecap="square"/>
        <rect x="105.386" y="220.88" width="39.1206" height="17.9131" rx="0.823591" fill="white" stroke="#7A7A7A" stroke-width="1.47597"/>
        <rect x="71.6176" y="221.498" width="7.82412" height="8.23591" rx="0.823591" fill="#181818"/>
        <rect x="170.45" y="221.498" width="7.82412" height="8.23591" rx="0.823591" fill="#181818"/>
        <defs>
        <linearGradient id="paint0_linear_2920_940" x1="178.406" y1="139.604" x2="71.8808" y2="139.604" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2920_940" x1="172.302" y1="20.1632" x2="77.7952" y2="20.1632" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        </defs>
      </svg>
       `;
  }
}