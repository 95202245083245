import * as joint from '@joint/plus';

export class UF_MEM extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'UF_MEM',
      size: {
        width: 250,
        height: 250,
      },
      attrs: {
          outlet: {
              d: "M206.456 214.153H212.864C213.65 214.153 214.288 213.516 214.288 212.729V188.521C214.288 187.734 213.65 187.097 212.864 187.097H206.456C205.669 187.097 205.032 187.734 205.032 188.521V212.729C205.032 213.516 205.669 214.153 206.456 214.153Z", 
              fill: "white", 
              stroke: "#818181", 
              "stroke-width": 1.42402,
              magnet: true
        },
        inlet: {
          d: "M37.136 62.9031H43.5441C44.3306 62.9031 44.9682 62.2655 44.9682 61.4791V37.2707C44.9682 36.4842 44.3306 35.8467 43.5441 35.8467H37.136C36.3496 35.8467 35.712 36.4842 35.712 37.2707L35.712 61.4791C35.712 62.2655 36.3496 62.9031 37.136 62.9031Z", 
          fill: "white", 
          stroke: "#818181", 
          "stroke-width": 1.42402,
          magnet: true
        }
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <rect x="44.7913" y="38.6947" width="160.202" height="21.3603" fill="url(#paint0_linear_3092_6414)" stroke="#818181" stroke-width="1.42402"/>
              <path d="M204.32 60.7366L210.864 60.7366C212.044 60.7366 213 59.7802 213 58.6005V40.1218C213 38.9421 212.044 37.9857 210.864 37.9857H204.32L204.32 60.7366Z" fill="#181818"/>
              <path @selector="inlet" />
              <path d="M54.511 189.945L54.511 183.537C54.511 182.75 55.1485 182.113 55.935 182.113L58.7831 182.113C59.5695 182.113 60.2071 182.75 60.2071 183.537L60.2071 189.945L54.511 189.945Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M54.511 60.0029L54.511 66.411C54.511 67.1975 55.1485 67.8351 55.935 67.8351L58.7831 67.8351C59.5695 67.8351 60.2071 67.1975 60.2071 66.411L60.2071 60.0029L54.511 60.0029Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <rect x="68.0388" y="75.7205" width="98.4345" height="21.3603" transform="rotate(90 68.0388 75.7205)" fill="url(#paint1_linear_3092_6414)" stroke="#818181" stroke-width="1.42402"/>
              <path d="M68.929 75.7205L68.929 90.3167C68.929 91.1032 68.2914 91.7407 67.5049 91.7407L47.2126 91.7407C46.4261 91.7407 45.7886 91.1032 45.7886 90.3167L45.7886 75.7205L68.929 75.7205Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M43.8307 67.8873L43.8307 74.2954C43.8307 75.0818 44.4682 75.7194 45.2547 75.7194L69.4631 75.7194C70.2495 75.7194 70.8871 75.0818 70.8871 74.2954L70.8871 67.8873C70.8871 67.1008 70.2495 66.4632 69.4631 66.4632L45.2547 66.4632C44.4682 66.4632 43.8307 67.1008 43.8307 67.8873Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M45.7888 174.155L45.7888 159.559C45.7888 158.772 46.4264 158.135 47.2128 158.135L67.5052 158.135C68.2916 158.135 68.9292 158.772 68.9292 159.559L68.9292 174.155L45.7888 174.155Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M70.8871 181.988L70.8871 175.58C70.8871 174.794 70.2496 174.156 69.4631 174.156L45.2547 174.156C44.4682 174.156 43.8307 174.794 43.8307 175.58L43.8307 181.988C43.8307 182.775 44.4682 183.412 45.2547 183.412L69.4631 183.412C70.2496 183.412 70.8871 182.775 70.8871 181.988Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M88.3315 189.945L88.3315 183.537C88.3315 182.75 88.9691 182.113 89.7556 182.113L92.6036 182.113C93.3901 182.113 94.0276 182.75 94.0276 183.537L94.0276 189.945L88.3315 189.945Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M88.3315 60.0029L88.3315 66.411C88.3315 67.1975 88.9691 67.8351 89.7556 67.8351L92.6036 67.8351C93.3901 67.8351 94.0276 67.1975 94.0276 66.411L94.0276 60.0029L88.3315 60.0029Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <rect x="101.86" y="75.7205" width="98.4345" height="21.3603" transform="rotate(90 101.86 75.7205)" fill="url(#paint2_linear_3092_6414)" stroke="#818181" stroke-width="1.42402"/>
              <path d="M102.75 75.7205L102.75 90.3167C102.75 91.1032 102.112 91.7407 101.326 91.7407L81.0334 91.7407C80.2469 91.7407 79.6094 91.1032 79.6094 90.3167L79.6094 75.7205L102.75 75.7205Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M77.6512 67.8873L77.6512 74.2954C77.6512 75.0818 78.2888 75.7194 79.0752 75.7194L103.284 75.7194C104.07 75.7194 104.708 75.0818 104.708 74.2954L104.708 67.8873C104.708 67.1008 104.07 66.4632 103.284 66.4632L79.0752 66.4632C78.2888 66.4632 77.6512 67.1008 77.6512 67.8873Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M79.6094 174.155L79.6094 159.559C79.6094 158.772 80.2469 158.135 81.0334 158.135L101.326 158.135C102.112 158.135 102.75 158.772 102.75 159.559L102.75 174.155L79.6094 174.155Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M104.708 181.988L104.708 175.58C104.708 174.794 104.07 174.156 103.284 174.156L79.0753 174.156C78.2888 174.156 77.6512 174.794 77.6512 175.58L77.6512 181.988C77.6512 182.775 78.2888 183.412 79.0753 183.412L103.284 183.412C104.07 183.412 104.708 182.775 104.708 181.988Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M122.152 189.945L122.152 183.537C122.152 182.75 122.789 182.113 123.576 182.113L126.424 182.113C127.21 182.113 127.848 182.75 127.848 183.537L127.848 189.945L122.152 189.945Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M122.152 60.0029L122.152 66.411C122.152 67.1975 122.789 67.8351 123.576 67.8351L126.424 67.8351C127.21 67.8351 127.848 67.1975 127.848 66.411L127.848 60.0029L122.152 60.0029Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <rect x="135.68" y="75.7205" width="98.4345" height="21.3603" transform="rotate(90 135.68 75.7205)" fill="url(#paint3_linear_3092_6414)" stroke="#818181" stroke-width="1.42402"/>
              <path d="M136.57 75.7205L136.57 90.3167C136.57 91.1032 135.933 91.7407 135.146 91.7407L114.854 91.7407C114.068 91.7407 113.43 91.1032 113.43 90.3167L113.43 75.7205L136.57 75.7205Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M111.472 67.8873L111.472 74.2954C111.472 75.0818 112.11 75.7194 112.896 75.7194L137.104 75.7194C137.891 75.7194 138.528 75.0818 138.528 74.2954L138.528 67.8873C138.528 67.1008 137.891 66.4632 137.104 66.4632L112.896 66.4632C112.11 66.4632 111.472 67.1008 111.472 67.8873Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M113.43 174.155L113.43 159.559C113.43 158.772 114.067 158.135 114.854 158.135L135.146 158.135C135.933 158.135 136.57 158.772 136.57 159.559L136.57 174.155L113.43 174.155Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M138.528 181.988L138.528 175.58C138.528 174.794 137.891 174.156 137.104 174.156L112.896 174.156C112.109 174.156 111.472 174.794 111.472 175.58L111.472 181.988C111.472 182.775 112.109 183.412 112.896 183.412L137.104 183.412C137.891 183.412 138.528 182.775 138.528 181.988Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M155.973 189.945L155.973 183.537C155.973 182.75 156.61 182.113 157.397 182.113L160.245 182.113C161.031 182.113 161.669 182.75 161.669 183.537L161.669 189.945L155.973 189.945Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M155.973 60.0029L155.973 66.411C155.973 67.1975 156.61 67.8351 157.397 67.8351L160.245 67.8351C161.031 67.8351 161.669 67.1975 161.669 66.411L161.669 60.0029L155.973 60.0029Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <rect x="169.501" y="75.7205" width="98.4345" height="21.3603" transform="rotate(90 169.501 75.7205)" fill="url(#paint4_linear_3092_6414)" stroke="#818181" stroke-width="1.42402"/>
              <path d="M170.391 75.7205L170.391 90.3167C170.391 91.1032 169.754 91.7407 168.967 91.7407L148.675 91.7407C147.888 91.7407 147.251 91.1032 147.251 90.3167L147.251 75.7205L170.391 75.7205Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M145.293 67.8873L145.293 74.2954C145.293 75.0818 145.93 75.7194 146.717 75.7194L170.925 75.7194C171.711 75.7194 172.349 75.0818 172.349 74.2954L172.349 67.8873C172.349 67.1008 171.711 66.4632 170.925 66.4632L146.717 66.4632C145.93 66.4632 145.293 67.1008 145.293 67.8873Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M147.251 174.155L147.251 159.559C147.251 158.772 147.888 158.135 148.675 158.135L168.967 158.135C169.754 158.135 170.391 158.772 170.391 159.559L170.391 174.155L147.251 174.155Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M172.349 181.988L172.349 175.58C172.349 174.794 171.712 174.156 170.925 174.156L146.717 174.156C145.93 174.156 145.293 174.794 145.293 175.58L145.293 181.988C145.293 182.775 145.93 183.412 146.717 183.412L170.925 183.412C171.712 183.412 172.349 182.775 172.349 181.988Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M189.793 189.945L189.793 183.537C189.793 182.75 190.431 182.113 191.217 182.113L194.065 182.113C194.852 182.113 195.489 182.75 195.489 183.537L195.489 189.945L189.793 189.945Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M189.793 60.0029L189.793 66.411C189.793 67.1975 190.431 67.8351 191.217 67.8351L194.065 67.8351C194.852 67.8351 195.489 67.1975 195.489 66.411L195.489 60.0029L189.793 60.0029Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <rect x="203.321" y="75.7205" width="98.4345" height="21.3603" transform="rotate(90 203.321 75.7205)" fill="url(#paint5_linear_3092_6414)" stroke="#818181" stroke-width="1.42402"/>
              <path d="M204.211 75.7205L204.211 90.3167C204.211 91.1032 203.574 91.7407 202.787 91.7407L182.495 91.7407C181.708 91.7407 181.071 91.1032 181.071 90.3167L181.071 75.7205L204.211 75.7205Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M179.113 67.8873L179.113 74.2954C179.113 75.0818 179.75 75.7194 180.537 75.7194L204.745 75.7194C205.532 75.7194 206.169 75.0818 206.169 74.2954L206.169 67.8873C206.169 67.1008 205.532 66.4632 204.745 66.4632L180.537 66.4632C179.75 66.4632 179.113 67.1008 179.113 67.8873Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <path d="M181.071 174.155L181.071 159.559C181.071 158.772 181.708 158.135 182.495 158.135L202.787 158.135C203.573 158.135 204.211 158.772 204.211 159.559L204.211 174.155L181.071 174.155Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.42402"/>
              <path d="M206.169 181.988L206.169 175.58C206.169 174.794 205.532 174.156 204.745 174.156L180.537 174.156C179.75 174.156 179.113 174.794 179.113 175.58L179.113 181.988C179.113 182.775 179.75 183.412 180.537 183.412L204.745 183.412C205.532 183.412 206.169 182.775 206.169 181.988Z" fill="white" stroke="#818181" stroke-width="1.42402"/>
              <rect x="44.791" y="189.945" width="160.202" height="21.3603" fill="url(#paint6_linear_3092_6414)" stroke="#818181" stroke-width="1.42402"/>
              <path d="M46 212L39.4559 212C38.2762 212 37.3198 211.044 37.3198 209.864V191.386C37.3198 190.206 38.2762 189.25 39.4559 189.25H46L46 212Z" fill="#181818"/>
              <path @selector="outlet" />
              <defs>
              <linearGradient id="paint0_linear_3092_6414" x1="204.994" y1="60.4431" x2="44.7913" y2="60.4431" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_3092_6414" x1="166.473" y1="97.4689" x2="68.0388" y2="97.4689" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint2_linear_3092_6414" x1="200.294" y1="97.4689" x2="101.86" y2="97.4689" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint3_linear_3092_6414" x1="234.115" y1="97.4689" x2="135.68" y2="97.4689" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint4_linear_3092_6414" x1="267.936" y1="97.4689" x2="169.501" y2="97.4689" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint5_linear_3092_6414" x1="301.756" y1="97.4689" x2="203.321" y2="97.4689" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint6_linear_3092_6414" x1="204.994" y1="211.693" x2="44.791" y2="211.693" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
  }
}
