import { ui } from '@joint/plus';
const Position = ui.Halo.HandlePosition;

export class HaloService {
    private halo: joint.ui.Halo | null = null;

    create(cellView: joint.dia.CellView) {
        // Clean up any existing FreeTransform/Halo before creating new ones
        this.cleanup();

        // Create Halo
        this.halo = new ui.Halo({
            cellView,
            boxContent: null,
            handles: this.getHaloConfig(cellView),
            useModelGeometry: true,
            bbox: ({ model, paper }) => {
                console.log("model.getBBox() ::: ", model.getBBox())
                // let {width, height} = cellView.getBBox();
                let {x, y, width, height} = model.getBBox();
                x+= 10;
                y+= 10;
                width += 10;
                height += 10;
                return paper!.localToPaperRect({x, y, width, height})
            }
        }).render();

    }

    private getHaloConfig(cellView: joint.dia.CellView) {
        return [
            {
                ...ui.Halo.getDefaultHandle('remove'),
                position: Position.NW,
                attrs: {
                    '.handle': {
                        'data-tooltip-class-name': 'small',
                        'data-tooltip': 'Click to remove the object',
                        'data-tooltip-position': 'right',
                        'data-tooltip-padding': 15
                    }
                }
            },
            {
                ...ui.Halo.getDefaultHandle('unlink'),
                position: Position.S,
                attrs: {
                    '.handle': {
                        'data-tooltip-class-name': 'small',
                        'data-tooltip': 'Click to break all connections to other objects',
                        'data-tooltip-position': 'right',
                        'data-tooltip-padding': 15
                    }
                }
            },
            {
                ...ui.Halo.getDefaultHandle('rotate'),
                position: Position.SW,
                attrs: {
                    '.handle': {
                        'data-tooltip-class-name': 'small',
                        'data-tooltip': 'Click and drag to rotate the object',
                        'data-tooltip-position': 'right',
                        'data-tooltip-padding': 15
                    }
                }
            },
            // {
            //     ...ui.Halo.getDefaultHandle('resize'),
            //     position: Position.SE,
            //     attrs: {
            //         '.handle': {
            //             'data-tooltip-class-name': 'small',
            //             'data-tooltip': 'Click and drag to rotate the object',
            //             'data-tooltip-position': 'right',
            //             'data-tooltip-padding': 15
            //         }
            //     }
            // }
        ];
    }



    // Clean up any existing Halo and FreeTransform before creating new ones
    cleanup() {

        if (this.halo) {
            this.halo.remove();
            this.halo = null;
        }
    }
}
