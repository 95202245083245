import * as joint from '@joint/plus';
import { Pipe, PipeView } from './equipment/Pipe';
import { OP_TANK } from './equipment/OP_TANK';
import { MOTOR } from './equipment/MOTOR';
import { SC_PRESS } from './equipment/SC_PRESS';
import { CP_TANK } from './equipment/CP_TANK';
import { OS_TANK, OS_TANKView } from './equipment/OS_TANK';
import { TANK } from './equipment/TANK';
import { TANK_2 } from './equipment/TANK_2';
import { VALVE_2 } from './equipment/VALVE_2';
import { VALVE_3 } from './equipment/VALVE_3';
import { SMC_PUMP } from './equipment/SMC_PUMP';
import { DS_PUMP } from './equipment/DS_PUMP';
import { FS_PUMP } from './equipment/FS_PUMP';
import { SH_TANK } from './equipment/SH_TANK';
import { F_PRESS } from './equipment/F_PRESS';
import { OZONATOR } from './equipment/OZONATOR';
import { MBR } from './equipment/MBR';
import { MBBR } from './equipment/MBBR';
import { DE_TANK } from './equipment/DE_TANK';
import { DA_TANK } from './equipment/DA_TANK';
import { BLW, BLWiew } from './equipment/BLOWER';
import { T_SETTLER } from './equipment/T_SETTLER';
import { UF_MEM } from './equipment/UF_MEM';
import { DOS_TANK } from './equipment/DOS_TANK';
import { OH_TANK } from './equipment/OH_TANK';
// import { OH_TANK } from './equipment/OH_TANK';
import { SOFTNER } from './equipment/SOFTNER';
import { ACF_MGF } from './equipment/ACF_MGF';
import { CL_TANK } from './equipment/CL_TANK';
import { ZONE } from './zone/ZONE';
import { OS_TANK_DF } from './equipment/OS_TANK_DF';
import { OS_TANK_SC } from './equipment/OS_TANK_SC';
import { OS_TANK_SP } from './equipment/OS_TANK_SP';
import { LEVEL_SENSOR, LEVEL_SENSORView } from './sensors/LEVEL_SENSOR';
import { NEWBLOWER, NEWBLOWERView } from './equipment/NEWBLOWER';


export const namespace: any = {
   ...joint.shapes,
      Pipe,
      // PipeView,
     TANK,
     CP_TANK,
     OP_TANK,
     MOTOR,
     SC_PRESS,
     OS_TANK,
     TANK_2,
     VALVE_2,
     VALVE_3,
     SMC_PUMP,
     DS_PUMP,
     FS_PUMP,
     SH_TANK,
     SOFTNER,
     ACF_MGF,
     F_PRESS,
     OZONATOR,
     MBR,
     MBBR,
     DE_TANK,
     DA_TANK,
     BLW,
     T_SETTLER,
     UF_MEM,
     DOS_TANK,
     CL_TANK,
     OH_TANK,
     ZONE,
     OS_TANK_DF,
     OS_TANK_SC,
     OS_TANK_SP,
     LEVEL_SENSOR,
     BLWiew,
     OS_TANKView,
     PipeView
    // LEVEL_SENSORView
    // CS_TANK,
    // CS_TANK_SP,
    // CS_TANK_SC,
    // CS_TANK_DF,
 };
