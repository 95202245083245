import React from 'react';
import { PermissionDeniedComponentInterface } from './PermissionCheckInterFace';
import Loader from '../../globalComponents/loader/DPLoader';
import { Stack, Typography } from '@mui/material';

const PermissionDeniedComponent: React.FC<PermissionDeniedComponentInterface> = ({loading, permissionDenied}: PermissionDeniedComponentInterface) => {
  return (
    <Stack sx={{height: 'calc(100vh - 80px)', justifyContent: 'center', alignItems: 'center', textAlign: 'center'}}>
      {
        loading && 
        <Loader />
      }
      {
        !loading && permissionDenied &&
        <Typography variant={'h2'}>You do not have permission to view this screen. Access denied.</Typography>

      }
    </Stack>
  )
}

export default React.memo(PermissionDeniedComponent);