import { Box, Stack } from "@mui/material";
import AddSensors from "../AddSensors";
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import './style.scss';
import { useRef, useState } from "react";
import { Primary } from "../../../../../core-components/theme/color-palette/colors";
import useOutsideClickDetector from "../../../../../utilities/customHooks/useOutsideClickDetector";

const SensorType = ['Gauge', 'Range', 'Value', 'Level - Range', 'Switch'];

const RenderSensorList = ({sensorList, handleSaveSensors, addSensorEquipemntToGraph}: any) => {
  const [showCollapsable, setShowCollapsable] = useState<number>(-1);
  const collapsable = useRef<HTMLDivElement>(null);

  useOutsideClickDetector(() => setShowCollapsable(-1), );

  const handleSelect = (index: number) => {
    setShowCollapsable(index);
  }

  const handleDeleteSensor = (index: number) => {
    const newSensorList = [...sensorList];
    newSensorList.splice(index, 1);
    handleSaveSensors(newSensorList);
  }

  const handleSensor = (sensorData: any, sensorType: string) => {
    setShowCollapsable(-1);
    addSensorEquipemntToGraph(sensorData, sensorType);
  }


  return <div>
      <Stack rowGap={6}>
        <AddSensors handleSaveSensors={handleSaveSensors} key={sensorList?.length || 0} />
        <Stack rowGap={4} sx={{marginTop: '30px'}}>
          {
            sensorList?.length > 0 && sensorList?.map((sensor: any, index: number) => {
              return <div key={sensor + index} className="ins-sensor-item">
                <Stack direction={"row"} columnGap={3} alignItems={"center"} onClick={() => handleSelect(index)}>
                  {/* <AddBoxOutlinedIcon sx={{color: '#2F6A9E', fontSize: '30px'}} /> */}
                  <Box sx={{width: '24px', height: '24px', backgroundColor: '#fff', borderRadius: '4px', border: `1px solid ${Primary[500]}`, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <AddIcon sx={{color: Primary[500], fontSize: '20px'}}/>
                  </Box>
                  <div className="ins-sensor-name">
                    <div>{sensor.sensorNickName}</div>
                    <div>{sensor.sensorName}</div>
                    {/* <div>{sensor.sensorTag}</div> */}
                  </div>
                </Stack>
                <DeleteIcon color="error"/>
                {
                  showCollapsable === index &&
                  <div className="ins-sensor-collapse" ref={collapsable} >
                    <ul>
                      {
                        SensorType?.map((sensorName) => {
                          return <li key={sensorName} onClick={() => handleSensor(sensor, 'gauge')}>{sensorName}</li>
                        })
                      }
                    </ul>
                  </div>
                }
              </div>
            })
          }
        </Stack>
      </Stack>
  </div>;
};

export default RenderSensorList