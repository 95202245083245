import * as joint from '@joint/plus';

export class SMC_PUMP extends joint.dia.Element {
    defaults() {
        return {
            ...super.defaults,
            type: "SMC_PUMP",
            size: {
                width: 250,
                height: 250
            },
            attrs: {
                outlet: {
                    x: 153.1,
                    y: 4.5639, 
                    width: 15.5619,
                    height: 53.1136, 
                    transform: "rotate(90 153.1 4.5639)",
                    fill: "white",
                    stroke: "#818181",
                    'stroke-width': "1.12779",
                    magnet: true
                },
            }

        };
    }

    preinitialize() {
        this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <rect x="15" y="4" width="220" height="36" fill="#975E58"/>
              <rect x="15" y="40" width="220" height="36" fill="#754C4F"/>
              <rect x="15" y="76" width="220" height="72" fill="#98D7FD"/>
              <rect x="15" y="148" width="220" height="98" fill="#6EA5F4"/>
              <circle cx="218.382" cy="105.285" r="2" fill="#6EA5F4"/>
              <circle cx="228.382" cy="127.285" r="2" fill="#6EA5F4"/>
              <circle cx="199.382" cy="120.285" r="2" fill="#6EA5F4"/>
              <circle cx="205.382" cy="85.2852" r="2" fill="#6EA5F4"/>
              <circle cx="212.382" cy="147.285" r="2" fill="#6EA5F4"/>
              <circle cx="211.382" cy="124.285" r="1" fill="#6EA5F4"/>
              <circle cx="201.382" cy="100.285" r="1" fill="#6EA5F4"/>
              <circle cx="181.382" cy="86.2852" r="1" fill="#6EA5F4"/>
              <circle cx="230.382" cy="99.2852" r="1" fill="#6EA5F4"/>
              <circle cx="49.382" cy="207.285" r="2" transform="rotate(180 49.382 207.285)" fill="#98D7FD"/>
              <circle cx="39.382" cy="185.285" r="2" transform="rotate(180 39.382 185.285)" fill="#98D7FD"/>
              <circle cx="68.382" cy="192.285" r="2" transform="rotate(180 68.382 192.285)" fill="#98D7FD"/>
              <circle cx="62.382" cy="227.285" r="2" transform="rotate(180 62.382 227.285)" fill="#98D7FD"/>
              <circle cx="55.382" cy="165.285" r="2" transform="rotate(180 55.382 165.285)" fill="#98D7FD"/>
              <circle cx="56.382" cy="188.285" r="1" transform="rotate(180 56.382 188.285)" fill="#98D7FD"/>
              <circle cx="66.382" cy="212.285" r="1" transform="rotate(180 66.382 212.285)" fill="#98D7FD"/>
              <circle cx="86.382" cy="226.285" r="1" transform="rotate(180 86.382 226.285)" fill="#98D7FD"/>
              <circle cx="37.382" cy="213.285" r="1" transform="rotate(180 37.382 213.285)" fill="#98D7FD"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 35.382 98.2852)" fill="#6EA5F4"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 27.382 130.285)" fill="#6EA5F4"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 56.382 118.285)" fill="#6EA5F4"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 30.382 75.2852)" fill="#6EA5F4"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 49.382 85.2852)" fill="#6EA5F4"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 53.382 145.285)" fill="#6EA5F4"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 39.382 123.285)" fill="#6EA5F4"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 53.382 99.2852)" fill="#6EA5F4"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 73.382 85.2852)" fill="#6EA5F4"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 24.382 108.285)" fill="#6EA5F4"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 205.382 212.285)" fill="#98D7FD"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 213.382 180.285)" fill="#98D7FD"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 184.382 192.285)" fill="#98D7FD"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 210.382 235.285)" fill="#98D7FD"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 191.382 225.285)" fill="#98D7FD"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 187.382 165.285)" fill="#98D7FD"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 201.382 187.285)" fill="#98D7FD"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 187.382 211.285)" fill="#98D7FD"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 167.382 225.285)" fill="#98D7FD"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 216.382 202.285)" fill="#98D7FD"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 186.382 70.2852)" fill="#975E58"/>
              <circle cx="47.382" cy="48.2849" r="2" fill="#975E58"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 220.382 49.2852)" fill="#975E58"/>
              <circle cx="81.382" cy="69.2849" r="2" fill="#975E58"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 165.382 50.2852)" fill="#975E58"/>
              <circle cx="26.382" cy="68.2849" r="2" fill="#975E58"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 184.382 55.1426)" fill="#975E58"/>
              <circle cx="45.382" cy="63.4275" r="2" fill="#975E58"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 218.382 78)" fill="#975E58"/>
              <circle cx="79.382" cy="40.5701" r="2" fill="#975E58"/>
              <circle cx="2" cy="2" r="2" transform="matrix(1 0 0 -1 198.382 42.5701)" fill="#975E58"/>
              <circle cx="59.382" cy="76" r="2" fill="#975E58"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 182.382 45.2852)" fill="#975E58"/>
              <circle cx="42.382" cy="72.2849" r="1" fill="#975E58"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 168.382 69.2852)" fill="#975E58"/>
              <circle cx="28.382" cy="48.2849" r="1" fill="#975E58"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 197.382 60.2852)" fill="#975E58"/>
              <circle cx="57.382" cy="57.2849" r="1" fill="#975E58"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 228.382 68.2852)" fill="#975E58"/>
              <circle cx="88.382" cy="49.2849" r="1" fill="#975E58"/>
              <circle cx="1" cy="1" r="1" transform="matrix(1 0 0 -1 214.382 56.1426)" fill="#975E58"/>
              <circle cx="74.382" cy="61.4275" r="1" fill="#975E58"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 67.382 12.2849)" fill="#754C4F"/>
              <circle cx="206.382" cy="34.2852" r="2" transform="rotate(180 206.382 34.2852)" fill="#754C4F"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 33.382 33.2849)" fill="#754C4F"/>
              <circle cx="172.382" cy="13.2852" r="2" transform="rotate(180 172.382 13.2852)" fill="#754C4F"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 88.382 32.2849)" fill="#754C4F"/>
              <circle cx="227.382" cy="14.2852" r="2" transform="rotate(180 227.382 14.2852)" fill="#754C4F"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 69.382 27.4275)" fill="#754C4F"/>
              <circle cx="208.382" cy="19.1426" r="2" transform="rotate(180 208.382 19.1426)" fill="#754C4F"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 35.382 4.57007)" fill="#754C4F"/>
              <circle cx="174.382" cy="42" r="2" transform="rotate(180 174.382 42)" fill="#754C4F"/>
              <circle cx="2" cy="2" r="2" transform="matrix(-1 0 0 1 55.382 40)" fill="#754C4F"/>
              <circle cx="194.382" cy="6.57007" r="2" transform="rotate(180 194.382 6.57007)" fill="#754C4F"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 71.382 37.2849)" fill="#754C4F"/>
              <circle cx="211.382" cy="10.2852" r="1" transform="rotate(180 211.382 10.2852)" fill="#754C4F"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 85.382 13.2849)" fill="#754C4F"/>
              <circle cx="225.382" cy="34.2852" r="1" transform="rotate(180 225.382 34.2852)" fill="#754C4F"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 56.382 22.2849)" fill="#754C4F"/>
              <circle cx="196.382" cy="25.2852" r="1" transform="rotate(180 196.382 25.2852)" fill="#754C4F"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 25.382 14.2849)" fill="#754C4F"/>
              <circle cx="165.382" cy="33.2852" r="1" transform="rotate(180 165.382 33.2852)" fill="#754C4F"/>
              <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 39.382 26.4275)" fill="#754C4F"/>
              <circle cx="179.382" cy="21.1426" r="1" transform="rotate(180 179.382 21.1426)" fill="#754C4F"/>
              <circle cx="125.5" cy="125" r="59.8465" transform="rotate(90 125.5 125)" fill="#D3D3D3" stroke="#818181" stroke-width="1.30693"/>
              <path d="M149.491 18.6034L149.491 116.655L103.594 116.655L103.594 18.6034L149.491 18.6034Z" fill="url(#paint0_linear_2920_1102)" stroke="#7A7A7A" stroke-width="1.12779"/>
              <rect @selector="outlet" />
              <rect width="108.483" height="45.8965" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 149.491 231.397)" fill="url(#paint1_linear_2920_1102)" stroke="#7A7A7A" stroke-width="1.12779"/>
              <rect x="-0.563897" y="-0.563897" width="15.5619" height="53.1136" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 152.536 244.872)" fill="white" stroke="#818181" stroke-width="1.12779"/>
              <circle cx="125.401" cy="125.045" r="45.2431" transform="rotate(90 125.401 125.045)" fill="white" stroke="#818181" stroke-width="1.30693"/>
              <circle cx="125.499" cy="125" r="36.8982" transform="rotate(90 125.499 125)" fill="#FF0000" stroke="black" stroke-width="1.30693"/>
              <path d="M116.114 132.48L125.956 124.068L125.062 158.653L116.114 132.48Z" fill="#D9D9D9" stroke="black" stroke-width="1.30693" stroke-linejoin="round"/>
              <path d="M116.645 115.018L125.956 124.02L91.5875 126.545L116.645 115.018Z" fill="#D9D9D9" stroke="black" stroke-width="1.30693" stroke-linejoin="round"/>
              <path d="M134.337 134.108L125.898 124.277L160.352 124.966L134.337 134.108Z" fill="#D9D9D9" stroke="black" stroke-width="1.30693" stroke-linejoin="round"/>
              <path d="M135.799 117.302L125.956 125.714L126.851 91.1286L135.799 117.302Z" fill="#D9D9D9" stroke="black" stroke-width="1.30693" stroke-linejoin="round"/>
              <path d="M144.277 173.555V175.641H108.812V173.555H144.277Z" fill="#808080"/>
              <path d="M144.277 177.727V181.9H108.812V177.727H144.277Z" fill="#808080"/>
              <path d="M144.277 183.986V188.158H108.812V183.986H144.277Z" fill="#808080"/>
              <path d="M144.277 190.245V194.417H108.812V190.245H144.277Z" fill="#808080"/>
              <path d="M108.812 209.02H144.277V213.193H108.812V209.02Z" fill="#808080"/>
              <path d="M144.277 196.503V200.676H108.812V196.503H144.277Z" fill="#808080"/>
              <path d="M144.277 202.762V206.934H108.812V202.762H144.277Z" fill="#808080"/>
              <path d="M144.277 215.279V219.451H108.812V215.279H144.277Z" fill="#808080"/>
              <path d="M144.277 221.538V225.71H108.812V221.538H144.277Z" fill="#808080"/>
              <defs>
              <linearGradient id="paint0_linear_2920_1102" x1="149.491" y1="67.6292" x2="103.594" y2="67.6292" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_1102" x1="54.2414" y1="0" x2="54.2414" y2="45.8965" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>

        `;
    }
}