import { useLocation, useParams } from "react-router-dom";
import { useFetchGraphDetails } from "../../../../hooks/useFetchGraphData";
import { ScadaConfigurationPathConstants } from "../assetConstants/pathConstants";
import { useFetchStencilData } from "../../../../hooks/useFetchStencilData";
import { getLastParams } from "../../visualizationHelper/helperFunctions";

const useAssetConfigurationFetch = () => {
  const {id: assetId = ''} = useParams<string>();
  // const { '*': allPaths } = useParams();
  const location = useLocation();
  const lastParam = getLastParams({location})
  

  const graphDetailsPayload: any = {
    path: ScadaConfigurationPathConstants.GET_ASSET_GRAPHS,
    payload: {
      id: lastParam
    }
  };
  const stencilsPayload: any = {
    path: ScadaConfigurationPathConstants.GET_STENCILS,
    params: {
      assetId: assetId
    }
  }

  const {data: graphDetails, isLoading: graphDetailsLoading} = useFetchGraphDetails(graphDetailsPayload, !!lastParam);
  const {data: stencilData, isLoading: stencilsLoading} = useFetchStencilData(stencilsPayload, true);  

  return {
      graphDetails,
      isLoading: graphDetailsLoading || stencilsLoading,
      stencilData,
      lastPathId: lastParam
  }
}

export default useAssetConfigurationFetch;