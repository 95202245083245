import * as joint from '@joint/plus';

export class VALVE_3 extends joint.dia.Element {
  // @ts-ignore
  defaults() {
    return {
      ...super.defaults,
      type: 'VALVE_3',
      size: { width: 250, height: 250 },
      attrs: {
        body: {
          width: 'calc(w)',
          height: 'calc(h)',
          fill: 'none',
        },
        rect1: {
          x: 119.324,
          y: 87.386,
          width: 11.3514,
          height: 24.3243,
          fill: '#555555',
          stroke: 'black',
          strokeWidth: 1.62162,
        },
        path1: {
          d: 'M94.4825 89.9963C94.531 84.1882 97.7596 78.8662 103.089 74.9579C108.459 71.0202 115.916 68.56 124.189 68.56C132.462 68.56 139.919 71.0202 145.289 74.9579C150.618 78.8662 153.847 84.1882 153.895 89.9963H94.4825Z',
          fill: '#666666',
          stroke: 'black',
          strokeWidth: 1.62162,
        },
        rect2: {
          x: 76.3513,
          y: 127.116,
          width: 97.2977,
          height: 35.675,
          fill: {
            type: 'linearGradient',
            stops: [
              { offset: '0%', color: '#8A8A8A' },
              { offset: '10%', color: '#B1B1B1' },
              { offset: '20%', color: '#E3E3E3' },
              { offset: '40%', color: 'white' },
              { offset: '60%', color: 'white' },
              { offset: '80%', color: '#E3E3E3' },
              { offset: '90%', color: '#B1B1B1' },
              { offset: '100%', color: '#848685' },
            ],
          },
          stroke: '#7A7A7A',
          strokeWidth: 1.62162,
        },
        circle: {
          cx: 124.189,
          cy: 145.764,
          r: 36.4865,
          fill: {
            type: 'radialGradient',
            stops: [
              { offset: '0%', color: '#8A8A8A' },
              { offset: '10%', color: '#B1B1B1' },
              { offset: '20%', color: '#E3E3E3' },
              { offset: '40%', color: 'white' },
              { offset: '60%', color: 'white' },
              { offset: '80%', color: '#E3E3E3' },
              { offset: '90%', color: '#B1B1B1' },
              { offset: '100%', color: '#848685' },
            ],
          },
          stroke: '#7A7A7A',
          strokeWidth: 1.62162,
        },
        innerRect: {
          x: 106.351,
          y: 127.926,
          width: 35.6757,
          height: 35.6757,
          fill: 'white',
          stroke: 'black',
          strokeWidth: 1.62162,
        },
        innerPath: {
          d: 'M109.594 131.98H138.784V159.548H109.594V131.98Z',
          fill: '#FF0000',
        },
      },
    };
  }
  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <rect @selector="rect1"/>
      <path @selector="path1"/>
      <rect @selector="rect2"/>
      <circle @selector="circle"/>
      <rect @selector="innerRect"/>
      <path @selector="innerPath"/>
    `;
  }
}
