import * as joint from '@joint/plus';
import zIndex from '@mui/material/styles/zIndex';
const LEVEL_FLAG = 'level-flag';

export class OS_TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "OS_TANK",
      equipmentType: 'OS_TANK',
      size: {
        width: 250,
        height: 250
      },
      level: 50,
      liquidColor: '#DBA670',
      attrs: {
        body: {
          magnet: false,
        },
        liquid: {
            x: 4.00012,
            y: 105.617,
            width: 241.965,
            height: 112.072,
            fill: "#DBA670",
            magnet: false
        },
        tank: {
          d: "M4 32.3108V216.219C4 217.031 4.65806 217.689 5.46981 217.689H244.53C245.342 217.689 246 217.031 246 216.219V32.3108" ,
          stroke: "#7A7A7A",
          strokeWidth: 2.93959,
          strokeLinecap: "round",
          magnet: false
        },
        label: {
          y: 'calc(h - 10)',                  // Adjust Y position
          x: 'calc(w/2)',
          fill: '#353535',
          // textVerticalAnchor: 'top',
          textAnchor: 'middle',
          fontFamily: 'Roboto',
          fontWeight: 'Normal',
          fontSize: 16,
          stroke: '#353535',
          text: 'Open Skelton Tank',
          zIndex: 1
        },
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg @selector="body" xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect @selector="liquid" />
        <path @selector="tank" />
        <text @selector="label" />
    </svg>
       `;
  }
}


export class OS_TANKView extends joint.dia.ElementView {
    // @ts-ignore
    presentationAttributes = joint.dia.ElementView.addPresentationAttributes({
        level: [LEVEL_FLAG],
        liquidColor: [LEVEL_FLAG],
    });

    // @ts-ignore
    // initFlag = [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    initFlag() {
        return [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    }
    confirmUpdate(...args: any[]): any {
        // @ts-ignore
        let flags = joint.dia.ElementView.prototype.confirmUpdate.call(this, ...args);
        if (this.hasFlag(flags, LEVEL_FLAG)) {
            this.updateLevel();
            flags = this.removeFlag(flags, LEVEL_FLAG);
        }
        return flags;
    }

    getElement(element: any) {
        return this.findNode(element)
    }

    updateLevel() {
        const model = this.model;
        const level = Math.max(0, Math.min(100, model.get('level') || 0));
        const liquidColor = model.get('liquidColor') || '#DBA670';

        const liquidEl = this.getElement('liquid');
        const tankEl = this.getElement('tank');
        if(!tankEl || !liquidEl) return;

        // @ts-ignore
        const tankBBox = tankEl?.getBBox();
        const tankHeight = tankBBox?.height;
        const tankBottom = tankBBox?.y + tankHeight;
        const maxLiquidHeight = tankHeight * 0.85;
        const newHeight = (maxLiquidHeight * level) / 100;

        // Calculate the y position (from bottom up)
        const newY = tankBottom - newHeight;

        // Animate the liquid element
        liquidEl?.animate(
            {
                height: [`${newHeight}px`],
                y: newY,
                fill: liquidColor,
            },
            {
                fill: 'forwards',
                duration: 800,
                easing: 'ease-in-out',
            }
        );
    }
}