import axiosInstance from "../../../../constants/axiosInstance";
import { ScadaConfigurationPathConstants } from "../assetConstants/pathConstants";

// const delete = async (companyId: string) => {
//   try {
//     const { data } = await axiosInstance().delete(
//       '',
//       {
//         params: {companyId}
//       }
//     );
//     return data.data;
//   } catch (err) {
//     console.error('Company Listing api Error');
//     throw err;
//   }
// };


export const submitScadaAnswer = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(
      ScadaConfigurationPathConstants.SUBMIT_ANSWER,
      payload
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export const fetchEquipmentSensorList = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(
      ScadaConfigurationPathConstants.FETCH_EQUIPMENT_SENSORS,
      {...payload}
    );
    return data.results;
  } catch (err) {
    throw err;
  }
};

export const saveEquipmentSensor = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(
      ScadaConfigurationPathConstants.SAVE_EQUIPMENT_SENSORS,
      payload
    );
    return data.data;
  } catch (err) {
    throw err;
  }
};

export const saveToEquipment = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(
      ScadaConfigurationPathConstants.ADD_SENSOR_TO_EQUIPMENT,
      { ...payload.apiPayload }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

// const edit = async (payload: any) => {
//   try {
//     const { data } = await axiosInstance().put(
//       // ScadaConfigurationPathConstants.EDIT_COMPANY,
//       payload
//     );
//     return data.data;
//   } catch (err) {
//     throw err;
//   }
// };



export {
  // getAssetStencil,
  // getAssetGraph
}