import * as joint from '@joint/plus';

export class T_SETTLER extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'T_SETTLER',
      size: {
        width: 250,
        height: 250,
      },
      attrs: {
       inlet: {
        d: "M64.1372 30.7906L64.1372 54.3047L60.1372 54.3047C59.0326 54.3047 58.1372 53.4093 58.1372 52.3047L58.1372 32.7906C58.1372 31.6861 59.0326 30.7906 60.1372 30.7906L64.1372 30.7906Z", 
        fill: "#999999",
        magnet: true
       },
       outlet: {
        d: "M185.486 30.7906L185.486 54.3047L189.486 54.3047C190.59 54.3047 191.486 53.4093 191.486 52.3047L191.486 32.7906C191.486 31.6861 190.59 30.7906 189.486 30.7906L185.486 30.7906Z", 
        fill: "#999999",
        magnet: true
       }
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <path d="M90.6834 113.394H185.288V129.034H90.6834V113.394Z" fill="#E3E3E3" stroke="#818181" stroke-width="0.396039"/>
              <path d="M90.8912 168.621L90.6844 129.158H185.002V168.624L155.79 206.888C155.461 207.318 154.951 207.57 154.41 207.57H148.814C148.241 207.57 147.776 208.035 147.776 208.609C147.776 209.407 147.129 210.054 146.331 210.054H133.78C132.982 210.054 132.335 209.407 132.335 208.609C132.335 208.035 131.87 207.57 131.296 207.57H122.916C122.389 207.57 121.891 207.331 121.561 206.919L90.8912 168.621Z" fill="#E3E3E3" stroke="#818181" stroke-width="0.396039"/>
              <path d="M71.3356 54.2453L71.2909 54.109H71.1474H64.3352V30.3203H185.288V85.3561V113.443H90.7369L71.3356 54.2453Z" fill="url(#paint0_linear_3092_7516)" stroke="#818181" stroke-width="0.396039"/>
              <path d="M69.8647 54.1091L185.2 54.1091" stroke="#818181" stroke-width="0.396039"/>
              <path d="M62.3494 25.198H187.651V30.5926H62.3494V25.198Z" fill="#E3E3E3" stroke="#818181" stroke-width="0.396039"/>
              <path d="M128.269 113.317L84.3779 54.2514M143.418 113.317L99.4631 54.2514M158.269 113.317L114.407 54.1022M172.626 113.317L128.647 54.1511M185.2 109.257L144.155 54.1511M185.2 91.2375L158.254 54.1511M185.2 73.6137L172.634 54.1511M135.695 113.317L91.991 54.2514M150.745 113.317L106.935 54.2514M165.695 113.317L121.88 54.1022M180.15 113.317L136.119 54.1511M185.2 99.9503L151.628 54.3003M185.2 82.2276L165.444 54.1511M185.2 63.9108L180.106 54.1511M132.032 113.317L88.3255 54.2514M147.18 113.317L103.129 54.1022M162.032 113.317L118.073 54.1022M176.487 113.317L132.454 54.1511M185.2 104.604L147.962 54.1511M185.2 86.9801L161.778 54.1511M185.2 68.5642L176.441 54.1511M139.556 113.317L95.7976 54.2514M154.606 113.317L110.742 54.2514M169.655 113.317L125.546 54.1511M183.913 113.317L139.926 54.1511M185.2 95.3959L155.434 54.1511M185.2 77.2771L169.251 54.3003M185.2 59.2573L183.913 54.1511" stroke="#818181" stroke-width="0.396039" stroke-linecap="round"/>
              <circle cx="2.22717" cy="2.22717" r="2.02915" transform="matrix(-1 0 0 1 182.146 118.987)" fill="#818181" stroke="#818181" stroke-width="0.396039"/>
              <circle cx="2.22717" cy="2.22717" r="2.02915" transform="matrix(-1 0 0 1 179.918 39.6993)" fill="#818181" stroke="#818181" stroke-width="0.396039"/>
              <circle cx="2.22717" cy="2.22717" r="2.02915" transform="matrix(-1 0 0 1 74.1543 40.1235)" fill="#818181" stroke="#818181" stroke-width="0.396039"/>
              <circle cx="2.22717" cy="2.22717" r="2.02915" transform="matrix(-1 0 0 1 98.4033 118.987)" fill="#818181" stroke="#818181" stroke-width="0.396039"/>
              <path d="M90.4891 221.189H90.6871V220.991L90.6871 129.158L93.4593 129.158L93.4594 220.991V221.189H93.6574H96.2688C96.3781 221.189 96.4668 221.278 96.4668 221.387V224.604C96.4668 224.713 96.3781 224.802 96.2688 224.802H89.043C88.9336 224.802 88.845 224.713 88.845 224.604L88.845 221.387C88.845 221.278 88.9336 221.189 89.043 221.189H90.4891Z" fill="#818181" stroke="#818181" stroke-width="0.396039"/>
              <path d="M185.577 221.189H185.379V220.991L185.379 129.158L182.607 129.158L182.607 220.991V221.189H182.409H179.798C179.688 221.189 179.6 221.278 179.6 221.387V224.604C179.6 224.713 179.688 224.802 179.798 224.802H187.023C187.133 224.802 187.221 224.713 187.221 224.604L187.221 221.387C187.221 221.278 187.133 221.189 187.023 221.189H185.577Z" fill="#818181" stroke="#818181" stroke-width="0.396039"/>
              <path d="M91.1133 204.275H183.471" stroke="#818181" stroke-width="1.93381"/>
              <path d="M137.292 205.01L137.292 113.196" stroke="#818181" stroke-width="1.93381"/>
              <path d="M91.1133 183.487H183.471" stroke="#818181" stroke-width="7.73523"/>
              <path d="M91.1133 153.169H183.471" stroke="#818181" stroke-width="29.0071"/>
              <path d="M75.0819 65.92L75.4782 67.1248L109.694 113.533C109.759 113.621 109.883 113.64 109.971 113.575C110.059 113.51 110.077 113.386 110.012 113.298L75.0819 65.92Z" fill="#818181"/>
              <path d="M78.4885 76.2779L78.0938 75.0777L106.349 113.298C106.414 113.386 106.395 113.51 106.307 113.575C106.219 113.64 106.095 113.621 106.03 113.533L78.4885 76.2779Z" fill="#818181"/>
              <path d="M81.2002 84.523L80.7951 83.2911L102.588 113.299C102.652 113.388 102.632 113.512 102.544 113.576C102.455 113.64 102.331 113.621 102.267 113.532L81.2002 84.523Z" fill="#818181"/>
              <path d="M83.8361 92.5376L83.4064 91.2312L98.8273 113.302C98.8899 113.392 98.868 113.515 98.7784 113.578C98.6887 113.641 98.5653 113.619 98.5026 113.529L83.8361 92.5376Z" fill="#818181"/>
              <path d="M72.9721 59.5049L72.5767 58.3025L113.181 113.298C113.246 113.386 113.227 113.51 113.139 113.575C113.051 113.64 112.927 113.621 112.862 113.533L72.9721 59.5049Z" fill="#818181"/>
              <path d="M76.877 53.9913C76.8121 53.9033 76.6882 53.8845 76.6001 53.9494C76.5121 54.0143 76.4934 54.1383 76.5583 54.2263L120.288 113.533C120.353 113.621 120.477 113.64 120.565 113.575C120.653 113.51 120.671 113.386 120.606 113.298L76.877 53.9913Z" fill="#818181"/>
              <path d="M80.6893 53.9912C80.6244 53.9032 80.5004 53.8845 80.4124 53.9495C80.3244 54.0144 80.3057 54.1383 80.3706 54.2264L124.05 113.434C124.115 113.522 124.239 113.541 124.327 113.476C124.415 113.411 124.434 113.287 124.369 113.199L80.6893 53.9912Z" fill="#818181"/>
              <path d="M73.2059 53.9913C73.141 53.9033 73.017 53.8845 72.929 53.9494C72.841 54.0143 72.8223 54.1383 72.8872 54.2263L116.624 113.533C116.689 113.621 116.813 113.64 116.901 113.575C116.989 113.51 117.008 113.386 116.943 113.298L73.2059 53.9913Z" fill="#818181"/>
              <path d="M86.391 99.3569C86.3367 99.262 86.2157 99.2291 86.1208 99.2834C86.0259 99.3377 85.993 99.4587 86.0473 99.5536L94.0376 113.514C94.092 113.609 94.213 113.642 94.3079 113.588C94.4028 113.533 94.4357 113.412 94.3814 113.317L86.391 99.3569Z" fill="#818181"/>
              <path @selector="inlet" />
              <path @selector="outlet" />
              <defs>
              <linearGradient id="paint0_linear_3092_7516" x1="278.926" y1="80.7163" x2="-24.9506" y2="80.7163" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
  }
}
