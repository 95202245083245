import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import SidebarContext, { SidebarProvider } from '../context/SidebarContext';
import { Toolbar, Container } from '@mui/material';
import classes from '../styles/layoutStyles.module.css';
import { useState } from 'react';
import { Box } from '@mui/material';
import useUserStore from '../store/UserStore';

type Props = {
  children?: React.ReactNode;
  plantId?: any;
  style?: any;
  factoryResetContainer?: boolean;
};

export default function LayoutComponent(props: Props) {
  const [open, setOpen] = React.useState(false);
  // const classes = useStyles();
  //const classs = { containerOpen: "co", containerClose: "CC" };
  //creating object to be passed onto Context
  const value = { open, setOpen };
  const [sideBarChange, setSideBarChange] = useState(true);

  // const sideBarMenu = JSON.parse(localStorage.getItem("sideBar")!);

  let sideBarMenu = useUserStore().sideBar || [];
  
  let showMenu = localStorage.getItem('showMenu');
  if (showMenu === null) {
    showMenu = 'false';
  }

  const refreshSideBar = () => {
    setSideBarChange(!sideBarChange);
  };

  return (
    <Box sx={{ ...props.style }}>
      <SidebarProvider>
        <Header plantId={props.plantId} />
        <Toolbar />
        <Sidebar
          sideBarMenu={sideBarMenu}
          refresh={refreshSideBar}
          close={() => {
            setOpen(false);
          }}
        />
        {props.factoryResetContainer === true ? (
          <Box p={{ md: 2, sm: 0 }}>{props.children}</Box>
        ) : (
          <Container
            id="container_test"
            sx={{ maxWidth: '1280px !important' }}
            className={open ? classes.containerOpen : classes.containerClose}
          >
            {props.children}
          </Container>
        )}
      </SidebarProvider>
    </Box>
  );
}
