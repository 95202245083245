import * as joint from '@joint/plus';
import { StencilService } from './stencil-service';
import { HaloService } from './halo-service';
import { KeyboardService } from './keyboard-service';
import * as appShapes from '../../shapes/app-shapes';
import { NavigatorService, ZOOM_SETTINGS } from './navigator-service';
import { Pipe } from '../../shapes/equipment/Pipe';

type Services = {
    stencilService: StencilService,
    haloService: HaloService,
    navigatorService: NavigatorService
}

class JointjsService {

    el: HTMLElement;

    graph: joint.dia.Graph;
    paper: joint.dia.Paper;
    paperScroller: joint.ui.PaperScroller;
    pageBreakSettings: { color: string, width: number, height: number } = { color: '#353535', width: 1000, height: 1000 };
    defaultPaperSize: { width: number, height: number } = { width: 1000, height: 1000 };

    commandManager: joint.dia.CommandManager;
    snaplines: joint.ui.Snaplines;
    selection: joint.ui.Selection;
    history: joint.dia.CommandManager;

    stencilService: StencilService;
    haloService: HaloService;
    navigatorService: NavigatorService;
    debounceTimeout: any;
    graphDetails: any

    constructor(
        el: HTMLElement,
        private readonly paperContainer: HTMLElement,
        {
            stencilService,
            haloService,
            navigatorService
        }: Services,
        graphDetails: any,
        private readonly fetchEntitiesData: (payload: any) => void,
        private readonly handleInspectorOpen: (element: any) => void,
        private readonly handleNavigate: (element: any) => void,
        private readonly handleSaveLayout: (element: any) => void,
    ) {
        this.stencilService = stencilService;
        this.haloService = haloService;
        this.navigatorService = navigatorService;
        this.debounceTimeout = null;
        this.graphDetails = graphDetails;

        // apply current joint js theme
        joint.setTheme('light');

        // const view = new joint.mvc.View({ el });
        // view.delegateEvents({
        //     'mouseup input[type="range"]': (evt) => evt.target.blur()
        // });
    }

    startRappid() {


        this.initializePaper();
        this.initializeStencil();
        this.initializeSelection();
        this.initializeToolsAndInspector()
    }

    initializePaper() {

        const graph = this.graph = new joint.dia.Graph({}, {
            cellNamespace: appShapes.namespace
        });

        const paper = this.paper = new joint.dia.Paper({
            width: 1000,
            height: 1000,
            gridSize: 10,
            drawGrid: true,
            model: graph,
            cellViewNamespace: appShapes.namespace,
            cursor: 'move',
            defaultLink: (x, y) => {
                return new Pipe()
            },
            linkPinning: false,
            routerNamespace: {
                'normal': joint.routers.normal,
                'orthogonal': joint.routers.orthogonal,
                // Redefine the rightAngle router to use vertices.
                'rightAngle': function (vertices: joint.g.Point[], opt: Record<string, any>, linkView: joint.dia.LinkView) {
                    opt.useVertices = true;
                    return joint.routers.rightAngle.call(this, vertices, opt, linkView);
                }
            },
            interactive: {
                linkMove: true,
                elementMove: true,
            },
            async: true,
            sorting: joint.dia.Paper.sorting.APPROX,
            markAvailable: true,
            validateConnection: (
                sourceView: joint.dia.CellView,
                sourceMagnet: SVGElement,
                targetView: joint.dia.CellView,
                targetMagnet: SVGElement
            ) => {

                console.log("Attempting connection from:", sourceView.model.id, "to:", targetView.model.id);

                if (sourceView === targetView) return false;
                if (!sourceView || !targetView) return false;
                if (sourceView.model.id == targetView.model.id) return false;
                // if (targetView.findAttribute('port-group', targetMagnet) === 'pipes') return true;
                // if (sourceView.findAttribute('port-group', sourceMagnet) === 'pipes') return true;
                return true;
            }
        });

        this.snaplines = new joint.ui.Snaplines({ paper: paper });

        const paperScroller = this.paperScroller = new joint.ui.PaperScroller({
            paper,
            autoResizePaper: true,
            scrollWhileDragging: true,
            borderless: true,
            cursor: 'grab'
        });


        this.paperContainer.appendChild(paperScroller.el);
        paperScroller.center();

        paper.on({
            // 'paper:pan': (evt, tx, ty) => {
            //     evt.preventDefault();
            //     paperScroller.el.scrollLeft += tx;
            //     paperScroller.el.scrollTop += ty;
            // },
            
            'paper:pinch': (_evt, ox, oy, scale) => {
                console.log("pinch :::: ");
            // the default is already prevented
                const zoom = paperScroller.zoom();
                paperScroller.zoom(zoom * scale, { min: ZOOM_SETTINGS.min, max: ZOOM_SETTINGS.max, ox, oy, absolute: true });
            },

            'link:connect': (_evt, linkView) => {
                console.log("link: connect");
                const link = _evt.model;
                if (link instanceof Pipe) {
                    // link.set('flow', 1); // Correct way
                    // console.log("payload ::: ", link?.attributes?.source?.id);
                    // console.log("payload ::: ", link?.attributes?.target?.id);
                    const sorceElement = paper.model.getCell(link?.attributes?.source?.id);
                    const targetElement = paper.model.getCell(link?.attributes?.target?.id);
                    let payload = {
                            type: 'Pipe',
                            compulsory: true,
                            sourceId: sorceElement?.attributes?.equipmentId,
                            targetId: targetElement?.attributes?.equipmentId
                        }
                        this.fetchEntitiesData(payload);
                }
                // if (this.selection.collection.has(link)) {
                //     this.selection.collection.reset([link]);
                // }
                // let cellView = link.findView(paper);

                // console.log("Pipe View ::: ", linkView);

                // if (cellView) {
                //     cellView.model.set('flow', 1); // This will automatically trigger the animation if you setup 'change:flow' listener in PipeView
                //     // OR call any custom method you added to PipeView (if needed)
                //     // cellView.updateFlow(); // Manually trigger if needed
                //     console.log("Manual Update Flow");
                // }

            },
            'element:pointerdblclick': (_evt, x, y) => {
                let equipmentId = _evt.model?.get('equipmentId');
                console.log("elemnet double click");
                if(equipmentId) this.handleNavigate(equipmentId)
            },
        });

        graph.on('change:position', (element) => {
            console.log('Element position changed:', element.id, element.position());
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout); // Clear previous timeout if any
            }
            this.debounceTimeout = setTimeout(() => {
                this.handleSaveLayout(this.graph.toJSON());
            }, 1000);
        });
        this.history = new joint.dia.CommandManager({
            graph
        });
        if(this.graphDetails) this.graph.fromJSON(this.graphDetails);
        console.log(this.graph.toJSON())

    }

    initializeStencil() {

        const { stencilService, paperScroller, snaplines } = this;
        stencilService.create(paperScroller, snaplines);
        stencilService.setShapes('');
         this.stencilService.stencil.on({
                'element:drop': (elementView: joint.dia.ElementView) => {
                    console.log("element drop is working");
                    const element = elementView.model;
                    this.selection.collection.reset([element]);
                    let payload = {
                        type: element?.attributes?.type.toUpperCase(),
                        compulsory: true
                    }
                    this.fetchEntitiesData(payload);
                },
            });
            if(stencilService?.stencil?.el) {
                stencilService.stencil.el!.querySelector('.search')!.placeholder = 'Search equipment';
            }
        // stencilService.el?.querySelector('.search').placeholder = 'My Placeholder';
    }

    initializeSelection() {
        this.selection = new joint.ui.Selection({
            boxContent: null,
            paper: this.paperScroller,
            useModelGeometry: true,
            translateConnectedLinks: joint.ui.Selection.ConnectedLinksTranslation.ALL,
            // handles: [
            //     {
            //         ...joint.ui.Selection.getDefaultHandle('rotate'),
            //         position: joint.ui.Selection.HandlePosition.SW,
            //     },
            //     {
            //         ...joint.ui.Selection.getDefaultHandle('resize'),
            //         position: joint.ui.Selection.HandlePosition.SE,
            //     }
            // ],
            // frames: new joint.ui.HTMLSelectionFrameList({
            //     rotate: true
            // })
        });

        this.selection.collection.on('reset add remove', () => {
            console.log("value changes ::: 'reset add remove'", )
            return this.onSelectionChange()
        });


        // Initiate selecting when the user grabs the blank area of the paper while the Shift key is pressed.
        // Otherwise, initiate paper pan.
        this.paper.on('blank:pointerdown', (evt: joint.dia.Event, _x: number, _y: number) => {
            console.log("// Initiate selecting when the user grabs the blank area of the paper");
            if (evt.button === 2) {
                evt.preventDefault();
            }
            this.selection.collection.reset([]);
            // this.paperScroller.startPanning(evt);
            this.paper.removeTools();
            this.handleInspectorOpen(null);
        });

        // Handle port selection as element selection
        this.paper.on('element:magnet:pointerdown', (elementView: joint.dia.ElementView, evt: joint.dia.Event) => {
            evt.stopPropagation();
            console.log("ports selection :: ");
            if (!this.selection.collection.has(elementView.model)) {
                this.selection.collection.reset([elementView.model]);
            }
        });

        // Handle full element selection when clicking on body or any other part
        this.paper.on('element:pointerdown', (elementView: joint.dia.ElementView, evt: joint.dia.Event) => {
            evt.stopPropagation();
            console.log("// Handle full element selection when clicking on body or any other part::: ", elementView);
            this.selection.collection.reset([ elementView.model]);
            this.handleInspectorOpen(elementView)
        });

        this.graph.on('remove', (cell: joint.dia.Cell) => {
            console.log("handle Remove ::: ");
            if (this.selection.collection.has(cell)) {
                this.selection.collection.reset(this.selection.collection.models.filter(c => c !== cell));
            }
        });
        this.paper.on('element:contextmenu', (elementView, evt) => {
            evt.preventDefault(); // Prevent the default context menu
            console.log('Right-clicked on:', elementView.model);
            this.selection.collection.reset([ elementView.model]);
            this.handleInspectorOpen(elementView)
        });

        this.paper.on('blank', function() {
            console.log("The paper has been loaded and is now blank.");
            // Your layout logic here
            // applyLayout();
        });
    }

    onSelectionChange() {
        const { paper, selection } = this;
        const { collection } = selection;

        // Clear all previously active tools and UIs
        paper.removeTools();
        joint.ui.Halo.clear(paper);
        joint.ui.FreeTransform.clear(paper);

        // Cleanup any existing Halo/FreeTransform
        this.haloService.cleanup();

        if (collection.length === 1) {
            const primaryCell: joint.dia.Cell = collection.first();
            const primaryCellView = paper.findViewByModel(primaryCell);
            selection.destroySelectionBox(primaryCell);
            this.selectPrimaryCell(primaryCellView);
        }
    }

    selectPrimaryCell(cellView: joint.dia.CellView) {
        const cell = cellView.model;
        if (cell.isElement()) {
            this.selectPrimaryElement(<joint.dia.ElementView>cellView);
        } else {
            this.selectPrimaryLink(<joint.dia.LinkView>cellView);
        }
    }

    selectPrimaryElement(elementView: joint.dia.ElementView) {
        console.log("elementView :: :", elementView);
        this.haloService.create(elementView);
    }

    selectPrimaryLink(linkView: joint.dia.LinkView) {
        const ns = joint.linkTools;
        const tools = [
            new ns.Vertices(),
            new ns.SourceAnchor(),
            new ns.TargetAnchor(),
            new ns.SourceArrowhead(),
            new ns.TargetArrowhead(),
            new ns.Boundary({ padding: 15 }),
            new ns.Remove({ offset: -20, distance: 40 })
        ]

        const { name } = linkView.model.router();

        // Add segments tool for 'normal' router
        if (name === 'normal') tools.push(new ns.Segments());

        const toolsView = new joint.dia.ToolsView({
            name: 'link-pointerdown',
            tools
        });

        linkView.addTools(toolsView);
    }

    initializeToolsAndInspector() {

        this.paper.on('cell:pointerup', (cellView: joint.dia.CellView) => {
            const cell = cellView.model;
            console.log("cellpointer up");
            const { collection } = this.selection;
            if (collection.includes(cell)) { return; }
            collection.reset([cell]);
            if (cell.isElement()) {
                 console.log("Element dropped:", cell);
                //  this.fetchEntitiesData(); // Trigger the function
             }
        });


        // this.graph.on('change', (cell: joint.dia.Cell, opt: any) => {
        //     console.log("Cell changed:", cell, opt);

        //     if (cell instanceof joint.dia.Graph || !cell.isLink() || !opt.inspector) { return; }

        //     const ns = joint.linkTools;
        //     const toolsView = new joint.dia.ToolsView({
        //         name: 'link-inspected',
        //         tools: [
        //             new ns.Boundary({ padding: 15 }),
        //         ]
        //     });

        //     cell.findView(this.paper).addTools(toolsView);
        // });
    }


    public destroy(): void {
        const { paper, paperScroller } = this;
        paper.remove();
        paperScroller.remove();
    }
}

export default JointjsService;
