import * as joint from '@joint/plus';

export class MBR extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'MBR',
      size: {
        width: 250,
        height: 250,
      },
      attrs: {
        
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <rect x="15" y="104.573" width="56.0101" height="66.0119" fill="#3DA3F5"/>
              <rect x="71.0103" y="93.0713" width="88.0158" height="77.5139" fill="#3DA3F5"/>
              <rect x="158.986" y="98.5723" width="76.0137" height="72.0129" fill="#3DA3F5"/>
              <rect x="178.99" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="181.19" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="183.391" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="185.591" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="187.792" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="189.992" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="192.192" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="194.393" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="196.593" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="198.793" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="200.994" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="203.194" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="205.395" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="207.595" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="209.795" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="211.996" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <rect x="214.196" y="107.574" width="0.800144" height="42.0075" rx="0.400072" fill="#181818" fill-opacity="0.4"/>
              <path d="M103.041 158.98L103.041 164.857L109.829 164.857L109.829 158.98L103.041 158.98Z" fill="url(#paint0_linear_2920_887)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 101.24 156.036)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M86.062 158.98L86.062 164.857L92.8503 164.857L92.8503 158.98L86.062 158.98Z" fill="url(#paint1_linear_2920_887)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 84.2617 156.036)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M136.997 158.98L136.997 164.857L143.785 164.857L143.785 158.98L136.997 158.98Z" fill="url(#paint2_linear_2920_887)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 135.197 156.036)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M120.019 158.98L120.019 164.857L126.807 164.857L126.807 158.98L120.019 158.98Z" fill="url(#paint3_linear_2920_887)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 118.218 156.036)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M79.4118 164.801C79.4118 164.139 79.9491 163.601 80.612 163.601H149.425C150.087 163.601 150.625 164.139 150.625 164.801C150.625 165.464 150.087 166.002 149.425 166.002H80.612C79.9491 166.002 79.4118 165.464 79.4118 164.801Z" fill="white" stroke="#7A7A7A" stroke-width="0.800144" stroke-linecap="round"/>
              <path d="M176.527 158.98L176.527 164.857L183.315 164.857L183.315 158.98L176.527 158.98Z" fill="url(#paint4_linear_2920_887)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 174.727 156.036)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M210.483 158.98L210.483 164.857L217.272 164.857L217.272 158.98L210.483 158.98Z" fill="url(#paint5_linear_2920_887)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 208.683 156.036)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M193.505 158.98L193.505 164.857L200.293 164.857L200.293 158.98L193.505 158.98Z" fill="url(#paint6_linear_2920_887)" stroke="#7A7A7A" stroke-width="0.800144"/>
              <rect x="0.400072" y="0.400072" width="2.62794" height="9.77694" transform="matrix(1.31134e-07 1 1 -1.31134e-07 191.705 156.036)" fill="white" stroke="#818181" stroke-width="0.800144"/>
              <path d="M169.388 164.801C169.388 164.139 169.926 163.601 170.589 163.601H223.398C224.061 163.601 224.598 164.139 224.598 164.801C224.598 165.464 224.061 166.002 223.398 166.002H170.589C169.926 166.002 169.388 165.464 169.388 164.801Z" fill="white" stroke="#7A7A7A" stroke-width="0.800144" stroke-linecap="round"/>
              <path d="M15 93.0328V169.431C15 170.281 15.6889 170.97 16.5387 170.97H71.0101V79.0303" stroke="#7A7A7A" stroke-width="1.60029" stroke-linecap="round"/>
              <path d="M71.0103 79.0303V170.97H157.487C158.337 170.97 159.026 170.281 159.026 169.431V79.0303" stroke="#7A7A7A" stroke-width="1.60029" stroke-linecap="round"/>
              <path d="M158.986 79.0303V170.97H233.461C234.311 170.97 235 170.281 235 169.431V79.0303" stroke="#7A7A7A" stroke-width="1.60029" stroke-linecap="round"/>
              <defs>
              <linearGradient id="paint0_linear_2920_887" x1="103.041" y1="161.919" x2="109.829" y2="161.919" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_887" x1="86.062" y1="161.919" x2="92.8503" y2="161.919" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint2_linear_2920_887" x1="136.997" y1="161.919" x2="143.785" y2="161.919" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint3_linear_2920_887" x1="120.019" y1="161.919" x2="126.807" y2="161.919" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint4_linear_2920_887" x1="176.527" y1="161.919" x2="183.315" y2="161.919" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint5_linear_2920_887" x1="210.483" y1="161.919" x2="217.272" y2="161.919" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint6_linear_2920_887" x1="193.505" y1="161.919" x2="200.293" y2="161.919" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
              </svg>
        `;
  }
}
