import { useState } from 'react';
import { Box, Typography, useMediaQuery, Theme, Stack, DialogActions } from '@mui/material';
import { toast } from 'react-toastify';
import DialogBox from '../core-components/theme/components/DialogBox/DialogBox';
import { createViewAsUserToken } from '../services/newUsers';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/UserStore';
import Button from '../core-components/theme/components/Button/Button';
import { Neutrals, Primary } from '../core-components/theme/color-palette/colors';
import ApiBasedAutoComplete from '../globalComponents/apiBasedAutoComplete';

interface IViewAsProps {
  open: boolean;
  handleClose: Function;
}
interface IUser {
  _id: string;
  name: string;
}
function ViewAs({ open, handleClose }: IViewAsProps) {
  const [selectedUser, setSelectedUser] = useState<IUser>({ _id: '', name: '' });
  const navigate = useNavigate();
  const { setToken, setSideBar, setUserId, setLandingPage } = useUserStore();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // Mutation for creating a View As User token
  const mutation = useMutation({
    mutationFn: (viewAsUserId: string) => createViewAsUserToken(viewAsUserId),
    onSuccess: data => {
      toast.success('View As User Token created successfully');
      handleClose();

      const originalToken = localStorage.getItem('token');
      if (originalToken) {
        localStorage.setItem('originalToken', originalToken);
        setToken(originalToken);
      }
      localStorage.setItem('token', data.token);

      const originalSideBar = localStorage.getItem('sideBar');
      if (originalSideBar) {
        localStorage.setItem('originalSideBar', originalSideBar);
        setSideBar(JSON.parse(originalSideBar));
      }
      localStorage.setItem('sideBar', JSON.stringify(data?.layoutInfo?.sideBar));

      const originalUserId = localStorage.getItem('userId');
      if (originalUserId) {
        localStorage.setItem('originalUserId', originalUserId);
        setUserId(originalUserId);
      }
      localStorage.setItem('userId', selectedUser?._id!);

      const originalLandingPage = localStorage.getItem('landingPage');
      if (originalLandingPage) {
        localStorage.setItem('originalLandingPage', originalLandingPage);
        setLandingPage(data.layoutInfo.landingPage);
      }
      localStorage.setItem('landingPage', data?.layoutInfo?.landingPage);

      localStorage.setItem('isViewing', 'true');
      navigate(`${data?.layoutInfo?.landingPage}`);
    },
    onError: (err: any) => {
      toast.error(err?.message || 'Error creating View As User Token');
      console.error('Error creating View As User Token:', err);
    },
  });

  const handleSubmit = () => {
    if (selectedUser) {
      mutation.mutate(selectedUser?._id);
    } else {
      toast.error('Please select a user first.');
    }
  };

  return (
    <Box>
      <DialogBox
        open={open}
        title="View As"
        handleClose={handleClose}
        padding="20px"
        contentPadding="20px 0px 10px 0px"
      >
        <ApiBasedAutoComplete
          onSelect={(newValue: IUser | null) => {
            setSelectedUser(newValue || { _id: '', name: '' });
          }}
          apiPath={'/newUsers/getViewAsUserList'}
          searchKey="query"
          keyToPick="name"
          idKey="_id"
          label="Select User"
          selectedOptions={selectedUser}
        />

        <Stack direction="row" mt={4} columnGap={1} justifyContent="space-between">
          <Button
            variant="contained"
            sx={{
              border: `1px solid ${Neutrals[500]}`,
              borderRadius: '4px',
              padding: isMobile ? '10px 40px' : '10px 70px',
              backgroundColor: 'white',
              color: `${Neutrals[500]}`,
              flex: isMobile ? 1 : 0,
              minWidth: '49%',
              boxShadow: 'none',
              ':hover': {
                background: 'none',
                boxShadow: 'none',
                backgroundColor: `${Neutrals[50]}`,
              },
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <Typography variant="body" color="Neutrals.500" lineHeight="24px">
              CANCEL
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth
            sx={{
              minWidth: '50%',
              padding: isMobile ? '10px 40px' : '10px 70px',
              flex: isMobile ? 1 : 0,
              borderRadius: '4px',
              backgroundColor: `${Primary[500]}`,
              boxShadow: 'none',
              ':hover': { boxShadow: 'none', backgroundColor: `${Primary[700]}` },
            }}
          >
            <Typography variant="body" lineHeight="24px" color="white">
              SUBMIT
            </Typography>
          </Button>
        </Stack>
      </DialogBox>
    </Box>
  );
}

export default ViewAs;
