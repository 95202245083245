import { ACF_MGF } from "../shapes/equipment/ACF_MGF";
import { BLW } from "../shapes/equipment/BLOWER";
import { CL_TANK } from "../shapes/equipment/CL_TANK";
import { CP_TANK } from "../shapes/equipment/CP_TANK";
import { DA_TANK } from "../shapes/equipment/DA_TANK";
import { DE_TANK } from "../shapes/equipment/DE_TANK";
import { DOS_TANK } from "../shapes/equipment/DOS_TANK";
import { DS_PUMP } from "../shapes/equipment/DS_PUMP";
import { F_PRESS } from "../shapes/equipment/F_PRESS";
import { FS_PUMP } from "../shapes/equipment/FS_PUMP";
import { MBBR } from "../shapes/equipment/MBBR";
import { MBR } from "../shapes/equipment/MBR";
import { MOTOR } from "../shapes/equipment/MOTOR";
import { OH_TANK } from "../shapes/equipment/OH_TANK";
import { OP_TANK } from "../shapes/equipment/OP_TANK";
import { OS_TANK } from "../shapes/equipment/OS_TANK";
import { OS_TANK_DF } from "../shapes/equipment/OS_TANK_DF";
import { OZONATOR } from "../shapes/equipment/OZONATOR";
import { SC_PRESS } from "../shapes/equipment/SC_PRESS";
import { SH_TANK } from "../shapes/equipment/SH_TANK";
import { SMC_PUMP } from "../shapes/equipment/SMC_PUMP";
import { SOFTNER } from "../shapes/equipment/SOFTNER";
import { T_SETTLER } from "../shapes/equipment/T_SETTLER";
import { TANK } from "../shapes/equipment/TANK";
import { TANK_2 } from "../shapes/equipment/TANK_2";
import { UF_MEM } from "../shapes/equipment/UF_MEM";
import { VALVE_2 } from "../shapes/equipment/VALVE_2";
import { LEVEL_SENSOR } from "../shapes/sensors/LEVEL_SENSOR";

export function renderEquipment(type: string) {
        if(type) {
            switch(type) {
                case 'SMC_PUMP':
                    return new SMC_PUMP({
                        name: 'SMC_PUMP',
                        // size: { width: 60, height: 60 },
                    });
                case 'DS_PUMP':
                    return new DS_PUMP({
                        name: 'DS_PUMP',
                        // size: { width: 60, height: 60 },
                    });
                case 'FS_PUMP':
                    return new FS_PUMP({
                        name: 'FS_PUMP',
                        // size: { width: 60, height: 60 },
                    });
                case 'SC_PRESS':
                    return new SC_PRESS({
                        name: 'SC_PRESS',
                        // size: { width: 60, height: 60 },
                    });
                case 'SH_TANK':
                    return new SH_TANK({
                        name: 'SH_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'SOFTNER':
                    return new SOFTNER({
                        name: 'SOFTNER',
                        // size: { width: 60, height: 60 },
                    });
                case 'ACF_MGF':
                    return new ACF_MGF({
                        name: 'ACF_MGF',
                        // size: { width: 60, height: 60 },
                    });
                case 'F_PRESS':
                    return new F_PRESS({
                        name: 'F_PRESS',
                        // size: { width: 60, height: 60 },
                    });
                case 'OZONATOR':
                    return new OZONATOR({
                        name: 'OZONATOR',
                        // size: { width: 60, height: 60 },
                    });
                case 'MOTOR':
                    return new MOTOR({
                        name: 'MOTOR',
                        // size: { width: 60, height: 60 },
                    });
                case 'MBR':
                    return new MBR({
                        name: 'MBR',
                        // size: { width: 60, height: 60 },
                    });
                case 'MBBR':
                    return new MBBR({
                        name: 'MBBR',
                        // size: { width: 60, height: 60 },
                    });
                case 'DE_TANK':
                    return new DE_TANK({
                        name: 'DE_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'DA_TANK':
                    return new DA_TANK({
                        name: 'DA_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'BLW':
                    return new BLW({
                        name: 'BLOWER',
                        // size: { width: 60, height: 60 },
                    });
                case 'VALVE_2':
                    return new VALVE_2({
                        name: 'VALVE_2',
                        // size: { width: 60, height: 60 },
                    });
                case 'T_SETTLER':
                    return new T_SETTLER({
                        name: 'T_SETTLER',
                        // size: { width: 60, height: 60 },
                    });
                case 'UF_MEM':
                    return new UF_MEM({
                        name: 'UF_MEM',
                        // size: { width: 60, height: 60 },
                    });
                case 'DOS_TANK':
                    return new DOS_TANK({
                        name: 'DOS_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'CL_TANK':
                    return new CL_TANK({
                        name: 'CL_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'TANK':
                    return new TANK({
                        name: 'TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'CP_TANK':
                    return new CP_TANK({
                        name: 'CP_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'OP_TANK':
                    return new OP_TANK({
                        name: 'OP_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'TANK_2':
                    return new TANK_2({
                        name: 'TANK_2',
                        // size: { width: 60, height: 60 },
                    });
                case 'OS_TANK':
                    return new OS_TANK({
                        name: 'OS_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'OH_TANK':
                    return new OH_TANK({
                        name: 'OH_TANK',
                        // size: { width: 60, height: 60 },
                    });
                case 'OS_TANK_DF': 
                    return new OS_TANK_DF({
                        name: 'OS_TANK_DF',
                        // size: { width: 60, height: 60 },
                    })
                case 'LEVEL_SENSOR': 
                return new LEVEL_SENSOR({
                    name: 'LEVEL_SENSOR'
                })
                // case 'CS_TANK':
                //     return new CS_TANK({
                //         name: '2-W Valve',
                //         size: { width: 60, height: 60 },
                //     });
            }
        }
    }