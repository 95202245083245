import * as joint from '@joint/plus';

export class SH_TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "SH_TANK",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        root: {
          magnet: true
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect width="242" height="156.292" transform="matrix(1 0 0 -1 4 222.079)" fill="url(#paint0_linear_2920_283)"/>
        <path d="M4 36.7476V219.023C4 220.737 5.38907 222.126 7.10257 222.126H242.897C244.611 222.126 246 220.737 246 219.023V36.7476" stroke="#7A7A7A" stroke-width="3.10256" stroke-linecap="round"/>
        <rect x="32.7714" y="47.2342" width="184.727" height="23.1244" rx="11.5622" fill="#D6D6D6" fill-opacity="0.8" stroke="#818181" stroke-width="2.15111" stroke-dasharray="6.45 6.45"/>
        <path d="M54.2827 58.7964C54.2827 63.103 50.7915 66.5942 46.4849 66.5942C42.1783 66.5942 38.6871 63.103 38.6871 58.7964C38.6871 54.4898 42.1783 50.9987 46.4849 50.9987C50.7915 50.9987 54.2827 54.4898 54.2827 58.7964Z" fill="white" stroke="#989498" stroke-width="2.15111"/>
        <circle cx="203.785" cy="58.7964" r="7.79778" fill="white" stroke="#989498" stroke-width="2.15111"/>
        <rect x="177.233" y="45.5742" width="13.8885" height="4.63936" rx="0.1182" transform="rotate(-90 177.233 45.5742)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.211829"/>
        <rect x="202.685" y="45.5742" width="13.8885" height="4.63936" rx="0.1182" transform="rotate(-90 202.685 45.5742)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.211829"/>
        <rect x="202.242" y="46.9036" width="16.5481" height="0.591002" rx="0.1182" transform="rotate(-90 202.242 46.9036)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.211829"/>
        <rect x="181.849" y="46.2454" width="15.2883" height="20.4119" transform="rotate(-90 181.849 46.2454)" fill="url(#paint1_linear_2920_283)" stroke="#7A7A7A" stroke-width="0.211829"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M172.593 40.7566C172.593 43.3188 174.67 45.396 177.233 45.396L177.233 31.8325C174.67 31.8325 172.593 33.9097 172.593 36.4719L172.593 40.7566Z" fill="url(#paint2_linear_2920_283)" stroke="#7A7A7A" stroke-width="0.211829"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M179.314 43.1206C179.071 43.1206 178.867 43.2849 178.813 43.5059C178.797 43.5693 178.852 43.623 178.917 43.623H180.188C180.253 43.623 180.307 43.5693 180.292 43.5059C180.238 43.2849 180.034 43.1206 179.79 43.1206H179.314Z" fill="white" stroke="#181818" stroke-width="0.177301"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M179.314 39.9592C179.071 39.9592 178.867 40.1235 178.813 40.3445C178.797 40.4079 178.852 40.4616 178.917 40.4616H180.188C180.253 40.4616 180.307 40.4079 180.292 40.3445C180.238 40.1235 180.034 39.9592 179.79 39.9592H179.314Z" fill="white" stroke="#181818" stroke-width="0.177301"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M179.314 36.7979C179.071 36.7979 178.867 36.9621 178.813 37.1831C178.797 37.2465 178.852 37.3002 178.917 37.3002H180.188C180.253 37.3002 180.307 37.2465 180.292 37.1831C180.238 36.9621 180.034 36.7979 179.79 36.7979H179.314Z" fill="white" stroke="#181818" stroke-width="0.177301"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M179.314 33.6353C179.071 33.6353 178.867 33.7995 178.813 34.0205C178.797 34.0839 178.852 34.1376 178.917 34.1376H180.188C180.253 34.1376 180.307 34.0839 180.292 34.0205C180.238 33.7995 180.034 33.6353 179.79 33.6353H179.314Z" fill="white" stroke="#181818" stroke-width="0.177301"/>
        <rect x="181.849" y="45.9983" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 45.9983)" fill="#7A7A7A"/>
        <rect x="181.849" y="45.5024" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 45.5024)" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="181.849" y="45.0066" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 45.0066)" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="181.849" y="44.5107" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 44.5107)" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="181.849" y="44.0149" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 44.0149)" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="181.849" y="43.519" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 43.519)" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="181.849" y="43.0232" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 43.0232)" fill="#7A7A7A" fill-opacity="0.8"/>
        <rect x="181.849" y="42.5273" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 42.5273)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="42.0315" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 42.0315)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="41.5356" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 41.5356)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="41.0398" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 41.0398)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="40.5442" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 40.5442)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="40.0483" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 40.0483)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="39.5525" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 39.5525)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="39.0566" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 39.0566)" fill="#7A7A7A" fill-opacity="0.6"/>
        <rect x="181.849" y="38.5608" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 38.5608)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="38.0649" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 38.0649)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="37.5691" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 37.5691)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="37.0732" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 37.0732)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="36.5774" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 36.5774)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="36.0815" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 36.0815)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="35.5857" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 35.5857)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="35.0898" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 35.0898)" fill="#7A7A7A" fill-opacity="0.4"/>
        <rect x="181.849" y="34.594" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 34.594)" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="181.849" y="34.0981" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 34.0981)" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="181.849" y="33.6023" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 33.6023)" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="181.849" y="33.1064" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 33.1064)" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="181.849" y="32.6106" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 32.6106)" fill="#7A7A7A" fill-opacity="0.2"/>
        <rect x="181.849" y="32.1147" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 32.1147)" fill="#7A7A7A" fill-opacity="0.1"/>
        <rect x="181.849" y="31.6189" width="0.165279" height="20.4119" rx="0.061026" transform="rotate(-90 181.849 31.6189)" fill="#7A7A7A" fill-opacity="0.1"/>
        <rect x="183.461" y="34.7888" width="1.92076" height="17.3262" rx="0.1182" transform="rotate(-90 183.461 34.7888)" fill="#181818"/>
        <rect x="183.409" y="39.0435" width="0.856953" height="17.3262" rx="0.1182" transform="rotate(-90 183.409 39.0435)" fill="#181818"/>
        <rect x="185.287" y="40.1665" width="3.10276" height="10.1448" rx="0.1182" transform="rotate(-90 185.287 40.1665)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.211829"/>
        <rect x="183.461" y="44.3633" width="1.92076" height="17.3262" rx="0.1182" transform="rotate(-90 183.461 44.3633)" fill="#181818"/>
        <path d="M184.945 49.385C184.553 49.385 184.236 49.0675 184.236 48.6758L184.236 46.4005C184.236 46.3352 184.289 46.2822 184.354 46.2822L197.12 46.2822C197.185 46.2822 197.238 46.3352 197.238 46.4004L197.238 48.6758C197.238 49.0675 196.92 49.385 196.529 49.385L184.945 49.385Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.211829"/>
        <circle cx="186.954" cy="47.8185" r="1.182" transform="rotate(-90 186.954 47.8185)" fill="#181818"/>
        <path d="M184.354 30.9771C184.289 30.9771 184.236 30.9241 184.236 30.8589L184.236 28.5835C184.236 28.1918 184.553 27.8743 184.945 27.8743L196.529 27.8743C196.92 27.8743 197.238 28.1918 197.238 28.5835L197.238 30.8588C197.238 30.9241 197.185 30.9771 197.12 30.9771L184.354 30.9771Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.211829"/>
        <circle cx="186.954" cy="29.4105" r="1.182" transform="rotate(-90 186.954 29.4105)" fill="#181818"/>
        <path d="M202.877 43.387L207.31 43.387" stroke="#7A7A7A" stroke-width="0.236401" stroke-linecap="square"/>
        <path d="M202.877 42.4421L207.31 42.4421" stroke="#7A7A7A" stroke-width="0.236401" stroke-linecap="square"/>
        <path d="M202.877 37.064L207.31 37.064" stroke="#7A7A7A" stroke-width="0.236401" stroke-linecap="square"/>
        <rect x="203.72" y="41.4375" width="5.61452" height="2.57086" rx="0.1182" transform="rotate(-90 203.72 41.4375)" fill="white" stroke="#7A7A7A" stroke-width="0.211829"/>
        <rect x="203.808" y="46.2834" width="1.1229" height="1.182" rx="0.1182" transform="rotate(-90 203.808 46.2834)" fill="#181818"/>
        <rect x="203.808" y="32.0986" width="1.1229" height="1.182" rx="0.1182" transform="rotate(-90 203.808 32.0986)" fill="#181818"/>
        <defs>
        <linearGradient id="paint0_linear_2920_283" x1="121" y1="0" x2="121" y2="156.292" gradientUnits="userSpaceOnUse">
        <stop offset="0.149" stop-color="#D18B43"/>
        <stop offset="0.764" stop-color="#664019"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2920_283" x1="197.137" y1="56.4513" x2="181.849" y2="56.4513" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2920_283" x1="174.913" y1="31.8325" x2="174.913" y2="45.396" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        </defs>
      </svg>
       `;
  }
}