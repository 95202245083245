import { dia, shapes, ui } from '@joint/plus';
import { useEffect, useRef, useCallback } from 'react';
import { NEWBLOWER, NEWBLOWERView } from '../shapes/equipment/NEWBLOWER';
import { Button } from '@mui/material';
import { Pipe } from '../shapes/equipment/Pipe';

const namespace = {
    ...shapes,
    NEWBLOWER,
    NEWBLOWERView
}
export function AnimationDemo() {
    const canvas = useRef<HTMLDivElement | null>(null);
    const rectRef = useRef<NEWBLOWER | null>(null); // Add this ref to store the BLOWER instance

    const handleButtonClick = useCallback(() => {
        if (rectRef.current) {
            // Toggle the power state
            if (rectRef.current) {
                // Use prop() instead of set() to trigger change events
                const currentPower = rectRef.current.prop('power');
                rectRef.current.prop('power', currentPower ? 0 : 1);
            }
            // rectRef.current.set('power', 1);
            // rectRef.current.power = 1;
        }
    }, []);

    useEffect(() => {
        const graph = new dia.Graph({
            cellNamespace: namespace
        });

        const paper = new dia.Paper({
            model: graph,
            size: { width: 1000, height: 500 },
            background: {
                color: '#F8F9FA',
            },
            frozen: true,
            async: true,
            cellViewNamespace: namespace
        });

        const scroller = new ui.PaperScroller({
            paper,
            autoResizePaper: true,
            cursor: 'grab'
        });

        if (canvas.current) {
            canvas.current.appendChild(scroller.el);
            scroller.render().center();
        }

        // Create the BLOWER element and store it in the ref
        const rect = new NEWBLOWER({
            position: { x: 100, y: 20 },
            size: { width: 100, height: 50 },
        });
        const rect1 = new NEWBLOWER({
            position: { x: 200, y: 20 },
            size: { width: 100, height: 50 },
        });

        rectRef.current = rect;

        const pipe = new Pipe({
            position: { x: 150, y: 20 },
            size: { width: 100, height: 20 },
        })
        // pipe.source(rect);
        // pipe.target(rect1);

        // graph.addCell(rect);
        graph.addCell(rect1);
        graph.addCell(pipe); 
        
        paper.unfreeze();
        paper.on('cell:pointerclick', (cellView) => {
            if (cellView.model.get('type') === 'NEWBLOWER') {
              const currentPower = cellView.model.prop('power') || 0;
              cellView.model.prop('power', currentPower ? 0 : 1);
            }
            if (cellView.model.get('type') === 'Pipe') {
                cellView.model.setFlow(1);
            }
          });
        return () => {
            scroller.remove();
            paper.remove();
        };
    }, []);

    return (
        <div className="welspun canvas">
            <Button onClick={handleButtonClick}>
                Toggle Power
            </Button>
            <div ref={canvas} className="paper-container"></div>
        </div>
    );
}