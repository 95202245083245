import * as joint from '@joint/plus';

export class TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "TANK",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        root: {
          magnet: true
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <path d="M89.7013 4.63393C75.1185 5.17404 66.726 7.51033 62.5354 9.55134C60.2813 10.6492 59.3853 13.0522 59.3848 15.5595C59.3701 83.3304 58.9795 203.665 58.9193 233.206C58.9122 236.686 60.5068 239.962 63.7573 241.203C73.3491 244.865 90.1817 246.084 98.7014 246.215H157.439C170.569 246.215 180.209 243.689 185.958 241.316C189.32 239.928 191.071 236.513 191.071 232.876V16.1431C191.071 12.9818 189.727 9.97893 186.774 8.85128C178.811 5.8109 164.417 4.76241 156.965 4.63393C143.07 4.31814 110.165 3.87603 89.7013 4.63393Z" fill="url(#paint0_linear_2920_1075)" stroke="#7A7A7A" stroke-width="1.89476"/>
        <path d="M68.3853 231.617H181.123" stroke="url(#paint1_linear_2920_1075)" stroke-width="1.89476" stroke-linecap="round"/>
        <path d="M68.3852 19.4036H181.123" stroke="url(#paint2_linear_2920_1075)" stroke-width="1.89476" stroke-linecap="round"/>
        <path d="M68.3853 30.7725H181.123" stroke="url(#paint3_linear_2920_1075)" stroke-width="1.89476" stroke-linecap="round"/>
        <defs>
        <linearGradient id="paint0_linear_2920_1075" x1="191.071" y1="125.215" x2="58.9115" y2="125.215" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2920_1075" x1="68.3853" y1="232.117" x2="181.123" y2="232.117" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#A6A6A6"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#A6A6A6"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2920_1075" x1="68.3852" y1="19.9036" x2="181.123" y2="19.9036" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#A6A6A6"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#A6A6A6"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2920_1075" x1="68.3853" y1="31.2725" x2="181.123" y2="31.2725" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#A6A6A6"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#A6A6A6"/>
        </linearGradient>
        </defs>
        </svg>
       `;
  }
}