import * as joint from '@joint/plus';

export class ACF_MGF extends joint.dia.Element {
    defaults() {
        return {
            ...super.defaults,
            type: "ACF_MGF",
            size: {
                width: 250,
                height: 250
            },
            attrs: {
            }

        };
    }

    preinitialize() {
        this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <path d="M98.5127 38.0825C98.2082 38.0825 98.0559 38.0825 97.9613 38.1771C97.8667 38.2717 97.8667 38.424 97.8667 38.7286L97.8667 40.3437L102.066 40.3437L102.066 38.7286C102.066 38.424 102.066 38.2717 101.971 38.1771C101.877 38.0825 101.724 38.0825 101.42 38.0825L98.5127 38.0825Z" fill="#C0E8DC" stroke="#1A4639" stroke-width="0.646037"/>
              <path d="M98.5127 25.0001C98.2082 25.0001 98.0559 25.0001 97.9613 25.0947C97.8667 25.1893 97.8667 25.3416 97.8667 25.6462L97.8667 26.6152C97.8667 26.9198 97.8667 27.072 97.9613 27.1666C98.0559 27.2613 98.2082 27.2613 98.5127 27.2613L101.42 27.2613C101.724 27.2613 101.877 27.2613 101.971 27.1666C102.066 27.072 102.066 26.9198 102.066 26.6152L102.066 25.6462C102.066 25.3416 102.066 25.1893 101.971 25.0947C101.877 25.0001 101.724 25.0001 101.42 25.0001L98.5127 25.0001Z" fill="#C0E8DC" stroke="#1A4639" stroke-width="0.646037"/>
              <path d="M98.6738 27.2614L98.6738 38.0825L101.419 38.0825L101.419 27.2614L98.6738 27.2614Z" fill="#C0E8DC" stroke="#1A4639" stroke-width="0.646037"/>
              <path d="M143.978 211.716C143.978 212.325 143.978 212.629 143.788 212.819C143.599 213.008 143.295 213.008 142.685 213.008L107.315 213.008C106.706 213.008 106.401 213.008 106.212 212.819C106.023 212.629 106.023 212.325 106.023 211.716L106.023 210.908C106.023 210.299 106.023 209.995 106.212 209.805C106.401 209.616 106.706 209.616 107.315 209.616L142.685 209.616C143.295 209.616 143.599 209.616 143.788 209.805C143.978 209.995 143.978 210.299 143.978 210.908L143.978 211.716Z" fill="#C0E8DC" stroke="#1A4639" stroke-width="1.29207"/>
              <path d="M109.804 209.612C109.106 209.612 108.757 209.612 108.662 209.461C108.567 209.309 108.753 208.924 109.125 208.152C109.293 207.804 109.383 207.438 109.383 207.061C109.383 206.684 109.293 206.319 109.125 205.97C108.753 205.199 108.567 204.813 108.662 204.662C108.757 204.51 109.106 204.51 109.804 204.51L140.197 204.51C140.895 204.51 141.244 204.51 141.339 204.662C141.434 204.813 141.248 205.199 140.876 205.97C140.708 206.319 140.618 206.684 140.618 207.061C140.618 207.438 140.708 207.804 140.876 208.152C141.248 208.924 141.434 209.309 141.339 209.461C141.244 209.612 140.895 209.612 140.197 209.612L109.804 209.612Z" fill="#C0E8DC" stroke="#1A4639" stroke-width="1.29207"/>
              <path d="M143.978 32.8762C143.978 32.2671 143.978 31.9625 143.788 31.7733C143.599 31.5841 143.295 31.5841 142.685 31.5841L107.315 31.5841C106.706 31.5841 106.401 31.5841 106.212 31.7733C106.023 31.9625 106.023 32.2671 106.023 32.8762L106.023 33.6837C106.023 34.2928 106.023 34.5974 106.212 34.7866C106.401 34.9758 106.706 34.9758 107.315 34.9758L142.685 34.9758C143.295 34.9758 143.599 34.9758 143.788 34.7866C143.978 34.5974 143.978 34.2928 143.978 33.6837L143.978 32.8762Z" fill="#C0E8DC" stroke="#1A4639" stroke-width="1.29207"/>
              <path d="M109.804 34.9802C109.106 34.9802 108.757 34.9802 108.662 35.1315C108.567 35.2827 108.753 35.6684 109.125 36.4398C109.293 36.7884 109.383 37.1541 109.383 37.531C109.383 37.9078 109.293 38.2735 109.125 38.6221C108.753 39.3935 108.567 39.7792 108.662 39.9304C108.757 40.0817 109.106 40.0817 109.804 40.0817L140.197 40.0817C140.895 40.0817 141.244 40.0817 141.339 39.9304C141.434 39.7792 141.248 39.3935 140.876 38.6221C140.708 38.2735 140.618 37.9078 140.618 37.531C140.618 37.1541 140.708 36.7884 140.876 36.4398C141.248 35.6684 141.434 35.2827 141.339 35.1315C141.244 34.9802 140.895 34.9802 140.197 34.9802L109.804 34.9802Z" fill="#C0E8DC" stroke="#1A4639" stroke-width="1.29207"/>
              <path d="M89.4678 200.776L89.4678 225" stroke="#1A4639" stroke-width="8.72151" stroke-linecap="round"/>
              <path d="M160.532 200.774V223.95" stroke="#1A4639" stroke-width="8.72151" stroke-linecap="round"/>
              <path d="M89.3281 37.0925C89.3281 36.6465 89.6897 36.2849 90.1357 36.2849H93.1579C93.6039 36.2849 93.9655 36.6465 93.9655 37.0925V42.0865C93.9655 42.5325 93.6039 42.8941 93.1579 42.8941H90.1357C89.6897 42.8941 89.3281 42.5325 89.3281 42.0865V37.0925Z" fill="#1A4639"/>
              <path d="M90.3983 38.6926C90.3983 38.0523 90.9173 37.5332 91.5576 37.5332C92.1979 37.5332 92.717 38.0523 92.717 38.6926C92.717 39.3329 92.1979 39.8519 91.5576 39.8519C90.9173 39.8519 90.3983 39.3329 90.3983 38.6926Z" fill="white"/>
              <path d="M156.035 36.735C156.035 36.289 156.397 35.9275 156.843 35.9275H159.865C160.311 35.9275 160.673 36.289 160.673 36.735V41.7291C160.673 42.1751 160.311 42.5367 159.865 42.5367H156.843C156.397 42.5367 156.035 42.1751 156.035 41.7291V36.735Z" fill="#1A4639"/>
              <path d="M157.105 38.3352C157.105 37.6949 157.624 37.1758 158.265 37.1758C158.905 37.1758 159.424 37.6949 159.424 38.3352C159.424 38.9755 158.905 39.4945 158.265 39.4945C157.624 39.4945 157.105 38.9755 157.105 38.3352Z" fill="white"/>
              <rect x="68.4717" y="39.8933" width="113.057" height="164.957" rx="29.0717" fill="url(#paint0_linear_2920_1542)" stroke="#1A4639" stroke-width="1.29207"/>
              <defs>
              <linearGradient id="paint0_linear_2920_1542" x1="181.528" y1="207.848" x2="68.4717" y2="207.848" gradientUnits="userSpaceOnUse">
              <stop stop-color="#1A4639"/>
              <stop offset="0.1" stop-color="#2C7761"/>
              <stop offset="0.2" stop-color="#379579"/>
              <stop offset="0.4" stop-color="#45BA97"/>
              <stop offset="0.6" stop-color="#45BA97"/>
              <stop offset="0.8" stop-color="#379579"/>
              <stop offset="0.9" stop-color="#2C7761"/>
              <stop offset="1" stop-color="#1A4639"/>
              </linearGradient>
              </defs>
            </svg>
        `;
    }
}