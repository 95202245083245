import { useRef, useEffect, useState, useMemo } from 'react';
import { dia, shapes, util, ui } from '@joint/plus';
import * as joint from '@joint/plus';
window.joint = joint

import { StencilService } from './services/stencil-service';
import { HaloService } from './services/halo-service';
import { KeyboardService } from './services/keyboard-service';
import { NavigatorService } from './services/navigator-service';
import JointjsService from './services/jointjs-service';

import withPermissionCheck from '../../../hoc/withRoutePermission';
import { PERMISSION_TAGS } from '../../../constants/permissions';
import QuestionsDialog from '../assetConfiguration/questionsDialog';
import { ScadaConfigurationPathConstants } from '../assetConfiguration/assetConstants/pathConstants';
import FloatingLoader from '../../../globalComponents/loader/FloatingLoader';
import { ScadaConfigurationInterface } from '../assetConfiguration/types';
import { useMutation } from '@tanstack/react-query';
import { getQuestionsJointGraph } from '../../../services/jointjsConfig';
import { toast } from 'react-toastify';
import { saveToEquipment, submitScadaAnswer } from '../assetConfiguration/config/scadaConfigurationConfig';
import Inspector from './Inspector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSaveGraphLayout } from '../../../hooks/useSaveGraphLayout';
import { renderEquipment } from '../visualizationHelper/shapesHelper';
import { getLastParams } from '../visualizationHelper/helperFunctions';

function ScadaConfiguration({graphDetails}: ScadaConfigurationInterface) {

    const rappid = useRef<JointjsService>(null);
    const elementRef = useRef<HTMLDivElement>(null);

    // Containers
    const paperContainer = useRef<HTMLDivElement>(null);
    const stencilContainer = useRef<HTMLDivElement>(null);
    const toolbarContainer = useRef<HTMLDivElement>(null);
    const navigatorContainer = useRef<HTMLDivElement>(null);

    const [questions, setQuestions] = useState<null | any[]>(null);
    const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
    const [showInspector, setShowInspector] = useState<boolean>(false); 
    const [selectedInspector, setSelectedInspector] = useState(null);
    const [selectedStencil, setSelectedStencil] = useState<string>('equipment');
    const navigate = useNavigate();
    const saveLayout = useSaveGraphLayout();
    const {assetId = ''} = useParams<string>();
    const location = useLocation();
    const lastParam = getLastParams({location})
    
    const mutation = useMutation({
        mutationFn: submitScadaAnswer,
        onSuccess: (data: any) => {
            toast.success(data?.message);
            updateExistingFiled(data)
        },
        onError: (err: any) => {
            toast.error(err?.message || 'Problem in saving Answer');
        },
    });

    const saveSensorToEquipment = useMutation({
        mutationFn: saveToEquipment,
        onSuccess: (data: any, variables, context) => {
            console.log(variables, context);
            addSensorEquipmentToGraph(variables.sensorType);
        },
        onError: (err: any) => {
            toast.error(err?.message || 'Problem in saving Answer');
        },
    });

    

    const updateExistingFiled = (data: any) => {
        let elementView = rappid?.current?.selection?.model?.models[0];
        let newTankType = '';
        if(questions?.length) {
            questions.map((question: any) => {
                if(question?.question === 'Color') {
                    elementView.attr('liquid/fill', question.answer.toLowerCase());
                    elementView.prop('liquidColor', question.answer.toLowerCase());
                    // elementView.prop('equipmentId', data?.result);
                }
                if(question?.question === 'Name') {
                    elementView.attr('label/text', question.answer);
                    // elementView.prop('equipmentId', data?.result);
                }
                if(question?.answer === 'Diffuser' || question?.answer === 'Submersible' || question?.answer === 'Screen Chamber' ) {
                    newTankType = question?.answer;
                }
            })
        }
        if(newTankType) {
            elementView = getUpdatedSvg(elementView, newTankType);
            rappid?.current?.graph?.addCell(elementView);
        }
        if(elementView) {
            elementView.prop('equipmentId', data?.result);
        }
        handleSaveLayout(rappid?.current?.graph.toJSON());
        setQuestions([]);
    }

    const isDialogOpen = useMemo(() => {
        if(questions && questions?.length) {
            return true
        }
        return false;
    }, [questions])

    const getUpdatedSvg = (elementView: any, newElementType: string) => {
        const oldElement = elementView;
        const position = oldElement.position();
        const oldAttrs = oldElement.attr();
        let elementType = '';
        if(newElementType === 'Diffuser') {
            elementType = 'OS_TANK_DF'
        }
        else if(newElementType === 'Submersible') {
            elementType = 'OS_TANK_SP';
        }
        else if(newElementType === 'Screen Chamber') {
            elementType = 'OS_TANK_SC';
        }

        
        let newElement = renderEquipment(elementType);
        if(elementType) {
            newElement?.prop('position', position);
            // newElement.prop('size', size);
            newElement?.prop('type', newElementType);
            if(oldAttrs?.liquid?.fill) newElement?.attr('liquid/fill', oldAttrs?.liquid?.fill);
            if(oldAttrs?.water?.fill) newElement?.attr('water/fill', oldAttrs?.liquid?.fill);
        }
        else {
            newElement = oldElement
        }
        // Remove old element
        oldElement.remove();
        return newElement;
    }


    useEffect(() => {
        const services = {
            stencilService: new StencilService(stencilContainer.current),
            haloService: new HaloService(),
            keyboardService: new KeyboardService(),
            navigatorService: new NavigatorService(navigatorContainer.current),
        };

        rappid.current = new JointjsService(
            elementRef.current!,
            paperContainer.current!,
            services,
            graphDetails,
            fetchEntitiesData,
            handleInspectorOpen,
            handleNavigate,
            handleSaveLayout,
        );

        rappid.current.startRappid();

        return () => {
            rappid?.current?.destroy();
        }
    }, []);

    const handleDialogClose = () => {
        rappid!.current!.history.cancel();
        setQuestions(null);
    }

    const handleChage = ({name, newValue, type, index}: any) => {
        let newFormState: any = questions ? [...questions] : [];
        newFormState[index][name] = newValue;
        setQuestions(newFormState)
    }

     async function fetchEntitiesData(payload: any) {
        setQuestionsLoading(true);
        let fetchedEntities = await getQuestionsJointGraph({path: ScadaConfigurationPathConstants.GET_QUESTIONS, payload})
        console.log("fetchedEntities ::: ", fetchedEntities);
        if(fetchedEntities?.length && fetchedEntities?.type !== 'error') {
            setQuestions(fetchedEntities);
        }
        if(fetchedEntities?.type === 'error') {
            handleDialogClose();
        }
        setQuestionsLoading(false);
    }

    const handleSubmit = () => {
        let type = rappid?.current?.selection?.model?.models[0]?.attributes?.type;
        let payload = {
            answers: prepareAnswer(),
            type: type
        }
        mutation.mutate(payload);
    }

    const handleInspectorOpen = (element: any) => {
        if(!element ||!element?.model?.get('equipmentId')) {
            setSelectedInspector(null);
            setShowInspector(false);
            return
        };
        if(!selectedInspector) {
            console.log("!selectedInspector :: ", showInspector);
            console.log("selectedInspector :: ", selectedInspector);
            setSelectedInspector(element?.model);
            setShowInspector(true);
        }
        else if( selectedInspector?.get('equipmentId') !== element?.model?.get('equipmentId')) {
            setSelectedInspector(element?.model);
            setShowInspector(true);
        }

    }

    const prepareAnswer = () => {
        const newAnswer = questions?.map(question => {
            return {
                answer: question?.answer,
                nodeType: question?.nodeType,
                options: question?.nodeType,
                order: question?.order,
                question: question?.question,
                questionId: question?.questionId,
                questionType: question?.questionType,
                type: question?.type
            }
        })
        return newAnswer;
    }

    const handleLoadStencil = (type: string) => {
        setSelectedStencil(type);
        console.log("clicked")
        rappid?.current?.stencilService.setShapes(type);
    }

    const addSensorEquipmentToGraph = (sensorType: string) => {
        let newSensor: any = createSensor(sensorType);
        let graph = rappid?.current?.graph;
        // newSensor.prop('equipmentId', equipmentId);
        newSensor.addTo(graph);
        let targetElement = rappid?.current?.selection?.model?.models[0];
        const gaugeLink = new shapes.standard.Link({
        source: { id: newSensor.id },
        target: { id: targetElement.id },
        z: -1,
        attrs: {
            line: {
            strokeDasharray: "5 5",
            // targetMarker: {
            //   type: "circle",
            //   r: 12,
            //   fill: "#eee",
            //   stroke: "#666",
            //   "stroke-width": 2
            // },
            stroke: "#aaa"
            }
        }
        });
        gaugeLink.addTo(graph);
        handleSaveLayout(rappid?.current?.graph.toJSON());
    }

    const handleAddSensorEquipment = (data: any, sensorType: string) => {
        let payload = {
            apiPayload: {
                equipmentId: selectedInspector?.getAttr('equipmentId'),
                sensors: [data],
                type: sensorType
            },
            sensorType
        }
        saveSensorToEquipment.mutate(payload);
    }

    const createSensor = (sensorType: string) => {
        switch (sensorType) {
            case "gauge":
                return new  shapes.chart.Knob({
                    position: { x: 380, y: 100 },
                    size: { width: 120, height: 120 },
                    min: 0,
                    max: 10,
                    step: 0.1,
                    value: 10,
                    fill: 'green',
                    // @ts-ignore
                    attrs: {
                        root: {
                        "font-family": "sans-serif"
                        }
                    },
                    serieDefaults: {
                        startAngle: 90,
                        label: "Ⓟ bar"
                    },
                    sliceDefaults: {
                        legendLabel: "{value:.1f}",
                        onClickEffect: { type: "none" }
                    }
                });
        }
    }

    const handleNavigate = (equipmentId: string) => {
        navigate(equipmentId)
    }

    const handleSaveLayout = (layout: dia.Graph.Cells) => {
        let payload = {
            id: lastParam,
            layout
        }
        saveLayout.mutate(payload);
    }

    console.log("graph json::: ", rappid?.current?.graph.toJSON());

    return (
        <div id="app" className="joint-app">
            <div className="app-body">
                <div className='stencil-section-container'>
                    <div className='stencil-layout-view'>
                        <div ref={stencilContainer} className="stencil-container"></div>
                        <div className='stencil-gradient-divider'></div>
                        <div className='stencil-section-content'>
                            <div className={'list-item ' + (selectedStencil === 'equipment' ? 'selected' : '')} onClick={() => handleLoadStencil('equipment')}>Equipment</div>
                            <div  className={'list-item ' + (selectedStencil !== 'equipment' ? 'selected' : '')} onClick={() => handleLoadStencil('sensor')}>Sensor</div>
                        </div>
                    </div>
                </div>
                <div ref={paperContainer} className="paper-container" style={{right: showInspector ? 300 : 0}}></div>
                <div
                    ref={navigatorContainer}
                    className="navigator-container"
                ></div>
            </div>
            {
                isDialogOpen && 
                <QuestionsDialog questions={questions} handleCancel={handleDialogClose} handleSubmit={handleSubmit} handleChange={handleChage} />
            }
            {
                showInspector &&
                <Inspector selectedElement={selectedInspector} addSensorEquipemntToGraph = {handleAddSensorEquipment} />
            }
            {
                questionsLoading &&
                <FloatingLoader />
            }
        </div>
    );
}

export default withPermissionCheck(ScadaConfiguration, PERMISSION_TAGS.Formula_Configuration_WE);
