import { useEffect, useRef, useState } from "react";
import * as joint from '@joint/plus'
import { PERMISSION_TAGS } from "../../../../constants/permissions";
import withPermissionCheck from "../../../../hoc/withRoutePermission";
import * as appShapes from '../../shapes/app-shapes';
import { Pipe } from "../../shapes/equipment/Pipe";
import './style.scss';
import { useLocation } from "react-router-dom";
import { useFetchGraphDetails } from "../../../../hooks/useFetchGraphData";
import { ScadaConfigurationPathConstants } from "../../assetConfiguration/assetConstants/pathConstants";
import FloatingLoader from "../../../../globalComponents/loader/FloatingLoader";
import { Stack, Typography } from "@mui/material";
import { EventSourcePolyfill } from "event-source-polyfill";
import { getLastParams } from "../../visualizationHelper/helperFunctions";
import ScadaView from "./scadaView";

const ScadaLiveSimulation = () => {
  const location = useLocation();
  const lastParam = getLastParams({location});
  
  const graphDetailsPayload: any = {
    path: ScadaConfigurationPathConstants.GET_ASSET_GRAPHS,
    payload: {
      id: lastParam
    }
  };

  const {data: graphDetails, isLoading: graphDetailsLoading} = useFetchGraphDetails(graphDetailsPayload, !!lastParam);

  return (
    <div className="section-padding">
      {
        graphDetailsLoading && <FloatingLoader />
      }
      <Stack rowGap={6}>
        <Typography variant="h1">Scada View</Typography>
        {
          !graphDetailsLoading && graphDetails !== null &&
          <ScadaView 
            graphDetails={graphDetails} 
            assetId={lastParam}
            sseApiUrl={ScadaConfigurationPathConstants.REAL_TIME_SCADA + `?id=${lastParam}`} 
            // sseApiUrl={`sseRoutes/getLayoutDiagramEvents?assetId=618e18ce74f85a27f952dc2d`} 
          />
        }
      </Stack>
    </div>
  )
}

export default withPermissionCheck(ScadaLiveSimulation, PERMISSION_TAGS.Formula_Configuration_WE);