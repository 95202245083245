import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import './style.css';

interface ILoader {
  color?: string,
}
const FloatingLoader = ({ color='#36A2C7'}: ILoader) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" className="floating-loader"
      sx={{
      }}
    >
      <CircularProgress style={{ color }} />
    </Box>
  );
};

export default FloatingLoader;