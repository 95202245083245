import * as joint from '@joint/plus';

export class OH_TANK extends joint.dia.Element {
    // @ts-ignore
    defaults() {
        return {
            ...super.defaults,
            type: "OH_TANK",
            size: {
                width: 160,
                height: 200
            },
            attrs: {
                body: {
                    x: 55.3629, 
                    y: 14.62, 
                    width: 139.274, 
                    height: 125.274, 
                    stroke: "#818181", 
                    strokeWidth: 0.725806,
                    magnet: true
                }
            }
        };
    }

    preinitialize() {
        this.markup = joint.util.svg/* xml */ `
                <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
                    <path d="M59.8196 151.38L59.9586 147.021L68.6757 147.3L68.5366 151.658L59.8196 151.38ZM65.8497 235.882C65.773 238.289 63.7593 240.178 61.3522 240.101C58.945 240.025 57.0559 238.011 57.1327 235.604L65.8497 235.882ZM68.5366 151.658L65.8497 235.882L57.1327 235.604L59.8196 151.38L68.5366 151.658Z" fill="#7A7A7A"/>
                    <path d="M191.947 151.38L191.808 147.021L183.091 147.3L183.23 151.658L191.947 151.38ZM185.917 235.882C185.994 238.289 188.007 240.178 190.414 240.101C192.821 240.025 194.711 238.011 194.634 235.604L185.917 235.882ZM183.23 151.658L185.917 235.882L194.634 235.604L191.947 151.38L183.23 151.658Z" fill="#7A7A7A"/>
                    <path d="M158.949 151.483L158.913 147.123L150.192 147.194L150.228 151.555L158.949 151.483ZM150.915 235.778C150.934 238.187 152.903 240.123 155.311 240.104C157.719 240.084 159.656 238.116 159.636 235.707L150.915 235.778ZM150.228 151.555L150.915 235.778L159.636 235.707L158.949 151.483L150.228 151.555Z" fill="#7A7A7A"/>
                    <path d="M109.915 151.483L109.95 147.123L118.671 147.194L118.636 151.555L109.915 151.483ZM117.949 235.778C117.929 238.187 115.961 240.123 113.553 240.104C111.145 240.084 109.208 238.116 109.228 235.707L117.949 235.778ZM118.636 151.555L117.949 235.778L109.228 235.707L109.915 151.483L118.636 151.555Z" fill="#7A7A7A"/>
                    <path d="M69.8281 168.974L186.044 168.974" stroke="#7A7A7A" stroke-width="8.72151" stroke-linecap="square"/>
                    <rect @selector="body"fill=" url(#paint0_linear_3092_5816)" />
                    <path d="M76.6842 128.137H173.316" stroke="url(#paint1_linear_3092_5816)" stroke-width="1.30073" stroke-linecap="round"/>
                    <path d="M76.6841 24.4553H173.316" stroke="url(#paint2_linear_3092_5816)" stroke-width="1.30073" stroke-linecap="round"/>
                    <path d="M76.6841 32.2596H173.316" stroke="url(#paint3_linear_3092_5816)" stroke-width="1.30073" stroke-linecap="round"/>
                    <rect x="49.3629" y="139.774" width="151.274" height="10.1613" rx="1.08871" fill="url(#paint4_linear_3092_5816)" stroke="#818181" stroke-width="0.725806"/>
                    <defs>
                    <linearGradient id="paint0_linear_3092_5816" x1="195" y1="71.3554" x2="55" y2="71.3554" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8A8A8A"/>
                    <stop offset="0.1" stop-color="#B1B1B1"/>
                    <stop offset="0.2" stop-color="#E3E3E3"/>
                    <stop offset="0.4" stop-color="white"/>
                    <stop offset="0.6" stop-color="white"/>
                    <stop offset="0.8" stop-color="#E3E3E3"/>
                    <stop offset="0.9" stop-color="#B1B1B1"/>
                    <stop offset="1" stop-color="#848685"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_3092_5816" x1="76.6842" y1="128.637" x2="173.316" y2="128.637" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00399963" stop-color="#D1D1D1"/>
                    <stop offset="0.5" stop-color="#E6E6E6"/>
                    <stop offset="1" stop-color="#D1D1D1"/>
                    </linearGradient>
                    <linearGradient id="paint2_linear_3092_5816" x1="76.6841" y1="24.9553" x2="173.316" y2="24.9553" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00399963" stop-color="#D1D1D1"/>
                    <stop offset="0.5" stop-color="#E6E6E6"/>
                    <stop offset="1" stop-color="#D1D1D1"/>
                    </linearGradient>
                    <linearGradient id="paint3_linear_3092_5816" x1="76.6841" y1="32.7596" x2="173.316" y2="32.7596" gradientUnits="userSpaceOnUse">
                    <stop offset="0.00399963" stop-color="#D1D1D1"/>
                    <stop offset="0.5" stop-color="#E6E6E6"/>
                    <stop offset="1" stop-color="#D1D1D1"/>
                    </linearGradient>
                    <linearGradient id="paint4_linear_3092_5816" x1="201" y1="144.344" x2="49" y2="144.344" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#8A8A8A"/>
                    <stop offset="0.1" stop-color="#B1B1B1"/>
                    <stop offset="0.2" stop-color="#E3E3E3"/>
                    <stop offset="0.4" stop-color="white"/>
                    <stop offset="0.6" stop-color="white"/>
                    <stop offset="0.8" stop-color="#E3E3E3"/>
                    <stop offset="0.9" stop-color="#B1B1B1"/>
                    <stop offset="1" stop-color="#848685"/>
                    </linearGradient>
                    </defs>
                </svg>
            `;
    }
}