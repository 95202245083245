import * as joint from '@joint/plus';
const LEVEL_FLAG = 'level-flag';

export class OS_TANK_DF extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "OS_TANK_DF",
      equipmentType: 'OS_TANK',
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        root: {
            magnet: true
        },
        liquid: {
          x: 13.1432,
          y: 68.6903,
          width: 232.857,
          height: 150.387,
          fill: "#DBA670"
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect @selector="liquid" />
        <path d="M83.9286 200.806L83.9286 211.769L96.5922 211.769L96.5922 200.806L83.9286 200.806Z" fill="url(#paint0_linear_3059_1139)" stroke="#7A7A7A" stroke-width="1.49268"/>
        <rect x="0.746338" y="0.746338" width="4.90244" height="18.239" transform="matrix(1.31134e-07 1 1 -1.31134e-07 80.5698 195.314)" fill="white" stroke="#818181" stroke-width="1.49268"/>
        <path d="M44.7924 200.806L44.7924 211.769L57.456 211.769L57.456 200.806L44.7924 200.806Z" fill="url(#paint1_linear_3059_1139)" stroke="#7A7A7A" stroke-width="1.49268"/>
        <rect x="0.746338" y="0.746338" width="4.90244" height="18.239" transform="matrix(1.31134e-07 1 1 -1.31134e-07 41.4336 195.314)" fill="white" stroke="#818181" stroke-width="1.49268"/>
        <path d="M162.199 200.806L162.199 211.769L174.863 211.769L174.863 200.806L162.199 200.806Z" fill="url(#paint2_linear_3059_1139)" stroke="#7A7A7A" stroke-width="1.49268"/>
        <rect x="0.746338" y="0.746338" width="4.90244" height="18.239" transform="matrix(1.31134e-07 1 1 -1.31134e-07 158.842 195.313)" fill="white" stroke="#818181" stroke-width="1.49268"/>
        <path d="M201.336 200.806L201.336 211.769L214 211.769L214 200.806L201.336 200.806Z" fill="url(#paint3_linear_3059_1139)" stroke="#7A7A7A" stroke-width="1.49268"/>
        <rect x="0.746338" y="0.746338" width="4.90244" height="18.239" transform="matrix(1.31134e-07 1 1 -1.31134e-07 197.978 195.314)" fill="white" stroke="#818181" stroke-width="1.49268"/>
        <path d="M123.065 200.806L123.065 211.769L135.729 211.769L135.729 200.806L123.065 200.806Z" fill="url(#paint4_linear_3059_1139)" stroke="#7A7A7A" stroke-width="1.49268"/>
        <rect x="0.746338" y="0.746338" width="4.90244" height="18.239" transform="matrix(1.31134e-07 1 1 -1.31134e-07 119.708 195.313)" fill="white" stroke="#818181" stroke-width="1.49268"/>
        <path d="M33.7951 210.123C33.7951 208.325 35.2522 206.868 37.0496 206.868H222.094C223.892 206.868 225.349 208.325 225.349 210.123C225.349 211.92 223.892 213.377 222.094 213.377H37.0496C35.2522 213.377 33.7951 211.92 33.7951 210.123Z" fill="white" stroke="#7A7A7A" stroke-width="1.4" stroke-linecap="round"/>
        <path d="M13.1432 41.4493V216.839C13.1432 218.487 14.4798 219.824 16.1285 219.824H243.015C244.664 219.824 246.001 218.487 246.001 216.839V41.4493" stroke="#7A7A7A" stroke-width="2.98535" stroke-linecap="round"/>
        <defs>
        <linearGradient id="paint0_linear_3059_1139" x1="83.9286" y1="206.287" x2="96.5922" y2="206.287" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.0516055" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.952575" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint1_linear_3059_1139" x1="44.7924" y1="206.287" x2="57.456" y2="206.287" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.0516055" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.952575" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint2_linear_3059_1139" x1="162.199" y1="206.287" x2="174.863" y2="206.287" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.0516055" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.952575" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint3_linear_3059_1139" x1="201.336" y1="206.287" x2="214" y2="206.287" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.0516055" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.952575" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint4_linear_3059_1139" x1="123.065" y1="206.287" x2="135.729" y2="206.287" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.0516055" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.952575" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        </defs>
        </svg>
       `;
  }
}



export class OS_TANK_DFView extends joint.dia.ElementView {
    // @ts-ignore
    presentationAttributes = joint.dia.ElementView.addPresentationAttributes({
        level: [LEVEL_FLAG],
        liquidColor: [LEVEL_FLAG],
    });

    // @ts-ignore
    // initFlag = [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    initFlag() {
        return [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    }
    confirmUpdate(...args: any[]): any {
        // @ts-ignore
        let flags = joint.dia.ElementView.prototype.confirmUpdate.call(this, ...args);
        if (this.hasFlag(flags, LEVEL_FLAG)) {
            this.updateLevel();
            flags = this.removeFlag(flags, LEVEL_FLAG);
        }
        return flags;
    }

    getElement(element: any) {
        return this.findNode(element)
    }

    updateLevel() {
        const model = this.model;
        const level = Math.max(0, Math.min(100, model.get('level') || 0));
        const liquidColor = model.get('liquidColor') || '#DBA670';

        const liquidEl = this.getElement('liquid');
        const tankEl = this.getElement('tank');

        // @ts-ignore
        const tankBBox = tankEl.getBBox();
        const tankHeight = tankBBox.height;
        const tankBottom = tankBBox.y + tankHeight;
        const maxLiquidHeight = tankHeight * 0.85;
        const newHeight = (maxLiquidHeight * level) / 100;

        // Calculate the y position (from bottom up)
        const newY = tankBottom - newHeight;

        // Animate the liquid element
        liquidEl?.animate(
            {
                height: [`${newHeight}px`],
                y: newY,
                fill: liquidColor,
            },
            {
                fill: 'forwards',
                duration: 800,
                easing: 'ease-in-out',
            }
        );
    }
}