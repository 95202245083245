const PRIMARY_SHAPE_COLOR = '#353535';
import { ModifiedStencilShapes } from '../../shapes/stencilShapes';

let svgUrl = 'https://nalinl-sandbox.s3.ap-south-1.amazonaws.com';
const AllEquipment = [
    {
        equipmentFileName: '/Equipments/CP_TANK.svg',
        type: 'CP_TANK'
    },
    {
        equipmentFileName: '/Equipments/OP_TANK.svg',
        type: 'OP_TANK'
    },
    {
        equipmentFileName: '/Equipments/TANK_2.svg',
        type: 'TANK_2'
    },
    {
        equipmentFileName: '/Equipments/OS_TANK.svg',
        type: 'OS_TANK'
    },
    {
        equipmentFileName: '/Equipments/SMC_PUMP.svg',
        type: 'SMC_PUMP'
    },
    {
        equipmentFileName: '/Equipments/DS_PUMP.svg',
        type: 'DS_PUMP'
    },
    {
        equipmentFileName: '/Equipments/FS_PUMP.svg',
        type: 'FS_PUMP'
    },
    {
        equipmentFileName: '/Equipments/SC_PRESS.svg',
        type: 'SC_PRESS'
    },
    {
        equipmentFileName: '/Equipments/SH_TANK.svg',
        type: 'SH_TANK'
    },
    {
        equipmentFileName: '/Equipments/SOFTNER.svg',
        type: 'SOFTNER'
    },
    {
        equipmentFileName: '/Equipments/ACF_MGF.svg',
        type: 'ACF_MGF'
    },
    {
        equipmentFileName: '/Equipments/F_PRESS.svg',
        type: 'F_PRESS'
    },
    {
        equipmentFileName: '/Equipments/OZONATOR.svg',
        type: 'OZONATOR'
    },
    {
        equipmentFileName: '/Equipments/MOTOR.svg',
        type: 'MOTOR'
    },
    {
        equipmentFileName: '/Equipments/MBR.svg',
        type: 'MBR'
    },
    {
        equipmentFileName: '/Equipments/MBBR.svg',
        type: 'MBBR'
    },
    {
        equipmentFileName: '/Equipments/DE_TANK.svg',
        type: 'DE_TANK'
    },
    {
        equipmentFileName: '/Equipments/DA_TANK.svg',
        type: 'DA_TANK'
    },
    {
        equipmentFileName: '/Equipments/BLOWER.svg',
        type: 'BLW'
    },
    {
        equipmentFileName: '/Equipments/VALVE_2.svg',
        type: 'VALVE_2'
    },
    {
        equipmentFileName: '/Equipments/T_SETTLER.svg',
        type: 'T_SETTLER'
    },
    {
        equipmentFileName: '/Equipments/UF_MEM.svg',
        type: 'UF_MEM'
    },
    {
        equipmentFileName: '/Equipments/DOS_TANK.svg',
        type: 'DOS_TANK'
    },
    {
        equipmentFileName: '/Equipments/CL_TANK.svg',
        type: 'CL_TANK'
    },
    {
        equipmentFileName: '/Equipments/TANK.svg',
        type: 'TANK'
    },

]

export const stencilShapes = () => {
    const newData = AllEquipment.map((equipment, i) => {
        let newShape = new ModifiedStencilShapes({
            size: { width: 120, height: 120 },
            type: equipment.type,
            name: equipment.type,
            // border: {
            //     stroke: '#000',
            //     strokeWidth: 2,
            // },
            attrs: {
                image: { 'xlink:href': svgUrl + equipment.equipmentFileName}, 
                label: {
                    y: 'calc(h - 12)',
                    fill: PRIMARY_SHAPE_COLOR,
                    textVerticalAnchor: 'top',
                    fontFamily: 'Open Sans',
                    fontWeight: 'Normal',
                    fontSize: 11,
                    stroke: PRIMARY_SHAPE_COLOR,
                    strokeWidth: 0,
                    text: equipment.type,
                },
            },
            customAttr: true,
            targetAttributes: {
                size: { width: 250, height: 250 }
            },
        })
        return newShape;
    })
     newData.push(
        {
            size: { width: 120, height: 60 },
            type: 'ZONE',
            name: 'ZONE',
            attrs: {
                label: {
                    y: 'calc(h - 12)',
                    fill: PRIMARY_SHAPE_COLOR,
                    textVerticalAnchor: 'top',
                    fontFamily: 'Open Sans',
                    fontWeight: 'Normal',
                    fontSize: 11,
                    stroke: PRIMARY_SHAPE_COLOR,
                    strokeWidth: 0,
                    text: 'ZONE',
                },
            },
            targetAttributes: {
                size: { width: 120, height: 60 }
            },
        }
     )
    return newData
};


export const stencilSensors = () => {
    return [
        // {
        //     type: 'LEVEL_SENSOR',
        //     size: { width: 80, height: 250 },
        //     name: 'LEVEL_SENSOR',
        //     attrs: {
        //         label: {
        //             y: 'calc(h + 5)',
        //             fill: PRIMARY_SHAPE_COLOR,
        //             textVerticalAnchor: 'top',
        //             fontFamily: 'Open Sans',
        //             fontWeight: 'Normal',
        //             fontSize: 11,
        //             stroke: PRIMARY_SHAPE_COLOR,
        //             strokeWidth: 0, 
        //             text: 'LEVEL_SENSOR'
        //         }
        //     },
        //     targetAttributes: {
        //         size: { width: 100, height: 250 }
        //     },
        // },
        new ModifiedStencilShapes({
            size: { width: 120, height: 120 },
            type: 'LEVEL_SENSOR',
            name: 'LEVEL_SENSOR',
            attrs: {
                image: { 'xlink:href': svgUrl + '/Sensors/Level.svg'}, 
                label: {
                    y: 'calc(h - 12)',
                    fill: PRIMARY_SHAPE_COLOR,
                    textVerticalAnchor: 'top',
                    fontFamily: 'Open Sans',
                    fontWeight: 'Normal',
                    fontSize: 11,
                    stroke: PRIMARY_SHAPE_COLOR,
                    strokeWidth: 0,
                    text: 'LEVEL_SENSOR',
                },
            },
            customAttr: true,
            targetAttributes: {
                size: { width: 250, height: 250 }
            },
        })
        
    ]
}