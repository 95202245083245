import * as joint from '@joint/plus';

export class ModifiedStencilShapes extends joint.shapes.standard.Image {
  constructor(a) {
      super(...arguments);
      
      this.markup = [{
              tagName: 'rect',
              selector: 'background',
          },
          {
            tagName: 'image',
            selector: 'image',
          },
          {
            tagName: 'g',
            selector: 'labelGroup',
            children: [
                {
                    tagName: 'path',
                    selector: 'labelRect',
                },
                {
                    tagName: 'text',
                    selector: 'label',
                }
            ]
          },
          // {
          //     tagName: 'path',
          //     selector: 'labelRect',
          // },
          // {
          //   tagName: 'text',
          //   selector: 'label',
          // }
        ];
  }
  defaults() {
    return {
      ...super.defaults,
      size: { width: 100, height: 100 },
      attrs: {
        background: {
          x: 1,
          y: 1,
          width: 'calc(w + 15)',
          height: 'calc(h + 15)',
          fill: 'transparent',
          stroke: '#E1E3E5',
          'stroke-width': 1,
          rx: 4,
          ry: 4
        },
        // labelRect: {
        //   x: 0,
        //   y: 'calc(h-50)',
        //   width: 'calc(0.7*w)',
        //   height: 'calc(0.3*h)',
        //   fill: 'transparent',
        //   stroke: '#000',
        //   'stroke-width': 1,
        //   rx: '10',             // Round the top-right and bottom-right corners (right side)
        //   ry: '10'
        // },
        labelGroup: {
          x: 0,
          y: 'calc(h-50)',
          width: 'calc(0.7*w)',
          height: 'calc(0.3*h)',
          fill: 'transparent',
          stroke: '#000',
          'stroke-width': 1
        },
        labelRect: {
          d: 'M0 100 H82.8914 C90.0998 100 95.9433 106.73414 95.9433 113.9425 V113.9425 C95.9433 121.1508 90.0997 126.9943 82.8914 126.9943 H0 V100Z',
          fill: '#F4F5F5',    // Transparent background for the label
          stroke: '#E1E3E5',         // Border color for the label rect
          'stroke-width': 1,      // Border width for the label rect
        },
        image: {
          'xlink:href': '', // Image URL will be set dynamically in the shape instance
          width: 120,
          height: 120,
          x: 10,
          y: 10
        },
        label: {
          text: '',
          'text-anchor': 'start',
          x: 10,
          y: 10,
          fill: '#000',
          'font-size': 10
        }
      },
    };
  }
}