// import { createContext } from "react";

// const SidebarContext = createContext({
//   open: false,
//   setOpen: () => {},
// });

// export default SidebarContext;

import { createContext, useContext, useState } from "react";

interface ISidebarContext {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const SidebarContext = createContext<ISidebarContext>({
  open: false,
  setOpen: () => {},
});

export const SidebarProvider = ({children}: any) => {
  const [open, setOpen] = useState(false);

  const handleSetOpen = (isOpen: boolean): void => {
    setOpen(isOpen);
  };

  const contextValue: ISidebarContext = {
    open,
    setOpen: handleSetOpen,
  };

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = (): ISidebarContext => useContext(SidebarContext);

export default SidebarContext;
