import * as joint from '@joint/plus';

export class OZONATOR extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'OZONATOR',
      size: {
        width: 250,
        height: 250,
      },
      attrs: {
       
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none" transform-origin="center">
              <g opacity="0.6">
                <path d="M66.0398 171.54L66.0398 199.338L59.8849 199.338C59.446 199.338 59.0903 198.983 59.0903 198.544L59.0903 172.335C59.0903 171.896 59.446 171.54 59.8849 171.54L66.0398 171.54Z" fill="url(#paint0_linear_2920_554)" stroke="#818181" stroke-width="0.79456"/>
                <rect x="59.0903" y="198.543" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 198.543)" fill="#818181"/>
                <rect x="59.0903" y="197.593" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 197.593)" fill="#818181"/>
                <rect x="59.0903" y="196.642" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 196.642)" fill="#818181"/>
                <rect x="59.0903" y="195.692" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 195.692)" fill="#818181" fill-opacity="0.8"/>
                <rect x="59.0903" y="194.741" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 194.741)" fill="#818181" fill-opacity="0.8"/>
                <rect x="59.0903" y="193.79" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 193.79)" fill="#818181" fill-opacity="0.8"/>
                <rect x="59.0903" y="192.84" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 192.84)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="191.889" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 191.889)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="190.938" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 190.938)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="189.988" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 189.988)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="189.037" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 189.037)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="188.086" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 188.086)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="187.136" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 187.136)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="186.185" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 186.185)" fill="#818181" fill-opacity="0.6"/>
                <rect x="59.0903" y="185.235" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 185.235)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="184.284" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 184.284)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="183.333" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 183.333)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="182.383" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 182.383)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="181.432" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 181.432)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="180.481" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 180.481)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="179.531" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 179.531)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="178.58" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 178.58)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="177.63" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 177.63)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="176.679" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 176.679)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="175.728" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 175.728)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="174.778" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 174.778)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="173.827" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 173.827)" fill="#818181" fill-opacity="0.4"/>
                <rect x="59.0903" y="172.876" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 172.876)" fill="#818181"/>
                <rect x="59.0903" y="171.926" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 171.926)" fill="#818181"/>
              </g>
              <path d="M62.0181 183.05C62.0181 182.611 62.3738 182.255 62.8126 182.255H65.1963V188.612H62.8126C62.3738 188.612 62.0181 188.256 62.0181 187.817V183.05Z" fill="#818181"/>
              <path d="M177.671 219.724L182.052 235" stroke="#7A7A7A" stroke-width="10.7266" stroke-linecap="round"/>
              <path d="M82.7314 219.724L78.3512 235" stroke="#7A7A7A" stroke-width="10.7266" stroke-linecap="round"/>
              <path d="M71.7256 23.1682H190.91V221.808H71.7256V23.1682Z" fill="url(#paint1_linear_2920_554)" stroke="#818181" stroke-width="0.79456"/>
              <rect x="84.9478" y="39.167" width="92.8173" height="38.9234" rx="0.79456" fill="#D9D9D9" stroke="#818181" stroke-width="0.79456"/>
              <path d="M66.0398 95.3218L66.0398 149.654L59.8849 149.654C59.446 149.654 59.0903 149.298 59.0903 148.86L59.0903 96.1163C59.0903 95.6775 59.446 95.3218 59.8849 95.3218L66.0398 95.3218Z" fill="url(#paint2_linear_2920_554)" stroke="#818181" stroke-width="0.79456"/>
              <path d="M94.3708 47.2635C94.3708 47.0441 94.5487 46.8662 94.7681 46.8662H111.851C112.071 46.8662 112.248 47.0441 112.248 47.2635V57.1955C112.248 57.4149 112.071 57.5928 111.851 57.5928H94.7681C94.5487 57.5928 94.3708 57.4149 94.3708 57.1955V47.2635Z" fill="#D9D9D9" stroke="#818181" stroke-width="0.79456"/>
              <path d="M94.3708 61.211C94.3708 60.9916 94.5487 60.8137 94.7681 60.8137H111.851C112.071 60.8137 112.248 60.9916 112.248 61.211V71.143C112.248 71.3624 112.071 71.5403 111.851 71.5403H94.7681C94.5487 71.5403 94.3708 71.3624 94.3708 71.143V61.211Z" fill="#D9D9D9" stroke="#818181" stroke-width="0.79456"/>
              <path d="M141.448 61.211C141.448 60.9916 141.626 60.8137 141.846 60.8137H151.778C151.997 60.8137 152.175 60.9916 152.175 61.211V71.143C152.175 71.3624 151.997 71.5403 151.778 71.5403H141.846C141.626 71.5403 141.448 71.3624 141.448 71.143V61.211Z" fill="#818181" stroke="#818181" stroke-width="0.79456"/>
              <path d="M141.647 47.2635C141.647 47.0441 141.825 46.8662 142.044 46.8662H151.976C152.196 46.8662 152.374 47.0441 152.374 47.2635V57.1955C152.374 57.4149 152.196 57.5928 151.976 57.5928H142.044C141.825 57.5928 141.647 57.4149 141.647 57.1955V47.2635Z" fill="#818181" stroke="#818181" stroke-width="0.79456"/>
              <path d="M141.647 47.346C141.647 47.1266 141.825 46.9487 142.044 46.9487H151.976C152.196 46.9487 152.374 47.1266 152.374 47.346V51.8701C152.374 52.0895 152.196 52.2674 151.976 52.2674H142.044C141.825 52.2674 141.647 52.0895 141.647 51.8701V47.346Z" fill="white"/>
              <rect x="65.2451" y="23.1682" width="6.35648" height="198.64" fill="url(#paint3_linear_2920_554)" stroke="#818181" stroke-width="0.79456"/>
              <circle cx="126.948" cy="51.0802" r="2.08572" fill="#B82120" stroke="#818181" stroke-width="0.59592"/>
              <circle cx="126.948" cy="66.1769" r="2.08572" fill="#028057" stroke="#818181" stroke-width="0.59592"/>
              <path d="M112.248 15.7946C112.248 15.3557 112.604 15 113.043 15H149.593C150.031 15 150.387 15.3557 150.387 15.7946V23.1442H143.236V20.6071C143.236 20.1682 142.88 19.8125 142.442 19.8125H120.194C119.755 19.8125 119.399 20.1682 119.399 20.6071V23.1442H112.248V15.7946Z" fill="#818181"/>
              <rect x="59.0903" y="149.678" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 149.678)" fill="#818181"/>
              <rect x="59.0903" y="148.392" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 148.392)" fill="#818181"/>
              <rect x="59.0903" y="147.105" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 147.105)" fill="#818181"/>
              <rect x="59.0903" y="145.819" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 145.819)" fill="#818181"/>
              <rect x="59.0903" y="144.532" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 144.532)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="143.246" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 143.246)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="141.959" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 141.959)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="140.673" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 140.673)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="139.386" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 139.386)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="138.1" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 138.1)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="136.814" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 136.814)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="135.527" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 135.527)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="134.241" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 134.241)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="132.954" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 132.954)" fill="#818181" fill-opacity="0.8"/>
              <rect x="59.0903" y="131.668" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 131.668)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="130.382" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 130.382)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="129.095" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 129.095)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="127.809" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 127.809)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="126.522" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 126.522)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="125.236" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 125.236)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="123.949" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 123.949)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="122.663" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 122.663)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="121.376" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 121.376)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="120.09" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 120.09)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="118.804" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 118.804)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="117.517" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 117.517)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="116.231" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 116.231)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="114.944" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 114.944)" fill="#818181" fill-opacity="0.6"/>
              <rect x="59.0903" y="113.658" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 113.658)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="112.372" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 112.372)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="111.085" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 111.085)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="109.799" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 109.799)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="108.512" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 108.512)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="107.226" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 107.226)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="105.939" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 105.939)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="104.653" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 104.653)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="103.366" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 103.366)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="102.08" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 102.08)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="100.793" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 100.793)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="99.5071" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 99.5071)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="98.2207" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 98.2207)" fill="#818181" fill-opacity="0.4"/>
              <rect x="59.0903" y="96.9343" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 96.9343)" fill="#818181"/>
              <rect x="59.0903" y="95.6479" width="0.39728" height="6.15784" transform="rotate(-90 59.0903 95.6479)" fill="#818181"/>
              <defs>
              <linearGradient id="paint0_linear_2920_554" x1="58.964" y1="199.338" x2="58.964" y2="171.54" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_554" x1="190.91" y1="225.417" x2="71.7256" y2="225.417" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint2_linear_2920_554" x1="58.964" y1="149.654" x2="58.964" y2="95.3218" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint3_linear_2920_554" x1="71.6016" y1="225.417" x2="65.2451" y2="225.417" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
  }
}
