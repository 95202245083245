import './style.scss';
import FloatingLoader from "../../../../globalComponents/loader/FloatingLoader";
import useInspectorConfig from "./hook/useInspectorConfig";
import RenderSensorList from "./RenderSensorList";
import RenderInspectorQuestions from "./RenderInspectorQuestions";
import { useMemo } from 'react';

const Tablist = ['Details', 'Sensors'];

const Inspector = ({selectedElement, addSensorEquipemntToGraph}: any) => {
  const {selectedTab, questionsData, sensorList, isLoading, handleTabClick, handleSaveAnswers, handleSaveSensors} = useInspectorConfig(selectedElement);
  const selectedTabName = useMemo(() => {
    if(questionsData?.length === 0 && Tablist[selectedTab] === 'Details') return 1;
    return selectedTab
  }, [selectedTab]);  

  return (
    <div className="ins-container">
      <div className="ins-content">
        <div className="tab-container">
          {
            Tablist.map((tab, index) => {
              if(questionsData?.length === 0 && tab === 'Details') return null
              return <div key={tab + index} className={"tab-item " + (selectedTabName === index ? 'selected' : '')} onClick={() => handleTabClick(index)}>{tab}</div>
            })
          }
        </div>
        <div className="ins-section">
          {
            !isLoading && selectedTab === 0 && questionsData?.length > 0 &&
            <RenderInspectorQuestions questionsData={questionsData} handleSaveAnswers={handleSaveAnswers} />
          }
          {
            selectedTab === 1 && 
            <div className="ins-sensors">
              <RenderSensorList sensorList={sensorList} handleSaveSensors={handleSaveSensors} addSensorEquipemntToGraph={addSensorEquipemntToGraph} />

            </div>
          }
        </div>
      </div>
      {
        isLoading && <FloatingLoader />
      }
    </div>
  )
}

export default Inspector;