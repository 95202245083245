import * as joint from '@joint/plus';

export class MBBR extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'MBBR',
      size: {
        width: 250,
        height: 250,
      },
      attrs: {
        
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <rect x="85.7228" y="93.5494" width="7.7317" height="3.86183" fill="white" stroke="#7A7A7A" stroke-width="1.41479"/>
              <rect x="15" y="97.5552" width="70.7396" height="68.5289" fill="#3DA3F5"/>
              <path d="M15.3442 93.5679H85.288" stroke="#7A7A7A" stroke-width="1.41479" stroke-linecap="square"/>
              <path opacity="0.5" d="M50.4487 97.547L50.4487 139.991" stroke="#7A7A7A" stroke-width="1.41479"/>
              <path d="M50.4487 92.949L50.4487 97.547" stroke="#7A7A7A" stroke-width="1.41479"/>
              <path d="M52.0787 142.013L50.272 139.891L57.6995 140.083L52.0787 142.013Z" fill="#D9D9D9" stroke="black" stroke-width="0.530547" stroke-linejoin="round"/>
              <path d="M48.3281 141.898L50.2612 139.891L50.8036 147.301L48.3281 141.898Z" fill="#D9D9D9" stroke="black" stroke-width="0.530547" stroke-linejoin="round"/>
              <path d="M52.4276 138.084L50.3164 139.903L50.4643 132.474L52.4276 138.084Z" fill="#D9D9D9" stroke="black" stroke-width="0.530547" stroke-linejoin="round"/>
              <path d="M48.8188 137.768L50.6255 139.891L43.198 139.698L48.8188 137.768Z" fill="#D9D9D9" stroke="black" stroke-width="0.530547" stroke-linejoin="round"/>
              <rect x="48.6533" y="84.8345" width="3.76778" height="1.2586" rx="0.0320662" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <rect x="48.6533" y="91.7395" width="3.76778" height="1.2586" rx="0.0320662" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <rect x="48.292" y="91.6193" width="4.48927" height="0.160331" rx="0.0320662" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <rect x="48.4712" y="86.0869" width="4.14752" height="5.5375" fill="url(#paint0_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M49.9598 83.5759C49.2647 83.5759 48.7012 84.1394 48.7012 84.8345H52.3808C52.3808 84.1394 51.8173 83.5759 51.1222 83.5759H49.9598Z" fill="url(#paint1_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M49.3184 85.3992C49.3184 85.3332 49.2738 85.2779 49.2138 85.2632C49.1966 85.259 49.1821 85.2738 49.1821 85.2915L49.1821 85.6362C49.1821 85.6539 49.1966 85.6686 49.2138 85.6644C49.2738 85.6497 49.3184 85.5944 49.3184 85.5284L49.3184 85.3992Z" fill="white" stroke="#181818" stroke-width="0.0480994"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M50.1763 85.3992C50.1763 85.3332 50.1317 85.2779 50.0718 85.2632C50.0546 85.259 50.04 85.2738 50.04 85.2915L50.04 85.6362C50.04 85.6539 50.0546 85.6686 50.0718 85.6644C50.1317 85.6497 50.1763 85.5944 50.1763 85.5284L50.1763 85.3992Z" fill="white" stroke="#181818" stroke-width="0.0480994"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M51.0342 85.3992C51.0342 85.3332 50.9896 85.2779 50.9297 85.2632C50.9125 85.259 50.8979 85.2738 50.8979 85.2915L50.8979 85.6362C50.8979 85.6539 50.9125 85.6686 50.9297 85.6644C50.9896 85.6497 51.0342 85.5944 51.0342 85.5284L51.0342 85.3992Z" fill="white" stroke="#181818" stroke-width="0.0480994"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M51.8921 85.3992C51.8921 85.3332 51.8475 85.2779 51.7876 85.2632C51.7704 85.259 51.7558 85.2738 51.7558 85.2915L51.7558 85.6362C51.7558 85.6539 51.7704 85.6686 51.7876 85.6644C51.8475 85.6497 51.8921 85.5944 51.8921 85.5284L51.8921 85.3992Z" fill="white" stroke="#181818" stroke-width="0.0480994"/>
              <rect x="48.5381" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A"/>
              <rect x="48.6724" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="48.8071" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="48.9414" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="49.0762" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="49.2104" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="49.3452" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="49.4795" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="49.6143" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="49.749" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="49.8833" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="50.0181" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="50.1523" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="50.2871" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="50.4214" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="50.5557" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="50.6904" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="50.8247" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="50.9595" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="51.0938" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="51.2285" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="51.3628" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="51.4976" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="51.6318" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="51.7661" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="51.9009" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="52.0352" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="52.1699" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="52.3042" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="52.4395" y="86.0869" width="0.044838" height="5.5375" rx="0.0165556" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="51.5791" y="86.5244" width="0.521076" height="4.70037" rx="0.0320662" fill="#181818"/>
              <rect x="50.4248" y="86.51" width="0.23248" height="4.70037" rx="0.0320662" fill="#181818"/>
              <rect x="50.1201" y="87.0195" width="0.841739" height="2.75216" rx="0.0320662" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <rect x="48.9819" y="86.5244" width="0.521076" height="4.70037" rx="0.0320662" fill="#181818"/>
              <path d="M47.6191 86.9268C47.6191 86.8205 47.7053 86.7344 47.8115 86.7344H48.4288C48.4465 86.7344 48.4609 86.7487 48.4609 86.7664V90.2296C48.4609 90.2473 48.4465 90.2617 48.4288 90.2617H47.8115C47.7053 90.2617 47.6191 90.1755 47.6191 90.0693V86.9268Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <circle cx="48.0443" cy="87.4719" r="0.320662" fill="#181818"/>
              <path d="M52.6133 86.7664C52.6133 86.7487 52.6276 86.7344 52.6453 86.7344H53.2626C53.3689 86.7344 53.455 86.8205 53.455 86.9268V90.0693C53.455 90.1755 53.3689 90.2617 53.2626 90.2617H52.6453C52.6276 90.2617 52.6133 90.2473 52.6133 90.2296V86.7664Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <circle cx="53.0379" cy="87.4719" r="0.320662" fill="#181818"/>
              <path d="M49.2461 91.7916L49.2461 92.9941" stroke="#7A7A7A" stroke-width="0.0641325" stroke-linecap="square"/>
              <path d="M49.5029 91.7916L49.5029 92.9941" stroke="#7A7A7A" stroke-width="0.0641325" stroke-linecap="square"/>
              <path d="M50.9614 91.7916L50.9614 92.9941" stroke="#7A7A7A" stroke-width="0.0641325" stroke-linecap="square"/>
              <rect x="49.7754" y="92.02" width="1.52315" height="0.697441" rx="0.0320662" fill="white" stroke="#7A7A7A" stroke-width="0.0574664"/>
              <rect x="48.4609" y="92.0442" width="0.304629" height="0.320662" rx="0.0320662" fill="#181818"/>
              <rect x="52.3086" y="92.0442" width="0.304629" height="0.320662" rx="0.0320662" fill="#181818"/>
              <rect x="93.521" y="97.5552" width="141.479" height="68.5289" fill="#3DA3F5"/>
              <path d="M120.07 156.326L120.07 161.521L126.072 161.521L126.072 156.326L120.07 156.326Z" fill="url(#paint2_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 118.479 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M105.06 156.326L105.06 161.521L111.061 161.521L111.061 156.326L105.06 156.326Z" fill="url(#paint3_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 103.468 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M150.09 156.326L150.09 161.521L156.092 161.521L156.092 156.326L150.09 156.326Z" fill="url(#paint4_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 148.499 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M135.081 156.326L135.081 161.521L141.082 161.521L141.082 156.326L135.081 156.326Z" fill="url(#paint5_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 133.489 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M99.1799 161.472C99.1799 160.886 99.6549 160.411 100.241 160.411H161.077C161.663 160.411 162.138 160.886 162.138 161.472C162.138 162.058 161.663 162.533 161.077 162.533H100.241C99.6549 162.533 99.1799 162.058 99.1799 161.472Z" fill="white" stroke="#7A7A7A" stroke-width="0.707395" stroke-linecap="round"/>
              <path d="M187.273 156.326L187.273 161.521L193.274 161.521L193.274 156.326L187.273 156.326Z" fill="url(#paint6_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 185.682 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M172.263 156.326L172.263 161.521L178.265 161.521L178.265 156.326L172.263 156.326Z" fill="url(#paint7_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 170.671 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M217.293 156.326L217.293 161.521L223.295 161.521L223.295 156.326L217.293 156.326Z" fill="url(#paint8_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 215.702 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M202.283 156.326L202.283 161.521L208.285 161.521L208.285 156.326L202.283 156.326Z" fill="url(#paint9_linear_2920_1562)" stroke="#7A7A7A" stroke-width="0.707395"/>
              <rect x="0.353698" y="0.353698" width="2.32332" height="8.64365" transform="matrix(1.31134e-07 1 1 -1.31134e-07 200.691 153.723)" fill="white" stroke="#818181" stroke-width="0.707395"/>
              <path d="M166.383 161.472C166.383 160.886 166.858 160.411 167.444 160.411H228.28C228.866 160.411 229.341 160.886 229.341 161.472C229.341 162.058 228.866 162.533 228.28 162.533H167.444C166.858 162.533 166.383 162.058 166.383 161.472Z" fill="white" stroke="#7A7A7A" stroke-width="0.707395" stroke-linecap="round"/>
              <path d="M15 85.1417V165.064C15 165.815 15.6091 166.424 16.3604 166.424H84.3792C85.1305 166.424 85.7396 165.815 85.7396 165.064V85.1417" stroke="#7A7A7A" stroke-width="1.41479" stroke-linecap="round"/>
              <path d="M93.521 85.1417V165.064C93.521 165.815 94.1301 166.424 94.8814 166.424H233.64C234.391 166.424 235 165.815 235 165.064V85.1417" stroke="#7A7A7A" stroke-width="1.41479" stroke-linecap="round"/>
              <defs>
              <linearGradient id="paint0_linear_2920_1562" x1="52.6187" y1="88.8557" x2="48.4712" y2="88.8557" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_1562" x1="52.3808" y1="84.2052" x2="48.7012" y2="84.2052" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint2_linear_2920_1562" x1="120.07" y1="158.923" x2="126.072" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint3_linear_2920_1562" x1="105.06" y1="158.923" x2="111.061" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint4_linear_2920_1562" x1="150.09" y1="158.923" x2="156.092" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint5_linear_2920_1562" x1="135.081" y1="158.923" x2="141.082" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint6_linear_2920_1562" x1="187.273" y1="158.923" x2="193.274" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint7_linear_2920_1562" x1="172.263" y1="158.923" x2="178.265" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint8_linear_2920_1562" x1="217.293" y1="158.923" x2="223.295" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint9_linear_2920_1562" x1="202.283" y1="158.923" x2="208.285" y2="158.923" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
  }
}
