import { useState } from "react";
import ApiBasedAutoComplete from "../../../../../globalComponents/apiBasedAutoComplete";
import { ScadaConfigurationPathConstants } from "../../../assetConfiguration/assetConstants/pathConstants";
import { Button, Stack } from "@mui/material";
import { Primary } from "../../../../../core-components/theme/color-palette/colors";

const AddSensors = ({handleSaveSensors}: any) => {
  const [selectedOptions, setSelectedOptions] = useState(null);

  const handleSubmit = () => {
    handleSaveSensors(selectedOptions);
  }
  return (
    <Stack rowGap={6}>
    <ApiBasedAutoComplete
        onSelect={setSelectedOptions}
        apiPath={ScadaConfigurationPathConstants.FETCH_SENSORS}
        searchKey="query"
        keyToPick="sensorName"
        idKey="_id"
        sx={{flex: 1}}
        label="Sensors"
        size="small"
        selectedOptions={selectedOptions}
      />
      <Button
        variant="outlined"
        size="small"
        sx={{
          border: `1px solid ${Primary[500]}`,
          borderRadius: '4px',
          // padding: '10px 70px',
          backgroundColor: 'white',
          color: Primary[500],
          // flex: 1,
          boxShadow: 'none',
          ':hover': { background: 'white', boxShadow: 'none' },
        }}
        onClick={() => {
          handleSubmit();
        }}
      >
        Add
      </Button>
    </Stack>
  )
}

export default AddSensors