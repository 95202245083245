import * as joint from '@joint/plus';

export class FS_PUMP extends joint.dia.Element {
    defaults() {
        return {
            ...super.defaults,
            type: "FS_PUMP",
            size: {
                width: 250,
                height: 250
            },
            attrs: {
                inlet: {
                    x: 231.096, 
                    y: 135.665, 
                    width: 32.2232, 
                    height: 3.90395, 
                    rx: 0.390395, 
                    transform: "rotate(-90 231.096 135.665)",
                    magnet: true
                },
                outlet: {
                    x: 227.138,
                    y: 94.1859,
                    width: 20.2171, 
                    height: 3.90395, 
                    rx: 0.390395, 
                    transform: "rotate(180 227.138 94.1859)",
                    magnet: true
                }
            }

        };
    }

    preinitialize() {
        this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <path d="M79.8054 135.318H221.324V141.955H79.8054V135.318Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path d="M195.558 135.324H219.079L225.618 148.343H203.073L195.558 135.324Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path d="M160.617 135.324H164.619L171.158 148.343H160.324L160.617 135.324Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M222.885 139.222H208.05L204.155 145.957H226.976L222.885 139.222ZM226.984 146.054H204.146V148.397H226.984V146.054Z" fill="url(#paint0_linear_2920_1281)"/>
              <path d="M208.05 139.222V139.027H207.938L207.881 139.125L208.05 139.222ZM222.885 139.222L223.052 139.121L222.995 139.027H222.885V139.222ZM204.155 145.957L203.986 145.859L203.816 146.152H204.155V145.957ZM226.976 145.957V146.152H227.323L227.143 145.855L226.976 145.957ZM204.146 146.054V145.859H203.951V146.054H204.146ZM226.984 146.054H227.18V145.859H226.984V146.054ZM204.146 148.397H203.951V148.592H204.146V148.397ZM226.984 148.397V148.592H227.18V148.397H226.984ZM208.05 139.418H222.885V139.027H208.05V139.418ZM204.324 146.054L208.219 139.32L207.881 139.125L203.986 145.859L204.324 146.054ZM226.976 145.762H204.155V146.152H226.976V145.762ZM222.718 139.324L226.809 146.058L227.143 145.855L223.052 139.121L222.718 139.324ZM204.146 146.25H226.984V145.859H204.146V146.25ZM204.341 148.397V146.054H203.951V148.397H204.341ZM226.984 148.201H204.146V148.592H226.984V148.201ZM226.789 146.054V148.397H227.18V146.054H226.789Z" fill="#7A7A7A"/>
              <rect x="113.184" y="122.43" width="5.66072" height="6.83191" rx="0.195197" transform="rotate(-90 113.184 122.43)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.290234"/>
              <rect x="107.133" y="130.823" width="22.4477" height="10.3455" rx="0.161951" transform="rotate(-90 107.133 130.823)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.290234"/>
              <path d="M119.365 135.318H151.053V150.153H115.917L119.365 135.318Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.390395"/>
              <rect x="120.016" y="115.695" width="14.6398" height="7.80789" rx="0.390395" fill="url(#paint1_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M143.892 116.867C143.892 116.651 144.067 116.476 144.282 116.476H156.713C156.929 116.476 157.104 116.651 157.104 116.867V122.332C157.104 122.548 156.929 122.723 156.713 122.723H144.282C144.067 122.723 143.892 122.548 143.892 122.332V116.867Z" fill="url(#paint2_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="148.905" y="109.357" width="20.3005" height="17.5567" rx="0.390395" transform="rotate(90 148.905 109.357)" fill="url(#paint3_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="124.506" y="123.997" width="1.56158" height="0.390395" rx="0.195197" transform="rotate(-180 124.506 123.997)" fill="#818181" stroke="#818181" stroke-width="0.390395"/>
              <rect x="158.86" y="130.534" width="1.56158" height="0.390395" rx="0.195197" transform="rotate(-90 158.86 130.534)" fill="#818181" stroke="#818181" stroke-width="0.390395"/>
              <rect x="158.445" y="122.049" width="4.8887" height="1.22218" rx="0.195197" transform="rotate(-90 158.445 122.049)" fill="#818181" stroke="#818181" stroke-width="0.390395"/>
              <path d="M157.239 121.017L157.239 118.194L158.461 118.194L158.461 121.017L157.239 121.017Z" fill="#818181" stroke="#818181" stroke-width="0.390395"/>
              <rect width="1.56158" height="0.292796" rx="0.146398" transform="matrix(-1 6.55671e-08 1.16564e-07 1 155.542 115.994)" fill="#818181" stroke="#818181" stroke-width="0.390395"/>
              <path d="M198.486 123.216C198.27 123.216 198.095 123.041 198.095 122.826L198.095 115.213C198.095 114.997 198.27 114.823 198.486 114.823L206.218 114.823C206.256 114.823 206.293 114.828 206.325 114.847C206.526 114.968 207.269 115.645 207.269 119.019C207.269 122.394 206.526 123.071 206.325 123.192C206.293 123.211 206.256 123.216 206.218 123.216L198.486 123.216Z" fill="url(#paint4_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M159.251 128.487L159.251 109.357C159.251 109.142 159.426 108.967 159.641 108.967L161.983 108.967L161.983 128.487L159.251 128.487Z" fill="url(#paint5_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M192.63 123.216C192.414 123.216 192.239 123.041 192.239 122.826L192.239 115.213C192.239 114.997 192.414 114.823 192.63 114.823L198.784 114.823C198.829 114.823 198.874 114.831 198.909 114.86C199.093 115.014 199.657 115.751 199.657 119.019C199.657 122.288 199.093 123.025 198.909 123.179C198.874 123.208 198.829 123.216 198.784 123.216L192.63 123.216Z" fill="url(#paint6_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="190.678" y="122.826" width="7.2223" height="1.56158" transform="rotate(-90 190.678 122.826)" fill="#D9D9D9" stroke="#818181" stroke-width="0.390395"/>
              <rect x="225.294" y="131.506" width="23.9052" height="5.9844" transform="rotate(-90 225.294 131.506)" fill="#D9D9D9" stroke="#818181" stroke-width="0.390395"/>
              <path d="M161.984 128.487L161.984 108.967L163.74 108.967L163.74 128.487L161.984 128.487Z" fill="url(#paint7_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M186.774 105.258L186.774 132.586L185.407 132.586C185.192 132.586 185.017 132.411 185.017 132.195L185.017 105.648C185.017 105.433 185.192 105.258 185.407 105.258L186.774 105.258Z" fill="url(#paint8_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M190.287 105.258C190.503 105.258 190.678 105.433 190.678 105.648L190.678 132.195C190.678 132.411 190.503 132.586 190.287 132.586L186.774 132.586L186.774 105.258L190.287 105.258Z" fill="url(#paint9_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="212.149" y="99.9877" width="39.2347" height="6.24631" rx="0.390395" transform="rotate(90 212.149 99.9877)" fill="url(#paint10_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M225.618 99.9877C225.834 99.9877 226.008 100.162 226.008 100.378L226.008 138.832C226.008 139.048 225.834 139.222 225.618 139.222L210.783 139.222C210.567 139.222 210.393 139.052 210.393 138.836C210.393 137.209 210.393 130.484 210.393 119.605C210.393 108.726 210.393 102.001 210.393 100.374C210.393 100.158 210.567 99.9877 210.783 99.9877L225.618 99.9877Z" fill="url(#paint11_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect @selector="inlet" fill="url(#paint12_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="27.2827" y="137.984" width="36.7682" height="12.2821" rx="0.312921" transform="rotate(-90 27.2827 137.984)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.56079"/>
              <rect x="103.636" y="137.984" width="36.7682" height="12.2821" rx="0.312921" transform="rotate(-90 103.636 137.984)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.56079"/>
              <rect x="102.461" y="141.504" width="43.8089" height="1.5646" rx="0.312921" transform="rotate(-90 102.461 141.504)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.56079"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 125.231C15 132.014 20.4989 137.513 27.2821 137.513L27.2821 101.606C20.4989 101.606 15 107.105 15 113.888L15 125.231Z" fill="url(#paint13_linear_2920_1281)" stroke="#7A7A7A" stroke-width="0.56079"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7931 131.49C32.149 131.49 31.6091 131.925 31.4657 132.51C31.4246 132.678 31.5686 132.82 31.7414 132.82H35.1053C35.2781 132.82 35.4221 132.678 35.3809 132.51C35.2376 131.925 34.6977 131.49 34.0535 131.49H32.7931Z" fill="white" stroke="#181818" stroke-width="0.469382"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7931 123.119C32.149 123.119 31.6091 123.554 31.4657 124.139C31.4246 124.307 31.5686 124.449 31.7414 124.449H35.1053C35.2781 124.449 35.4221 124.307 35.3809 124.139C35.2376 123.554 34.6977 123.119 34.0535 123.119H32.7931Z" fill="white" stroke="#181818" stroke-width="0.469382"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7931 114.749C32.149 114.749 31.6091 115.183 31.4657 115.768C31.4246 115.936 31.5686 116.078 31.7414 116.078H35.1053C35.2781 116.078 35.4221 115.936 35.3809 115.768C35.2376 115.183 34.6977 114.749 34.0535 114.749H32.7931Z" fill="white" stroke="#181818" stroke-width="0.469382"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M32.7931 106.378C32.149 106.378 31.6091 106.813 31.4657 107.398C31.4246 107.566 31.5686 107.708 31.7414 107.708H35.1053C35.2781 107.708 35.4221 107.566 35.3809 107.398C35.2376 106.813 34.6977 106.378 34.0535 106.378H32.7931Z" fill="white" stroke="#181818" stroke-width="0.469382"/>
              <rect x="39.564" y="139.861" width="40.5233" height="62.8971" transform="rotate(-90 39.564 139.861)" fill="url(#paint14_linear_2920_1281)" stroke="#7A7A7A" stroke-width="0.56079"/>
              <rect x="39.6082" y="139.015" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 139.015)" fill="#7A7A7A"/>
              <rect x="39.6082" y="137.702" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 137.702)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="39.6082" y="136.389" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 136.389)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="39.6082" y="135.077" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 135.077)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="39.6082" y="133.764" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 133.764)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="39.6082" y="132.451" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 132.451)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="39.6082" y="131.139" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 131.139)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="39.6082" y="129.826" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 129.826)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="128.513" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 128.513)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="127.201" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 127.201)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="125.888" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 125.888)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="124.575" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 124.575)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="123.263" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 123.263)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="121.95" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 121.95)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="120.637" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 120.637)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="39.6082" y="119.325" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 119.325)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="118.012" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 118.012)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="116.699" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 116.699)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="115.387" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 115.387)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="114.074" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 114.074)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="112.761" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 112.761)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="111.449" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 111.449)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="110.136" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 110.136)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="39.6082" y="108.823" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 108.823)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="39.6082" y="107.511" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 107.511)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="39.6082" y="106.198" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 106.198)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="39.6082" y="104.885" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 104.885)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="39.6082" y="103.573" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 103.573)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="39.6082" y="102.26" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 102.26)" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="39.6082" y="100.947" width="0.437556" height="62.7892" rx="0.161559" transform="rotate(-90 39.6082 100.947)" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="43.7734" y="109.428" width="5.08497" height="54.6211" rx="0.312921" transform="rotate(-90 43.7734 109.428)" fill="#181818"/>
              <rect x="43.6338" y="120.693" width="2.26868" height="54.6211" rx="0.312921" transform="rotate(-90 43.6338 120.693)" fill="#181818"/>
              <rect x="48.6057" y="123.666" width="8.21418" height="31.2321" rx="0.312921" transform="rotate(-90 48.6057 123.666)" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.56079"/>
              <rect x="43.7734" y="134.775" width="5.08497" height="54.6211" rx="0.312921" transform="rotate(-90 43.7734 134.775)" fill="#181818"/>
              <path d="M47.7008 148.075C46.6638 148.075 45.8232 147.234 45.8232 146.197L45.8232 140.173C45.8232 140.001 45.9633 139.861 46.1362 139.861L79.9316 139.861C80.1044 139.861 80.2445 140.001 80.2445 140.173L80.2445 146.197C80.2445 147.234 79.404 148.075 78.367 148.075L47.7008 148.075Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.56079"/>
              <circle cx="53.0203" cy="143.929" r="3.12921" transform="rotate(-90 53.0203 143.929)" fill="#181818"/>
              <path d="M46.1359 99.3383C45.9631 99.3383 45.823 99.1982 45.823 99.0253L45.823 93.0016C45.823 91.9647 46.6636 91.1241 47.7005 91.1241L78.3668 91.1241C79.4037 91.1241 80.2443 91.9647 80.2443 93.0016L80.2443 99.0253C80.2443 99.1982 80.1042 99.3383 79.9314 99.3383L46.1359 99.3383Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.56079"/>
              <circle cx="53.0203" cy="95.1921" r="3.12921" transform="rotate(-90 53.0203 95.1921)" fill="#181818"/>
              <path d="M104.144 132.195L115.879 132.195" stroke="#7A7A7A" stroke-width="0.625842" stroke-linecap="square"/>
              <path d="M104.144 129.691L115.879 129.691" stroke="#7A7A7A" stroke-width="0.625842" stroke-linecap="square"/>
              <path d="M104.144 115.453L115.879 115.453" stroke="#7A7A7A" stroke-width="0.625842" stroke-linecap="square"/>
              <rect x="106.373" y="127.031" width="14.8637" height="6.80603" rx="0.312921" transform="rotate(-90 106.373 127.031)" fill="white" stroke="#7A7A7A" stroke-width="0.56079"/>
              <rect x="106.607" y="139.861" width="2.97275" height="3.12921" rx="0.312921" transform="rotate(-90 106.607 139.861)" fill="#181818"/>
              <rect x="106.607" y="102.31" width="2.97275" height="3.12921" rx="0.312921" transform="rotate(-90 106.607 102.31)" fill="#181818"/>
              <rect x="159.251" y="128.487" width="2.73276" height="19.7149" fill="url(#paint15_linear_2920_1281)" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path d="M163.74 108.954L184.595 107.245C184.822 107.226 185.017 107.406 185.017 107.634V130.011C185.017 130.241 184.82 130.421 184.591 130.4L163.74 128.503L163.74 108.954Z" fill="url(#paint16_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M180.332 107.405C180.332 107.513 180.245 107.6 180.137 107.6L179.161 107.6C179.053 107.6 178.966 107.513 178.966 107.405V107.405C178.966 107.297 179.053 107.21 179.161 107.21L180.137 107.21C180.245 107.21 180.332 107.297 180.332 107.405V107.405Z" fill="#818181" stroke="#818181" stroke-width="0.390395"/>
              <rect x="175.647" y="125.559" width="2.34237" height="2.34237" rx="1.17118" transform="rotate(90 175.647 125.559)" fill="url(#paint17_radial_2920_1281)" stroke="#7A7A7A" stroke-width="0.195197"/>
              <rect x="175.127" y="126.079" width="1.30132" height="1.30132" rx="0.650658" transform="rotate(90 175.127 126.079)" fill="url(#paint18_radial_2920_1281)" stroke="#7A7A7A" stroke-width="0.195197"/>
              <rect x="42.4531" y="150.739" width="184.521" height="6.63671" fill="url(#paint19_linear_2920_1281)" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path d="M42.4663 157.571H226.987L227.947 158.323C228.532 158.78 228.208 159.718 227.466 159.718H41.9474C41.1974 159.718 40.8788 158.764 41.4784 158.313L42.4663 157.571Z" fill="url(#paint20_linear_2920_1281)" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path d="M42.5674 148.397H227.088L228.048 149.148C228.633 149.606 228.309 150.544 227.567 150.544H42.0485C41.2984 150.544 40.9798 149.589 41.5795 149.139L42.5674 148.397Z" fill="url(#paint21_linear_2920_1281)" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path d="M190.678 110.138H205.513V112.48H190.678V110.138Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.390395"/>
              <path d="M190.678 127.901H205.513V125.559H190.678V127.901Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.390395"/>
              <rect x="205.893" y="101.159" width="3.68277" height="0.575326" rx="0.195197" transform="rotate(90 205.893 101.159)" fill="url(#paint22_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="205.893" y="109.156" width="3.68277" height="0.780789" rx="0.195197" transform="rotate(90 205.893 109.156)" fill="url(#paint23_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="205.893" y="117.153" width="3.68277" height="0.780789" rx="0.195197" transform="rotate(90 205.893 117.153)" fill="url(#paint24_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="205.893" y="125.149" width="3.68277" height="0.780789" rx="0.195197" transform="rotate(90 205.893 125.149)" fill="url(#paint25_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <rect x="205.893" y="133.146" width="3.68277" height="0.780789" rx="0.195197" transform="rotate(90 205.893 133.146)" fill="url(#paint26_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <path d="M146.666 108.74C147.097 108.74 147.447 109.089 147.447 109.521L147.447 148.401L132.807 148.401L132.807 109.521C132.807 109.089 133.157 108.74 133.588 108.74L146.666 108.74Z" fill="#D9D9D9" stroke="#818181" stroke-width="0.390395"/>
              <rect x="223.641" y="99.9877" width="13.2239" height="5.9844" transform="rotate(180 223.641 99.9877)" fill="#D9D9D9" stroke="#818181" stroke-width="0.390395"/>
              <rect @selector="outlet" fill="url(#paint27_linear_2920_1281)" stroke="#818181" stroke-width="0.390395"/>
              <defs>
              <linearGradient id="paint0_linear_2920_1281" x1="227.375" y1="148.422" x2="203.951" y2="148.422" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_1281" x1="127.592" y1="123.503" x2="127.533" y2="115.695" gradientUnits="userSpaceOnUse">
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              </linearGradient>
              <linearGradient id="paint2_linear_2920_1281" x1="150.729" y1="122.723" x2="150.687" y2="116.476" gradientUnits="userSpaceOnUse">
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              </linearGradient>
              <linearGradient id="paint3_linear_2920_1281" x1="169.206" y1="127.233" x2="148.905" y2="127.233" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint4_linear_2920_1281" x1="205.734" y1="114.823" x2="205.734" y2="123.216" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint5_linear_2920_1281" x1="161.803" y1="108.967" x2="161.803" y2="128.487" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint6_linear_2920_1281" x1="198.415" y1="114.823" x2="198.415" y2="123.216" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint7_linear_2920_1281" x1="163.624" y1="108.967" x2="163.624" y2="128.487" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint8_linear_2920_1281" x1="184.985" y1="132.586" x2="184.985" y2="105.258" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint9_linear_2920_1281" x1="186.703" y1="132.586" x2="186.703" y2="105.258" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint10_linear_2920_1281" x1="251.384" y1="106.347" x2="212.149" y2="106.347" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint11_linear_2920_1281" x1="210.109" y1="139.222" x2="210.109" y2="99.9877" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint12_linear_2920_1281" x1="263.319" y1="139.64" x2="231.096" y2="139.64" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint13_linear_2920_1281" x1="21.1411" y1="101.606" x2="21.1411" y2="137.513" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint14_linear_2920_1281" x1="80.0872" y1="171.31" x2="39.564" y2="171.31" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint15_linear_2920_1281" x1="161.983" y1="148.56" x2="159.251" y2="148.56" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint16_linear_2920_1281" x1="185.017" y1="128.902" x2="163.74" y2="128.902" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <radialGradient id="paint17_radial_2920_1281" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(176.819 126.73) rotate(90) scale(1.17118)">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.70219" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </radialGradient>
              <radialGradient id="paint18_radial_2920_1281" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(175.777 126.73) rotate(90) scale(0.650658)">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.70219" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </radialGradient>
              <linearGradient id="paint19_linear_2920_1281" x1="226.974" y1="157.496" x2="42.4531" y2="157.496" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint20_linear_2920_1281" x1="226.987" y1="160.751" x2="42.4662" y2="160.751" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint21_linear_2920_1281" x1="227.088" y1="151.577" x2="42.5673" y2="151.577" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint22_linear_2920_1281" x1="209.576" y1="101.745" x2="205.893" y2="101.745" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint23_linear_2920_1281" x1="209.576" y1="109.951" x2="205.893" y2="109.951" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint24_linear_2920_1281" x1="209.576" y1="117.948" x2="205.893" y2="117.948" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint25_linear_2920_1281" x1="209.576" y1="125.944" x2="205.893" y2="125.944" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint26_linear_2920_1281" x1="209.576" y1="133.941" x2="205.893" y2="133.941" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint27_linear_2920_1281" x1="247.355" y1="98.1608" x2="227.138" y2="98.1608" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
    }
}