export const ScadaConfigurationPathConstants = {
  GET_STENCILS: '/visualization/sidebarItems',
  GET_ASSET_GRAPHS: '/visualization/getLayout',
  SAVE_CONFIGURATION: '/visualization/setLayout',
  GET_QUESTIONS: '/visualization/equipmentQuestions',
  SUBMIT_ANSWER: '/visualization/submitAnswers',
  FETCH_SENSORS: '/visualization/sensorList',
  SAVE_EQUIPMENT_SENSORS: '/visualization/createSensor',
  FETCH_EQUIPMENT_SENSORS: '/visualization/fetchEquipmentSensors',
  ADD_SENSOR_TO_EQUIPMENT: 'visualization/addSensors',
  REAL_TIME_SCADA: 'visualization/realTimeScada'
}