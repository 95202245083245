import React from "react";
import { Stack, Typography } from "@mui/material";
import Button from "../../core-components/theme/components/Button/Button";

interface ButtonsProps {
  onSave: () => void;
  onCancel: () => void;
  containerProps?: any;
  saveButtonProps?: any;
  cancelButtonProps?: any;
  cancelLabel?: any,
  saveLabel?: any,
  disableSubmit?: boolean
}

const ButtonStack: React.FC<ButtonsProps> = ({
  containerProps,
  saveButtonProps,
  cancelButtonProps,
  disableSubmit,
  onSave,
  onCancel,
  cancelLabel="CANCEL",
  saveLabel="SUBMIT"
  
}) => {
  return (
    <Stack {...containerProps}>
      <Button
        variant="contained"
        size='large'
        onClick={onCancel}
        {...cancelButtonProps}
      >
        <Typography variant="body" color="Neutrals.500" lineHeight="24px">
          {cancelLabel}
        </Typography>
      </Button>
      {
        !disableSubmit &&
        <Button
          variant="contained"
          onClick={onSave}
          size='large'
          {...saveButtonProps}
        >
          <Typography variant="body" lineHeight="24px" color="white">
            {saveLabel}
          </Typography>
        </Button>
      }
    </Stack>
  )
}

export default ButtonStack;