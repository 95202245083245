import * as joint from '@joint/plus';
import { OP_TANK } from './OP_TANK';

export class CP_TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "CP_TANK",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        // mainRect: {
        //   width: 'calc(w)',
        //   height: 'calc(h)',
        //   fill: 'transparent',
        //   transform: "rotate(16.9256 24.4922 76.4597)",
        //   stroke:"red",
        //   "stroke-width":"0.31433",
        //   magnet: true
        // },
        body: {
          x: 39.05, 
          y: 21.5968, 
          width: 171.9,
          height: 206.694,
          stroke: "#818181",
          "stroke-width": 0.887097,
          magnet: true
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect @selector="body" fill="url(#paint0_linear_2796_247)"/>
        <path d="M65.7427 214.067H183.848" stroke="url(#paint1_linear_2796_247)" stroke-width="1.58978" stroke-linecap="round"/>
        <path d="M65.7427 36.0121H183.848" stroke="url(#paint2_linear_2796_247)" stroke-width="1.58978" stroke-linecap="round"/>
        <path d="M65.7427 45.5508H183.848" stroke="url(#paint3_linear_2796_247)" stroke-width="1.58978" stroke-linecap="round"/>
        <rect x="27.9738" y="228.29" width="194.052" height="12.4194" rx="1.33065" fill="url(#paint4_linear_2796_247)" stroke="#818181" stroke-width="0.887097"/>
        <rect x="27.9738" y="9.29032" width="194.052" height="12.4194" rx="1.33065" fill="url(#paint5_linear_2796_247)" stroke="#818181" stroke-width="0.887097"/>
        <defs>
        <linearGradient id="paint0_linear_2796_247" x1="211.394" y1="115.221" x2="38.6064" y2="115.221" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2796_247" x1="65.7427" y1="214.567" x2="183.848" y2="214.567" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#D1D1D1"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#D1D1D1"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2796_247" x1="65.7427" y1="36.5121" x2="183.848" y2="36.5121" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#D1D1D1"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#D1D1D1"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2796_247" x1="65.7427" y1="46.0508" x2="183.848" y2="46.0508" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#D1D1D1"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#D1D1D1"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2796_247" x1="222.47" y1="233.877" x2="27.5303" y2="233.877" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint5_linear_2796_247" x1="222.47" y1="14.8767" x2="27.5303" y2="14.8767" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        </defs>
      </svg>
       `;
  }
}