import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';

interface SearchBoxProps extends Omit<TextFieldProps, 'onChange'> {
  onSearch?: (query: string) => void;
  onClear: () => void;
  backgroundColor?: string;
  fullWidth?: boolean;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | null;
  borderRadius?: string | number;
  height?: string | number;
  borderColor?: string;
}

export interface SearchBoxHandle {
  handleClear: () => void;
}

const SearchBox = forwardRef<SearchBoxHandle, SearchBoxProps>(
  (
    {
      onSearch,
      onClear,
      onChange,
      backgroundColor = '#F2F7FB',
      fullWidth,
      sx,
      borderRadius,
      height,
      borderColor,
      ...props
    },
    ref
  ) => {
    const [input, setInput] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInput(event.target.value);
      onChange?.(event);
    };

    useImperativeHandle(ref, () => ({
      handleClear,
    }));

    function handleClear() {
      setInput('');
      onClear();
    }

    const handleSearch = () => {
      if (onSearch) {
        onSearch(input);
      }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    };

    return (
      <TextField
        value={input}
        variant="outlined"
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        fullWidth={fullWidth}
        {...props}
        sx={{
          ...sx,
          '.MuiInputBase-root': {
            background: backgroundColor || 'white',
            borderRadius: borderRadius,
            height: height,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: borderColor,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {input.length > 0 && (
                <IconButton onClick={handleClear} edge="end">
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton onClick={handleSearch} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default SearchBox;
