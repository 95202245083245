import * as joint from '@joint/plus';

export class TANK_2 extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "TANK_2",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        root: {
          magnet: true
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect x="43.9998" y="4.00024" width="161.333" height="242" rx="6.20513" fill="url(#paint0_linear_2920_134)" stroke="#7A7A7A" stroke-width="1.89476"/>
        <path d="M69.3379 228.902H179.614" stroke="url(#paint1_linear_2920_134)" stroke-width="1.85338" stroke-linecap="round"/>
        <path d="M69.3379 21.3232H179.614" stroke="url(#paint2_linear_2920_134)" stroke-width="1.85338" stroke-linecap="round"/>
        <path d="M69.3379 32.4438H179.614" stroke="url(#paint3_linear_2920_134)" stroke-width="1.85338" stroke-linecap="round"/>
        <defs>
        <linearGradient id="paint0_linear_2920_134" x1="205.333" y1="125" x2="43.9998" y2="125" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2920_134" x1="69.3379" y1="229.402" x2="179.614" y2="229.402" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#CBCBCB"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#CDCDCD"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2920_134" x1="69.3379" y1="21.8232" x2="179.614" y2="21.8232" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#CBCBCB"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#CDCDCD"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2920_134" x1="69.3379" y1="32.9438" x2="179.614" y2="32.9438" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#CBCBCB"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#CDCDCD"/>
        </linearGradient>
        </defs>
      </svg>
       `;
  }
}