import * as joint from '@joint/plus';

import { dia, shapes, util, ui } from '@joint/plus';
const markup = util.svg/* xml */`<svg @selector="body" xmlns="http://www.w3.org/2000/svg" fill="none">
              <path d="M60.0715 15L60.0715 22.0565L118.747 22.0565L118.747 15L60.0715 15Z" fill="url(#paint0_linear_2920_1395)" stroke="#818181" stroke-width="1.05575"/>
              <rect x="83.2981" y="143.801" width="85.7794" height="29.8248" fill="url(#paint1_linear_2920_1395)" stroke="#818181" stroke-width="1.05575"/>
              <path d="M85.4094 173.626H166.966V235H85.4094V173.626Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.05575"/>
              <path d="M139.343 174.163H166.529V234.461H139.343V174.163Z" fill="#B8B8B8"/>
              <path d="M85.9446 174.163H113.13V234.461H85.9446V174.163Z" fill="#E6E6E6"/>
              <path d="M62.9749 22.1263H116.29V40.811C119.516 40.3258 122.821 40.074 126.188 40.074C161.391 40.074 189.928 67.6073 189.928 101.571C189.928 135.535 161.391 163.069 126.188 163.069C90.9847 163.069 62.447 135.535 62.447 101.571C62.447 98.8769 62.6266 96.223 62.9749 93.6201V22.1263Z" fill="#D9D9D9" stroke="#818181" stroke-width="1.05575"/>
              <rect x="128.772" y="55.6012" width="93.2126" height="6.91645" rx="1.05575" transform="rotate(90 128.772 55.6012)" fill="#181818"/>
              <rect x="77.165" y="98.1638" width="95.6599" height="6.81005" rx="1.05575" fill="#181818"/>
              <ellipse @selector="rotorBackground" cx="125.314" cy="102.207" rx="43.148" ry="41.6051" fill="#FF0000" stroke="#7A7A7A" stroke-width="1.36656"/>
              <path @selector="rotor" fill="#D9D9D9" stroke="#818181" stroke-width="1.05575"/>
              <circle cx="125.314" cy="103.112" r="7.50169" fill="#FF0000" stroke="#7A7A7A" stroke-width="1.36656"/>
              <path d="M85.5791 197.317H139.343" stroke="#818181" stroke-width="1.05575"/>
              <path d="M127.516 193.83H139.129V200.692H127.516V193.83Z" fill="#D9D9D9" stroke="#818181" stroke-width="0.527873"/>
              <rect x="139.517" y="231.956" width="0.527873" height="26.9215" transform="rotate(-90 139.517 231.956)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="139.517" y="228.774" width="0.527873" height="26.9215" transform="rotate(-90 139.517 228.774)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="139.517" y="225.592" width="0.527873" height="26.9215" transform="rotate(-90 139.517 225.592)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="139.517" y="222.41" width="0.527873" height="26.9215" transform="rotate(-90 139.517 222.41)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="139.517" y="219.228" width="0.527873" height="26.9215" transform="rotate(-90 139.517 219.228)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="139.517" y="216.046" width="0.527873" height="26.9215" transform="rotate(-90 139.517 216.046)" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="139.517" y="212.865" width="0.527873" height="26.9215" transform="rotate(-90 139.517 212.865)" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="139.517" y="209.683" width="0.527873" height="26.9215" transform="rotate(-90 139.517 209.683)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="139.517" y="206.501" width="0.527873" height="26.9215" transform="rotate(-90 139.517 206.501)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="139.517" y="203.319" width="0.527873" height="26.9215" transform="rotate(-90 139.517 203.319)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="139.517" y="200.137" width="0.527873" height="26.9215" transform="rotate(-90 139.517 200.137)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="139.517" y="196.955" width="0.527873" height="26.9215" transform="rotate(-90 139.517 196.955)" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="139.517" y="193.773" width="0.527873" height="26.9215" transform="rotate(-90 139.517 193.773)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="139.517" y="190.591" width="0.527873" height="26.9215" transform="rotate(-90 139.517 190.591)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="139.517" y="187.409" width="0.527873" height="26.9215" transform="rotate(-90 139.517 187.409)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="139.517" y="184.227" width="0.527873" height="26.9215" transform="rotate(-90 139.517 184.227)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="139.517" y="181.046" width="0.527873" height="26.9215" transform="rotate(-90 139.517 181.046)" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="139.517" y="177.864" width="0.527873" height="26.9215" transform="rotate(-90 139.517 177.864)" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="139.517" y="174.682" width="0.527873" height="26.9215" transform="rotate(-90 139.517 174.682)" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="146.777" y="176.938" width="11.6132" height="5.80661" fill="#D9D9D9" stroke="#818181" stroke-width="0.527873"/>
              <rect x="146.777" y="205.443" width="11.6132" height="5.80661" fill="#D9D9D9" stroke="#818181" stroke-width="0.527873"/>
              <rect x="146.777" y="224.446" width="11.6132" height="5.80661" fill="#D9D9D9" stroke="#818181" stroke-width="0.527873"/>
              <defs>
              <linearGradient id="paint0_linear_2920_1395" x1="119.813" y1="22.0565" x2="119.813" y2="15" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_1395" x1="169.078" y1="174.168" x2="83.2981" y2="174.168" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <radialGradient id="paint2_radial_2920_1395" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(125.314 102.207) rotate(90) scale(41.6051 43.148)">
              <stop offset="0.0516055" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.70219" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </radialGradient>
              <radialGradient id="paint3_radial_2920_1395" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(125.314 103.112) rotate(90) scale(7.50169)">
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.70219" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.952575" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </radialGradient>
              </defs>
            </svg> `;

const POWER_FLAG = "POWER";

// Pump metrics
const r = 30;
const d = 10;
const l = (3 * r) / 4;
export class BLW extends dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "BLW",
      size: {
        width: 100,
        height: 100
      },
      power: 0,
      attrs: {
        root: {
          magnetSelector: "body"
        },
        body: {
          rx: "calc(w / 2)",
          ry: "calc(h / 2)",
          cx: "calc(w / 2)",
          cy: "calc(h / 2)",
          stroke: "gray",
          strokeWidth: 2,
          fill: "lightgray"
        },
        label: {
          text: "Pump",
          textAnchor: "middle",
          textVerticalAnchor: "top",
          x: "calc(0.5*w)",
          y: "calc(h+10)",
          fontSize: 14,
          fontFamily: "sans-serif",
          fill: "#350100"
        },
        rotorGroup: {
          transform: "translate(calc(w/2),calc(h/2))",
          event: "element:power:click",
          cursor: "pointer"
        },
        rotorFrame: {
          r: 40,
          fill: "#FF0000",
          stroke: "#666",
          strokeWidth: 2
        },
        rotorBackground: {
          r: 34,
          fill: "#777",
          stroke: "#222",
          strokeWidth: 1,
          style: {
            transition: "fill 0.5s ease-in-out"
          }
        },
        rotor: {
          // d: `M ${a} ${a} ${b} ${r} -${b} ${r} -${a} ${a} -${r} ${b} -${r} -${b} -${a} -${a} -${b} -${r} ${b} -${r} ${a} -${a} ${r} -${b} ${r} ${b} Z`,
          d: `M130.152 129.584C130.152 130.75 129.207 131.695 128.04 131.695H122.479C121.313 131.695 120.368 130.75 120.368 129.584V124.117C117.646 123.583 115.095 122.593 112.805 121.232L109.114 124.922C108.29 125.747 106.953 125.747 106.128 124.922L102.196 120.99C101.371 120.165 101.371 118.828 102.196 118.004L105.622 114.578C103.989 112.242 102.795 109.595 102.152 106.749H97.5331C96.367 106.749 95.4216 105.804 95.4216 104.637L95.4216 99.0761C95.4216 97.9099 96.367 96.9646 97.5331 96.9646H102.164C102.812 94.1259 104.01 91.487 105.643 89.1577L102.195 85.71C101.37 84.8854 101.37 83.5485 102.195 82.7239L106.127 78.7914C106.952 77.9669 108.289 77.9669 109.113 78.7914L112.837 82.5147C115.119 81.1636 117.659 80.1799 120.368 79.6489V74.1298C120.368 72.9637 121.313 72.0183 122.479 72.0183L128.04 72.0183C129.207 72.0183 130.152 72.9637 130.152 74.1298V79.7385C132.806 80.31 135.29 81.3174 137.521 82.679L141.408 78.792C142.232 77.9674 143.569 77.9674 144.394 78.792L148.326 82.7245C149.151 83.5491 149.151 84.886 148.326 85.7106L144.62 89.4165C146.162 91.6818 147.296 94.2305 147.92 96.9646H152.987C154.153 96.9646 155.099 97.9099 155.099 99.0761V104.637C155.099 105.804 154.153 106.749 152.987 106.749H147.932C147.313 109.491 146.182 112.047 144.641 114.319L148.325 118.003C149.15 118.828 149.15 120.165 148.325 120.989L144.393 124.922C143.568 125.746 142.231 125.746 141.407 124.922L137.552 121.067C135.313 122.439 132.818 123.453 130.152 124.027V129.584Z`,
          stroke: "#222",
          strokeWidth: 3,
          fill: "#bbb"
        },
        
      },
    };
  }

  preinitialize() {
    this.markup = markup;
  }

}



export const BLWiew = dia.ElementView.extend({
  presentationAttributes: dia.ElementView.addPresentationAttributes({
      power: [POWER_FLAG]
  }),

  initFlag: [dia.ElementView.Flags.RENDER, POWER_FLAG],

  spinAnimation: null,

  confirmUpdate(...args) {
      let flags = dia.ElementView.prototype.confirmUpdate.call(this, ...args);
      if (this.hasFlag(flags, POWER_FLAG)) {
          this.togglePower();
          flags = this.removeFlag(flags, POWER_FLAG);
      }
      return flags;
  },

  getRotorElement() {
      return this.findNode('rotor');
  },

  getSpinAnimation() {
      if (this.spinAnimation) return this.spinAnimation;
      const rotorEl = this.getRotorElement();
      if (!rotorEl) return null;

      this.spinAnimation = rotorEl.animate(
          { transform: ['rotate(0deg)', 'rotate(360deg)'] }, 
          {
              fill: 'forwards',
              duration: 1000,
              iterations: Infinity
          }
      );
      
      return this.spinAnimation;
  },

  togglePower() {
      const animation = this.getSpinAnimation();
      if (!animation) return;
      
      animation.playbackRate = this.model.prop('power') ? 1 : 0;
      
      const bg = this.findNode('rotorBackground');
      if (bg) bg.style.fill = this.model.prop('power') ? '#00ff00' : '#777';
  },
});