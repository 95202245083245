import * as joint from '@joint/plus';

export class DOS_TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'DOS_TANK',
      size: {
        width: 250,
        height: 250,
      },
      attrs: {
       
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <rect x="69.5456" y="68.1381" width="111.388" height="167.442" fill="url(#paint0_linear_2920_21)" stroke="#818181" stroke-width="0.718634"/>
              <path d="M86.7937 224.058H163.422" stroke="url(#paint1_linear_2920_21)" stroke-width="1.28787" stroke-linecap="round"/>
              <path d="M86.7936 79.8162H163.422" stroke="url(#paint2_linear_2920_21)" stroke-width="1.28787" stroke-linecap="round"/>
              <path d="M86.7937 87.5435H163.422" stroke="url(#paint3_linear_2920_21)" stroke-width="1.28787" stroke-linecap="round"/>
              <path d="M63.4374 58.0776H187.042C187.638 58.0776 188.12 58.5602 188.12 59.1555V67.0605C188.12 67.6558 187.638 68.1385 187.042 68.1385H63.4374C62.8421 68.1385 62.3594 67.6558 62.3594 67.0605V59.1555C62.3594 58.5602 62.8421 58.0776 63.4374 58.0776Z" fill="url(#paint4_linear_2920_21)" stroke="#818181" stroke-width="0.718634"/>
              <path d="M126.677 48.0165H179.856C180.451 48.0165 180.934 48.4992 180.934 49.0945V58.0774H126.677C126.082 58.0774 125.599 57.5948 125.599 56.9995V49.0945C125.599 48.4992 126.082 48.0165 126.677 48.0165Z" fill="url(#paint5_linear_2920_21)" stroke="#818181" stroke-width="0.718634"/>
              <path d="M84.2778 48.0165H109.071C110.46 48.0165 111.586 49.1426 111.586 50.5318V58.0774H81.7626V50.5318C81.7626 49.1426 82.8887 48.0165 84.2778 48.0165Z" fill="url(#paint6_linear_2920_21)" stroke="#818181" stroke-width="0.718634"/>
              <rect x="62.3594" y="235.58" width="125.761" height="10.0609" rx="1.07795" fill="url(#paint7_linear_2920_21)" stroke="#818181" stroke-width="0.718634"/>
              <rect x="83.2" y="48.3757" width="0.359317" height="9.34224" fill="#818181"/>
              <rect x="84.3079" y="48.3757" width="0.359317" height="9.34224" fill="#818181"/>
              <rect x="85.4158" y="48.3757" width="0.359317" height="9.34224" fill="#818181"/>
              <rect x="86.5237" y="48.3757" width="0.359317" height="9.34224" fill="#818181"/>
              <rect x="87.6316" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.8"/>
              <rect x="88.7395" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.8"/>
              <rect x="89.8474" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.8"/>
              <rect x="90.9553" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.8"/>
              <rect x="92.0632" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.8"/>
              <rect x="93.1711" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.8"/>
              <rect x="94.279" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.6"/>
              <rect x="95.3869" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.6"/>
              <rect x="96.4948" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.6"/>
              <rect x="97.6026" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.6"/>
              <rect x="98.7104" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.6"/>
              <rect x="99.8184" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.6"/>
              <rect x="100.926" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.6"/>
              <rect x="102.034" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.4"/>
              <rect x="103.142" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.4"/>
              <rect x="104.25" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.4"/>
              <rect x="105.358" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.4"/>
              <rect x="106.466" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.4"/>
              <rect x="107.574" y="48.3757" width="0.359317" height="9.34224" fill="#818181" fill-opacity="0.4"/>
              <rect x="108.681" y="48.3757" width="0.359317" height="9.34224" fill="#818181"/>
              <rect x="109.789" y="48.3757" width="0.359317" height="9.34224" fill="#818181"/>
              <rect x="144.419" y="9.85107" width="17.5144" height="5.85054" rx="0.149058" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.26713"/>
              <rect x="144.419" y="41.9485" width="17.5144" height="5.85054" rx="0.149058" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.26713"/>
              <rect x="142.742" y="41.3896" width="20.8682" height="0.745292" rx="0.149058" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.26713"/>
              <rect x="143.572" y="15.6726" width="19.2795" height="25.7408" fill="url(#paint8_linear_2920_21)" stroke="#7A7A7A" stroke-width="0.26713"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M150.494 4.00073C147.263 4.00073 144.643 6.6201 144.643 9.85128H161.748C161.748 6.6201 159.128 4.00073 155.897 4.00073H150.494Z" fill="url(#paint9_linear_2920_21)" stroke="#7A7A7A" stroke-width="0.26713"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M147.512 12.4762C147.512 12.1694 147.305 11.9122 147.027 11.8439C146.947 11.8243 146.879 11.8929 146.879 11.9752L146.879 13.5776C146.879 13.6599 146.947 13.7285 147.027 13.7089C147.305 13.6406 147.512 13.3834 147.512 13.0766L147.512 12.4762Z" fill="white" stroke="#181818" stroke-width="0.223588"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M151.499 12.4762C151.499 12.1694 151.292 11.9122 151.013 11.8439C150.934 11.8243 150.866 11.8929 150.866 11.9752L150.866 13.5776C150.866 13.6599 150.934 13.7285 151.013 13.7089C151.292 13.6406 151.499 13.3834 151.499 13.0766L151.499 12.4762Z" fill="white" stroke="#181818" stroke-width="0.223588"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M155.487 12.4762C155.487 12.1694 155.28 11.9122 155.001 11.8439C154.921 11.8243 154.853 11.8929 154.853 11.9752L154.853 13.5776C154.853 13.6599 154.921 13.7285 155.001 13.7089C155.28 13.6406 155.487 13.3834 155.487 13.0766L155.487 12.4762Z" fill="white" stroke="#181818" stroke-width="0.223588"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M159.474 12.4762C159.474 12.1694 159.267 11.9122 158.988 11.8439C158.908 11.8243 158.841 11.8929 158.841 11.9752L158.841 13.5776C158.841 13.6599 158.908 13.7285 158.988 13.7089C159.267 13.6406 159.474 13.3834 159.474 13.0766L159.474 12.4762Z" fill="white" stroke="#181818" stroke-width="0.223588"/>
              <rect x="143.885" y="15.6726" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A"/>
              <rect x="144.51" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="145.136" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="145.761" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="146.386" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="147.012" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="147.637" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="148.262" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="148.887" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="149.513" y="15.6726" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="150.138" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="150.763" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="151.388" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="152.014" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="152.639" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="153.264" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="153.89" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="154.515" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="155.14" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="155.765" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="156.391" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="157.016" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="157.641" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="158.266" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="158.892" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="159.517" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="160.142" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="160.767" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="161.393" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="162.018" y="15.6729" width="0.208428" height="25.7408" rx="0.0769579" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="158.021" y="17.7061" width="2.4222" height="21.8495" rx="0.149058" fill="#181818"/>
              <rect x="152.655" y="17.6394" width="1.08067" height="21.8495" rx="0.149058" fill="#181818"/>
              <rect x="151.239" y="20.0078" width="3.91278" height="12.7933" rx="0.149058" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.26713"/>
              <rect x="145.947" y="17.7061" width="2.4222" height="21.8495" rx="0.149058" fill="#181818"/>
              <path d="M139.612 19.5772C139.612 19.0833 140.013 18.6829 140.507 18.6829H143.376C143.458 18.6829 143.525 18.7496 143.525 18.8319V34.9302C143.525 35.0126 143.458 35.0793 143.376 35.0793H140.507C140.013 35.0793 139.612 34.6789 139.612 34.1849V19.5772Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.26713"/>
              <circle cx="141.587" cy="22.1112" r="1.49058" fill="#181818"/>
              <path d="M162.827 18.8319C162.827 18.7496 162.894 18.6829 162.977 18.6829H165.846C166.34 18.6829 166.74 19.0833 166.74 19.5772V34.1849C166.74 34.6789 166.34 35.0793 165.846 35.0793H162.977C162.894 35.0793 162.827 35.0126 162.827 34.9302V18.8319Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.26713"/>
              <circle cx="164.803" cy="22.1112" r="1.49058" fill="#181818"/>
              <path d="M147.177 42.1907L147.177 47.7804" stroke="#7A7A7A" stroke-width="0.298117" stroke-linecap="square"/>
              <path d="M148.369 42.1907L148.369 47.7804" stroke="#7A7A7A" stroke-width="0.298117" stroke-linecap="square"/>
              <path d="M155.152 42.1907L155.152 47.7804" stroke="#7A7A7A" stroke-width="0.298117" stroke-linecap="square"/>
              <rect x="149.636" y="43.2527" width="7.08028" height="3.24202" rx="0.149058" fill="white" stroke="#7A7A7A" stroke-width="0.26713"/>
              <rect x="143.525" y="43.3647" width="1.41606" height="1.49058" rx="0.149058" fill="#181818"/>
              <rect x="161.412" y="43.3647" width="1.41606" height="1.49058" rx="0.149058" fill="#181818"/>
              <defs>
              <linearGradient id="paint0_linear_2920_21" x1="181.293" y1="143.982" x2="69.1863" y2="143.982" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_21" x1="86.7937" y1="224.558" x2="163.422" y2="224.558" gradientUnits="userSpaceOnUse">
              <stop offset="0.00399963" stop-color="#D1D1D1"/>
              <stop offset="0.5" stop-color="#E6E6E6"/>
              <stop offset="1" stop-color="#D1D1D1"/>
              </linearGradient>
              <linearGradient id="paint2_linear_2920_21" x1="86.7936" y1="80.3162" x2="163.422" y2="80.3162" gradientUnits="userSpaceOnUse">
              <stop offset="0.00399963" stop-color="#D1D1D1"/>
              <stop offset="0.5" stop-color="#E6E6E6"/>
              <stop offset="1" stop-color="#D1D1D1"/>
              </linearGradient>
              <linearGradient id="paint3_linear_2920_21" x1="86.7937" y1="88.0435" x2="163.422" y2="88.0435" gradientUnits="userSpaceOnUse">
              <stop offset="0.00399963" stop-color="#D1D1D1"/>
              <stop offset="0.5" stop-color="#E6E6E6"/>
              <stop offset="1" stop-color="#D1D1D1"/>
              </linearGradient>
              <linearGradient id="paint4_linear_2920_21" x1="188.48" y1="62.6031" x2="62.0001" y2="62.6031" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint5_linear_2920_21" x1="181.293" y1="52.5421" x2="125.24" y2="52.5421" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint6_linear_2920_21" x1="111.945" y1="52.5421" x2="81.4033" y2="52.5421" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint7_linear_2920_21" x1="188.48" y1="240.106" x2="62.0001" y2="240.106" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint8_linear_2920_21" x1="162.852" y1="28.543" x2="143.572" y2="28.543" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint9_linear_2920_21" x1="161.748" y1="6.926" x2="144.643" y2="6.926" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
  }
}
