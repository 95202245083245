import * as joint from '@joint/plus';

export class OP_TANK extends joint.dia.Element {
  constructor(attributes?: any, options?: any) {
    super(attributes, options);
    this.setupEvents();
  }

  setupEvents() {
    this.on('element:pointerclick', (evt: any) => {
      console.log('Tank clicked');
      // this.trigger('inspector:open', this);
    });
  }
  defaults() {
    return {
      ...super.defaults,
      type: "OP_TANK",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        body: {
          x: 39.05, 
          y: 15.4435, 
          width: 171.9, 
          height: 206.694,
          stroke: "#818181",
          "stroke-width": 0.887097,
          magent:true
        },
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect @selector="body" fill="url(#paint0_linear_2920_1237)" stroke="#818181" stroke-width="0.887097"/>
        <path d="M65.7427 207.914H183.848" stroke="url(#paint1_linear_2920_1237)" stroke-width="1.58978" stroke-linecap="round"/>
        <path d="M65.7427 29.8589H183.848" stroke="url(#paint2_linear_2920_1237)" stroke-width="1.58978" stroke-linecap="round"/>
        <path d="M65.7427 39.3976H183.848" stroke="url(#paint3_linear_2920_1237)" stroke-width="1.58978" stroke-linecap="round"/>
        <rect x="27.9738" y="222.137" width="194.052" height="12.4194" rx="1.33065" fill="url(#paint4_linear_2920_1237)" stroke="#818181" stroke-width="0.887097"/>
        <defs>
        <linearGradient id="paint0_linear_2920_1237" x1="211.394" y1="109.067" x2="38.6064" y2="109.067" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        <linearGradient id="paint1_linear_2920_1237" x1="65.7427" y1="208.414" x2="183.848" y2="208.414" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#D1D1D1"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#D1D1D1"/>
        </linearGradient>
        <linearGradient id="paint2_linear_2920_1237" x1="65.7427" y1="30.3589" x2="183.848" y2="30.3589" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#D1D1D1"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#D1D1D1"/>
        </linearGradient>
        <linearGradient id="paint3_linear_2920_1237" x1="65.7427" y1="39.8976" x2="183.848" y2="39.8976" gradientUnits="userSpaceOnUse">
        <stop offset="0.00399963" stop-color="#D1D1D1"/>
        <stop offset="0.5" stop-color="#E6E6E6"/>
        <stop offset="1" stop-color="#D1D1D1"/>
        </linearGradient>
        <linearGradient id="paint4_linear_2920_1237" x1="222.47" y1="227.724" x2="27.5303" y2="227.724" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8A8A8A"/>
        <stop offset="0.1" stop-color="#B1B1B1"/>
        <stop offset="0.2" stop-color="#E3E3E3"/>
        <stop offset="0.4" stop-color="white"/>
        <stop offset="0.6" stop-color="white"/>
        <stop offset="0.8" stop-color="#E3E3E3"/>
        <stop offset="0.9" stop-color="#B1B1B1"/>
        <stop offset="1" stop-color="#848685"/>
        </linearGradient>
        </defs>
      </svg>
       `;
  }
}