import * as joint from '@joint/plus';

export class DS_PUMP extends joint.dia.Element {
    defaults() {
        return {
            ...super.defaults,
            type: "DS_PUMP",
            size: {
                width: 250,
                height: 250
            },
            // x="183.282" y="43.9653" width="12" height="93.2818" rx="6" fill="url(#paint5_linear_3059_561)" stroke="#818181" stroke-width="1.28076"
            attrs: {
                body: {
                    magnet: false // Disable magnetism for the body
                },
                inlet: {
                  x: 168.184,
                  y: 116,
                  width: 26,
                  height: 20,
                  // rx: 6,
                  fill: "url(#paint5_linear_3092_6268)",
                  stroke: "#818181",
                  strokeWidth: 1.28076,
                  magnet: true
                },
                outlet: {
                  x: 168.184,
                  y: 43.9653,
                  width: 26,
                  height: 20,
                  // rx: 6,
                  fill: "url(#paint5_linear_3092_6268)",
                  stroke: "#818181",
                  strokeWidth: 1.28076,
                  magnet: true
                }
            }

        };
    }

    preinitialize() {
        this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
                <path d="M90.3572 146.104C90.3572 145.235 91.0622 144.53 91.9318 144.53H128.78V204.298H91.9318C91.0622 204.298 90.3572 203.593 90.3572 202.724V146.104Z" fill="url(#paint0_linear_3092_6268)" stroke="#818181" stroke-width="1.57466"/>
                <path d="M45.0139 36.5747C45.0139 35.705 45.7189 35 46.5886 35H62.1157V214.952H46.5886C45.7189 214.952 45.0139 214.247 45.0139 213.378V36.5747Z" fill="url(#paint1_linear_3092_6268)" stroke="#818181" stroke-width="1.28076"/>
                <path d="M166.201 204.811L166.201 213.189C166.201 214.056 165.501 214.759 164.635 214.764L123.597 214.991C122.715 214.996 122.002 214.276 122.014 213.395L122.112 206.364C122.124 205.503 122.825 204.811 123.686 204.811L166.201 204.811Z" fill="#FBD487" stroke="#818181" stroke-width="1.57466"/>
                <path d="M128.715 158.21C149.343 158.074 166.148 174.739 166.184 195.368L166.2 204.177C166.2 204.583 165.871 204.914 165.464 204.914L128.715 204.914L128.715 158.21V158.21Z" fill="url(#paint2_linear_3092_6268)" stroke="#818181" stroke-width="1.57466"/>
                <path d="M61.6887 35L127.354 35C128.223 35 128.928 35.705 128.928 36.5747V158.348H103.217C102.347 158.348 101.642 159.053 101.642 159.923V185.69C101.642 186.278 101.97 186.817 102.492 187.088L128.079 200.356C128.601 200.627 128.928 201.166 128.928 201.754V213.372C128.928 214.241 128.223 214.946 127.354 214.946H61.6887V35Z" fill="url(#paint3_linear_3092_6268)" stroke="#818181" stroke-width="1.28076"/>
                <path d="M35.6467 55.3571C35.6467 54.4875 36.3517 53.7825 37.2214 53.7825H45.0141V147.949H37.2214C36.3517 147.949 35.6467 147.244 35.6467 146.375V55.3571Z" fill="#FBD487" stroke="#818181" stroke-width="1.28076"/>
                <path d="M35.6467 168.916C35.6467 168.046 36.3517 167.341 37.2214 167.341H45.0141V184.761H37.2214C36.3517 184.761 35.6467 184.056 35.6467 183.187V168.916Z" fill="#FBD487" stroke="#818181" stroke-width="1.28076"/>
                <rect x="72.7888" y="71.3948" width="38.4227" height="38.4227" rx="1.57466" fill="#FBD487" stroke="#818181" stroke-width="1.28076"/>
                <rect x="150.076" y="209.412" width="3.61548" height="9.0387" rx="1.57466" transform="rotate(-180 150.076 209.412)" fill="#818181"/>
                <rect x="130.865" y="209.412" width="3.61548" height="9.0387" rx="1.57466" transform="rotate(-180 130.865 209.412)" fill="#818181"/>
                <rect x="163.288" y="44.1318" width="92.3522" height="44" rx="1.57466" transform="rotate(90 163.288 44.1318)" fill="url(#paint4_linear_3092_6268)" stroke="#818181" stroke-width="1.28076"/>
                <rect x="119.304" y="136.53" width="0.853835" height="43.4525" transform="rotate(-90 119.304 136.53)" fill="#7A7A7A"/>
                <rect x="119.304" y="133.673" width="0.853835" height="43.4525" transform="rotate(-90 119.304 133.673)" fill="#7A7A7A" fill-opacity="0.8"/>
                <rect x="119.304" y="130.816" width="0.853835" height="43.4525" transform="rotate(-90 119.304 130.816)" fill="#7A7A7A" fill-opacity="0.8"/>
                <rect x="119.304" y="127.959" width="0.853835" height="43.4525" transform="rotate(-90 119.304 127.959)" fill="#7A7A7A" fill-opacity="0.8"/>
                <rect x="119.304" y="125.102" width="0.853835" height="43.4525" transform="rotate(-90 119.304 125.102)" fill="#7A7A7A" fill-opacity="0.8"/>
                <rect x="119.304" y="122.245" width="0.853835" height="43.4525" transform="rotate(-90 119.304 122.245)" fill="#7A7A7A" fill-opacity="0.8"/>
                <rect x="119.304" y="119.388" width="0.853835" height="43.4525" transform="rotate(-90 119.304 119.388)" fill="#7A7A7A" fill-opacity="0.8"/>
                <rect x="119.304" y="116.531" width="0.853835" height="43.4525" transform="rotate(-90 119.304 116.531)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="113.675" width="0.853835" height="43.4525" transform="rotate(-90 119.304 113.675)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="110.818" width="0.853835" height="43.4525" transform="rotate(-90 119.304 110.818)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="107.961" width="0.853835" height="43.4525" transform="rotate(-90 119.304 107.961)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="105.104" width="0.853835" height="43.4525" transform="rotate(-90 119.304 105.104)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="102.247" width="0.853835" height="43.4525" transform="rotate(-90 119.304 102.247)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="99.3904" width="0.853835" height="43.4525" transform="rotate(-90 119.304 99.3904)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="96.5336" width="0.853835" height="43.4525" transform="rotate(-90 119.304 96.5336)" fill="#7A7A7A" fill-opacity="0.6"/>
                <rect x="119.304" y="93.6766" width="0.853835" height="43.4525" transform="rotate(-90 119.304 93.6766)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="90.8198" width="0.853835" height="43.4525" transform="rotate(-90 119.304 90.8198)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="87.9629" width="0.853835" height="43.4525" transform="rotate(-90 119.304 87.9629)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="85.1061" width="0.853835" height="43.4525" transform="rotate(-90 119.304 85.1061)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="82.2493" width="0.853835" height="43.4525" transform="rotate(-90 119.304 82.2493)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="79.3923" width="0.853835" height="43.4525" transform="rotate(-90 119.304 79.3923)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="76.5355" width="0.853835" height="43.4525" transform="rotate(-90 119.304 76.5355)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="73.6787" width="0.853835" height="43.4525" transform="rotate(-90 119.304 73.6787)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="70.8218" width="0.853835" height="43.4525" transform="rotate(-90 119.304 70.8218)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="67.965" width="0.853835" height="43.4525" transform="rotate(-90 119.304 67.965)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="65.108" width="0.853835" height="43.4525" transform="rotate(-90 119.304 65.108)" fill="#7A7A7A" fill-opacity="0.4"/>
                <rect x="119.304" y="62.2512" width="0.853835" height="43.4525" transform="rotate(-90 119.304 62.2512)" fill="#7A7A7A" fill-opacity="0.2"/>
                <rect x="119.304" y="59.3944" width="0.853835" height="43.4525" transform="rotate(-90 119.304 59.3944)" fill="#7A7A7A" fill-opacity="0.2"/>
                <rect x="119.304" y="56.5375" width="0.853835" height="43.4525" transform="rotate(-90 119.304 56.5375)" fill="#7A7A7A" fill-opacity="0.2"/>
                <rect x="119.304" y="53.6807" width="0.853835" height="43.4525" transform="rotate(-90 119.304 53.6807)" fill="#7A7A7A" fill-opacity="0.2"/>
                <rect x="119.304" y="50.8239" width="0.853835" height="43.4525" transform="rotate(-90 119.304 50.8239)" fill="#7A7A7A" fill-opacity="0.2"/>
                <rect x="119.304" y="47.9669" width="0.853835" height="43.4525" transform="rotate(-90 119.304 47.9669)" fill="#7A7A7A" fill-opacity="0.1"/>
                <rect x="119.304" y="45.1101" width="0.853835" height="43.4525" transform="rotate(-90 119.304 45.1101)" fill="#7A7A7A" fill-opacity="0.1"/>
                <rect @selector="outlet" fill="url(#paint5_linear_3092_6268)" />
                <rect @selector="inlet" fill="url(#paint5_linear_3092_6268)" />

                <path d="M163.288 60.8286H197.505C198.375 60.8286 199.08 61.5336 199.08 62.4033V119.023C199.08 119.892 198.375 120.597 197.505 120.597H163.288V60.8286Z" fill="#FBD487" stroke="#818181" stroke-width="1.28076"/>
                <defs>
                <linearGradient id="paint0_linear_3092_6268" x1="128.78" y1="205.405" x2="90.3572" y2="205.405" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8A8A8A"/>
                <stop offset="0.1" stop-color="#B1B1B1"/>
                <stop offset="0.2" stop-color="#E3E3E3"/>
                <stop offset="0.4" stop-color="white"/>
                <stop offset="0.6" stop-color="white"/>
                <stop offset="0.8" stop-color="#E3E3E3"/>
                <stop offset="0.9" stop-color="#B1B1B1"/>
                <stop offset="1" stop-color="#848685"/>
                </linearGradient>
                <linearGradient id="paint1_linear_3092_6268" x1="62.1157" y1="218.285" x2="45.0139" y2="218.285" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8A8A8A"/>
                <stop offset="0.1" stop-color="#B1B1B1"/>
                <stop offset="0.2" stop-color="#E3E3E3"/>
                <stop offset="0.4" stop-color="white"/>
                <stop offset="0.6" stop-color="white"/>
                <stop offset="0.8" stop-color="#E3E3E3"/>
                <stop offset="0.9" stop-color="#B1B1B1"/>
                <stop offset="1" stop-color="#848685"/>
                </linearGradient>
                <linearGradient id="paint2_linear_3092_6268" x1="127.497" y1="204.914" x2="127.497" y2="158.38" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8A8A8A"/>
                <stop offset="0.1" stop-color="#B1B1B1"/>
                <stop offset="0.2" stop-color="#E3E3E3"/>
                <stop offset="0.4" stop-color="white"/>
                <stop offset="0.6" stop-color="white"/>
                <stop offset="0.8" stop-color="#E3E3E3"/>
                <stop offset="0.9" stop-color="#B1B1B1"/>
                <stop offset="1" stop-color="#848685"/>
                </linearGradient>
                <linearGradient id="paint3_linear_3092_6268" x1="130.366" y1="218.54" x2="61.6887" y2="218.54" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8A8A8A"/>
                <stop offset="0.1" stop-color="#B1B1B1"/>
                <stop offset="0.2" stop-color="#E3E3E3"/>
                <stop offset="0.4" stop-color="white"/>
                <stop offset="0.6" stop-color="white"/>
                <stop offset="0.8" stop-color="#E3E3E3"/>
                <stop offset="0.9" stop-color="#B1B1B1"/>
                <stop offset="1" stop-color="#848685"/>
                </linearGradient>
                <linearGradient id="paint4_linear_3092_6268" x1="255.641" y1="88.9312" x2="163.288" y2="88.9312" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8A8A8A"/>
                <stop offset="0.1" stop-color="#B1B1B1"/>
                <stop offset="0.2" stop-color="#E3E3E3"/>
                <stop offset="0.4" stop-color="white"/>
                <stop offset="0.6" stop-color="white"/>
                <stop offset="0.8" stop-color="#E3E3E3"/>
                <stop offset="0.9" stop-color="#B1B1B1"/>
                <stop offset="1" stop-color="#848685"/>
                </linearGradient>
                <linearGradient id="paint5_linear_3092_6268" x1="194.184" y1="138.975" x2="168.184" y2="138.975" gradientUnits="userSpaceOnUse">
                <stop stop-color="#8A8A8A"/>
                <stop offset="0.1" stop-color="#B1B1B1"/>
                <stop offset="0.2" stop-color="#E3E3E3"/>
                <stop offset="0.4" stop-color="white"/>
                <stop offset="0.6" stop-color="white"/>
                <stop offset="0.8" stop-color="#E3E3E3"/>
                <stop offset="0.9" stop-color="#B1B1B1"/>
                <stop offset="1" stop-color="#848685"/>
                </linearGradient>
                </defs>
            </svg>

        `;
    }
}