import { useState } from "react";
import { useFetchQuestionsAsset } from "../../../assetConfiguration/hook/useFetchQuestions";
import { useFetchSensorList } from "./useFetchSensorList";
import { ScadaConfigurationPathConstants } from "../../../assetConfiguration/assetConstants/pathConstants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { saveEquipmentSensor, submitScadaAnswer } from "../../../assetConfiguration/config/scadaConfigurationConfig";
import { useParams } from "react-router-dom";

const useInspectorConfig = (selectedElement: any) => {
  const {assetId = ''} = useParams<string>();
  console.log("assetId :: ", assetId);
   const questionPayload = {
    queryKey: ['questions', (selectedElement?.attributes?.equipmentId || '')],
    path: ScadaConfigurationPathConstants.GET_QUESTIONS,
    payload: {equipmentId: selectedElement?.attributes?.equipmentId, type: selectedElement?.attributes?.type},
  }
  const [selectedTab, setSelectedTab] = useState(0);
  const queryClient = useQueryClient();
  const { data: questionsData, isLoading: questionsLoading } = useFetchQuestionsAsset(questionPayload);
  const { data: sensorList, isLoading: sensorListLoading, refetch: refetchSensors } = useFetchSensorList(selectedElement?.attributes?.equipmentId);

  const saveSensor: any = useMutation({
    mutationFn: (payload: any) => saveEquipmentSensor(payload ),
    onSuccess: (data, id) => {
      refetchSensors();
    },
    onError: (error: any) => {
      toast.error(error?.message || `Problem in Saving Sensors`);
    },
  });

  const saveQuestions: any = useMutation({
    mutationFn: (payload: any) => submitScadaAnswer(payload),
    onSuccess: (data, id) => {
      queryClient.invalidateQueries({ queryKey: questionPayload.queryKey });
    },
    onError: (error: any) => {
      toast.error(error?.message || `Problem in Saving Questions`);
    },
  });

  const handleTabClick = (index: number) => {
    if(selectedTab !== index) setSelectedTab(index)
  }

  const handleSaveSensors = (data: any) => {
    saveSensor.mutate({
      equipmentId: selectedElement?.attributes?.equipmentId,
      assetId,
      sensor: data?.sensorName,
    });
  }

  const handleSaveAnswers = (payload: any) => {
    let newPayload = {
      equipmentId: selectedElement?.attributes?.equipmentId,
      type: selectedElement?.attributes?.type,
      answers: payload
    }
    saveQuestions.mutate({
      ...newPayload
    });
  }

  return {
      selectedTab,
      questionsData,
      sensorList,
      isLoading: questionsLoading || sensorListLoading,
      handleSaveAnswers,
      handleTabClick,
      handleSaveSensors
    }
}

export default useInspectorConfig;