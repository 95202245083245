import { Stack, Typography } from "@mui/material"
import { NEW_BREAKPOINT_OPTIONS } from "../../../../constants/breakPoints.constants"
import DialogBox from "../../../../core-components/theme/components/DialogBox/DialogBox"
import ButtonStack from "../../../../globalComponents/buttonStack"
import { Neutrals } from "../../../../core-components/theme/color-palette/colors"
import { ButtonStyle } from "../../../../globalStyles/globalStyles"
import FormField from "../../../../components/formFieldCollection"

interface QustionDialogInterface {
  questions: any[] | null;
  handleCancel: () => void;
  handleSubmit: () => void;
  handleChange: ({name, newValue, type, index}: any) => void
}

const QuestionsDialog = ({questions, handleCancel, handleSubmit, handleChange}: QustionDialogInterface) => {
  return (
    <DialogBox open={true} onClose={handleCancel} title="Equipment Setting" dialogStyles={{[NEW_BREAKPOINT_OPTIONS.tablet]: {width: '100% !important', maxWidth: '360px', }, width: '100% !important', margin: '0 30px'}}>
      <Stack rowGap={6}>
        {
          questions && questions?.length > 0 && questions?.map((question: any, index: number) => {
            return (
              <Stack rowGap={2} key={index}>
                <FormField key={question?._id} {...question} onChange={(name, newValue) => {handleChange({name, newValue, type: question?.type, index})}}  />
              </Stack>
            )
          })
        }
        <ButtonStack
          containerProps={{
            spacing: 5,
            direction: 'row',
          }}
          cancelButtonProps={{
            sx: {
              border: `1px solid ${Neutrals[500]}`,
              flex: 1,
              borderRadius: '4px',
              backgroundColor: 'white',
              color: `${Neutrals[500]}`,
              boxShadow: 'none',
              ':hover': { background: 'none', boxShadow: 'none' },
            },
          }}
          saveButtonProps={{
            sx: {
              flex: 1,
              ...ButtonStyle.confirmButtonStyles
            },
          }}
          onSave={handleSubmit}
          onCancel={handleCancel}
        />
      </Stack>
    </DialogBox>
  )
}

export default QuestionsDialog;