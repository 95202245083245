import * as joint from '@joint/plus';
const LEVEL1_FLAG = 'level1-flag';
const LEVEL2_FLAG = 'level2-flag';

export class DA_TANK extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: 'DA_TANK',
      size: {
        width: 250,
        height: 250,
      },
      liquidColor1: '#3DA3F5',
      liquidColor2: '#3DA3F5',
      attrs: {
        
         tank1Liquid: {
              x: 18.9905,
              y: 91.1858,
              width: 105.737,
              height: 91.8352,
              fill: '#3DA3F5',
          },
          tank2Liquid: {
              x: 124.727,
              y: 91.1858,
              width: 105.737,
              height: 91.8352,
              fill: '#3DA3F5',
          },
      },
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
            <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
              <rect x="125.137" y="88.1147" width="109.863" height="95.4185" fill="#3DA3F5"/>
              <path d="M125.019 70.8311V184.007H233.106C234.152 184.007 235 183.159 235 182.113V70.8311" stroke="#7A7A7A" stroke-width="1.89416" stroke-linecap="round"/>
              <path opacity="0.5" d="M180.068 88.1152L180.068 144.94" stroke="#7A7A7A" stroke-width="1.89416"/>
              <path d="M180.068 81.9592L180.068 88.1153" stroke="#7A7A7A" stroke-width="1.89416"/>
              <path d="M182.251 147.647L179.832 144.806L189.776 145.064L182.251 147.647Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <path d="M177.229 147.494L179.817 144.806L180.543 154.727L177.229 147.494Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <path d="M182.718 142.387L179.892 144.823L180.09 134.877L182.718 142.387Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <path d="M177.886 141.964L180.305 144.806L170.361 144.547L177.886 141.964Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <rect x="177.03" y="68.1008" width="6.31143" height="2.10829" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="177.03" y="79.6675" width="6.31143" height="2.10829" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="176.426" y="79.4666" width="7.52" height="0.268572" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="176.724" y="70.1987" width="6.94753" height="9.27589" fill="url(#paint0_linear_2920_352)" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M179.218 65.9927C178.054 65.9927 177.11 66.9366 177.11 68.101H183.274C183.274 66.9366 182.33 65.9927 181.166 65.9927H179.218Z" fill="url(#paint1_linear_2920_352)" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M178.145 69.0475C178.145 68.9369 178.07 68.8442 177.969 68.8196C177.941 68.8126 177.916 68.8373 177.916 68.8669L177.916 69.4444C177.916 69.474 177.941 69.4988 177.969 69.4917C178.07 69.4671 178.145 69.3744 178.145 69.2638L178.145 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M179.581 69.0475C179.581 68.9369 179.507 68.8442 179.406 68.8196C179.377 68.8126 179.353 68.8373 179.353 68.8669L179.353 69.4444C179.353 69.474 179.377 69.4988 179.406 69.4917C179.507 69.4671 179.581 69.3744 179.581 69.2638L179.581 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M181.018 69.0475C181.018 68.9369 180.944 68.8442 180.843 68.8196C180.814 68.8126 180.79 68.8373 180.79 68.8669L180.79 69.4444C180.79 69.474 180.814 69.4988 180.843 69.4917C180.944 69.4671 181.018 69.3744 181.018 69.2638L181.018 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M182.456 69.0475C182.456 68.9369 182.381 68.8442 182.281 68.8196C182.252 68.8126 182.228 68.8373 182.228 68.8669L182.228 69.4444C182.228 69.474 182.252 69.4988 182.281 69.4917C182.381 69.4671 182.456 69.3744 182.456 69.2638L182.456 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <rect x="176.837" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A"/>
              <rect x="177.062" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="177.287" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="177.513" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="177.738" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="177.963" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="178.189" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="178.414" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="178.639" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="178.865" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="179.09" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="179.315" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="179.541" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="179.766" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="179.991" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="180.217" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="180.442" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="180.667" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="180.893" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="181.118" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="181.343" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="181.569" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="181.794" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="182.019" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="182.245" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="182.47" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="182.695" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="182.921" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="183.146" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="183.371" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="181.93" y="70.9321" width="0.872857" height="7.87361" rx="0.0537143" fill="#181818"/>
              <rect x="179.997" y="70.9077" width="0.389429" height="7.87361" rx="0.0537143" fill="#181818"/>
              <rect x="179.487" y="71.761" width="1.41" height="4.61015" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="177.58" y="70.9321" width="0.872857" height="7.87361" rx="0.0537143" fill="#181818"/>
              <path d="M175.298 71.6055C175.298 71.4275 175.442 71.2832 175.62 71.2832H176.654C176.684 71.2832 176.708 71.3073 176.708 71.3369V77.1381C176.708 77.1677 176.684 77.1918 176.654 77.1918H175.62C175.442 77.1918 175.298 77.0475 175.298 76.8695V71.6055Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <circle cx="176.01" cy="72.5183" r="0.537143" fill="#181818"/>
              <path d="M183.664 71.3369C183.664 71.3073 183.688 71.2832 183.718 71.2832H184.752C184.93 71.2832 185.074 71.4275 185.074 71.6055V76.8695C185.074 77.0475 184.93 77.1918 184.752 77.1918H183.718C183.688 77.1918 183.664 77.1677 183.664 77.1381V71.3369Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <circle cx="184.375" cy="72.5183" r="0.537143" fill="#181818"/>
              <path d="M178.023 79.7551L178.023 81.7694" stroke="#7A7A7A" stroke-width="0.107429" stroke-linecap="square"/>
              <path d="M178.453 79.7551L178.453 81.7694" stroke="#7A7A7A" stroke-width="0.107429" stroke-linecap="square"/>
              <path d="M180.897 79.7551L180.897 81.7694" stroke="#7A7A7A" stroke-width="0.107429" stroke-linecap="square"/>
              <rect x="178.909" y="80.1377" width="2.55143" height="1.16829" rx="0.0537143" fill="white" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="176.707" y="80.1782" width="0.510286" height="0.537143" rx="0.0537143" fill="#181818"/>
              <rect x="183.153" y="80.1782" width="0.510286" height="0.537143" rx="0.0537143" fill="#181818"/>
              <rect x="15.2744" y="88.1147" width="109.863" height="95.4185" fill="#3DA3F5"/>
              <path d="M15.1562 70.8311V182.113C15.1562 183.159 16.0043 184.007 17.0504 184.007H125.137V70.8311" stroke="#7A7A7A" stroke-width="1.89416" stroke-linecap="round"/>
              <path opacity="0.5" d="M70.2053 88.1152L70.2053 144.94" stroke="#7A7A7A" stroke-width="1.89416"/>
              <path d="M70.2053 81.9592L70.2053 88.1153" stroke="#7A7A7A" stroke-width="1.89416"/>
              <path d="M72.3886 147.647L69.9697 144.806L79.9139 145.064L72.3886 147.647Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <path d="M67.3665 147.494L69.9546 144.806L70.6807 154.727L67.3665 147.494Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <path d="M72.8558 142.387L70.0293 144.823L70.2273 134.877L72.8558 142.387Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <path d="M68.024 141.964L70.4429 144.806L60.4987 144.547L68.024 141.964Z" fill="#D9D9D9" stroke="black" stroke-width="0.710312" stroke-linejoin="round"/>
              <rect x="67.167" y="68.1008" width="6.31143" height="2.10829" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="67.167" y="79.6675" width="6.31143" height="2.10829" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="66.5632" y="79.4666" width="7.52" height="0.268572" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="66.8616" y="70.1987" width="6.94753" height="9.27589" fill="url(#paint2_linear_2920_352)" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M69.3558 65.9927C68.1915 65.9927 67.2476 66.9366 67.2476 68.101H73.4113C73.4113 66.9366 72.4673 65.9927 71.303 65.9927H69.3558Z" fill="url(#paint3_linear_2920_352)" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M68.2825 69.0475C68.2825 68.9369 68.2078 68.8442 68.1074 68.8196C68.0786 68.8126 68.0542 68.8373 68.0542 68.8669L68.0542 69.4444C68.0542 69.474 68.0786 69.4988 68.1074 69.4917C68.2078 69.4671 68.2825 69.3744 68.2825 69.2638L68.2825 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M69.7192 69.0475C69.7192 68.9369 69.6446 68.8442 69.5442 68.8196C69.5154 68.8126 69.491 68.8373 69.491 68.8669L69.491 69.4444C69.491 69.474 69.5154 69.4988 69.5442 69.4917C69.6446 69.4671 69.7192 69.3744 69.7192 69.2638L69.7192 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M71.156 69.0475C71.156 68.9369 71.0813 68.8442 70.9809 68.8196C70.9521 68.8126 70.9277 68.8373 70.9277 68.8669L70.9277 69.4444C70.9277 69.474 70.9521 69.4988 70.9809 69.4917C71.0813 69.4671 71.156 69.3744 71.156 69.2638L71.156 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M72.5935 69.0475C72.5935 68.9369 72.5188 68.8442 72.4184 68.8196C72.3896 68.8126 72.3652 68.8373 72.3652 68.8669L72.3652 69.4444C72.3652 69.474 72.3896 69.4988 72.4184 69.4917C72.5188 69.4671 72.5935 69.3744 72.5935 69.2638L72.5935 69.0475Z" fill="white" stroke="#181818" stroke-width="0.0805715"/>
              <rect x="66.9744" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A"/>
              <rect x="67.2" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="67.4253" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="67.6506" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="67.8757" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="68.1013" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="68.3264" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.8"/>
              <rect x="68.5518" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="68.7771" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="69.0024" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="69.2278" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="69.4531" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="69.6785" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="69.9038" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="70.1292" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.6"/>
              <rect x="70.3545" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="70.5798" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="70.8052" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="71.0305" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="71.2556" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="71.4812" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="71.7063" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="71.9316" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.4"/>
              <rect x="72.157" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="72.3823" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="72.6077" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="72.833" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="73.0583" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.2"/>
              <rect x="73.2837" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="73.509" y="70.1995" width="0.0751084" height="9.27589" rx="0.0277323" fill="#7A7A7A" fill-opacity="0.1"/>
              <rect x="72.0679" y="70.9321" width="0.872857" height="7.87361" rx="0.0537143" fill="#181818"/>
              <rect x="70.1345" y="70.9077" width="0.389429" height="7.87361" rx="0.0537143" fill="#181818"/>
              <rect x="69.6243" y="71.7607" width="1.41" height="4.61015" rx="0.0537143" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="67.7175" y="70.9321" width="0.872857" height="7.87361" rx="0.0537143" fill="#181818"/>
              <path d="M65.4355 71.6055C65.4355 71.4275 65.5798 71.2832 65.7578 71.2832H66.7918C66.8215 71.2832 66.8455 71.3073 66.8455 71.3369V77.1381C66.8455 77.1677 66.8215 77.1918 66.7918 77.1918H65.7578C65.5798 77.1918 65.4355 77.0475 65.4355 76.8695V71.6055Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <circle cx="66.1472" cy="72.5183" r="0.537143" fill="#181818"/>
              <path d="M73.8015 71.3369C73.8015 71.3073 73.8256 71.2832 73.8552 71.2832H74.8892C75.0672 71.2832 75.2115 71.4275 75.2115 71.6055V76.8695C75.2115 77.0475 75.0672 77.1918 74.8892 77.1918H73.8552C73.8256 77.1918 73.8015 77.1677 73.8015 77.1381V71.3369Z" fill="#D9D9D9" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <circle cx="74.513" cy="72.5183" r="0.537143" fill="#181818"/>
              <path d="M68.1604 79.7551L68.1604 81.7694" stroke="#7A7A7A" stroke-width="0.107429" stroke-linecap="square"/>
              <path d="M68.5906 79.7551L68.5906 81.7694" stroke="#7A7A7A" stroke-width="0.107429" stroke-linecap="square"/>
              <path d="M71.0347 79.7551L71.0347 81.7694" stroke="#7A7A7A" stroke-width="0.107429" stroke-linecap="square"/>
              <rect x="69.0464" y="80.1377" width="2.55143" height="1.16829" rx="0.0537143" fill="white" stroke="#7A7A7A" stroke-width="0.0962622"/>
              <rect x="66.8442" y="80.1782" width="0.510286" height="0.537143" rx="0.0537143" fill="#181818"/>
              <rect x="73.29" y="80.1782" width="0.510286" height="0.537143" rx="0.0537143" fill="#181818"/>
              <path d="M15 82.7876L235 82.7876" stroke="#7A7A7A" stroke-width="1.89416" stroke-linecap="square"/>
              <defs>
              <linearGradient id="paint0_linear_2920_352" x1="183.672" y1="74.8367" x2="176.724" y2="74.8367" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint1_linear_2920_352" x1="183.274" y1="67.0468" x2="177.11" y2="67.0468" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint2_linear_2920_352" x1="73.8091" y1="74.8367" x2="66.8616" y2="74.8367" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              <linearGradient id="paint3_linear_2920_352" x1="73.4113" y1="67.0468" x2="67.2476" y2="67.0468" gradientUnits="userSpaceOnUse">
              <stop stop-color="#8A8A8A"/>
              <stop offset="0.1" stop-color="#B1B1B1"/>
              <stop offset="0.2" stop-color="#E3E3E3"/>
              <stop offset="0.4" stop-color="white"/>
              <stop offset="0.6" stop-color="white"/>
              <stop offset="0.8" stop-color="#E3E3E3"/>
              <stop offset="0.9" stop-color="#B1B1B1"/>
              <stop offset="1" stop-color="#848685"/>
              </linearGradient>
              </defs>
            </svg>
        `;
  }
}



export class OSD_TANKView extends joint.dia.ElementView {
    presentationAttributes() {
        return joint.dia.ElementView.addPresentationAttributes({
            level1: [LEVEL1_FLAG],
            level2: [LEVEL2_FLAG],
            liquidColor1: [LEVEL1_FLAG],
            liquidColor2: [LEVEL2_FLAG],
        });
    }

    initFlag() {
        return [joint.dia.ElementView.Flags.RENDER, LEVEL1_FLAG, LEVEL2_FLAG];
    }

    // @ts-ignore
    confirmUpdate(...args) {
        // @ts-ignore
        let flags = joint.dia.ElementView.prototype.confirmUpdate.call(this, ...args);

        if (this.hasFlag(flags, LEVEL1_FLAG)) {
            this.updateLevel1();
            flags = this.removeFlag(flags, LEVEL1_FLAG);
        }

        if (this.hasFlag(flags, LEVEL2_FLAG)) {
            this.updateLevel2();
            flags = this.removeFlag(flags, LEVEL2_FLAG);
        }

        return flags;
    }

    getElement(element: any) {
        return this.findNode(element)
    }

    // Update the liquid level for tank 1 with animation
    updateLevel1() {
        const model = this.model;
        // Ensure level is between 0 and 100
        const level = Math.max(0, Math.min(100, model.get('level1') || 0));
        const liquidColor = model.get('liquidColor1') || '#3DA3F5';

        // Get the liquid element and tank path
        const liquidEl = this.getElement('tank1Liquid');
        const tankPathEl = this.getElement('tank1Path');
        const horizontalLineEl = this.getElement('horizontalLine');
        if (!liquidEl) return
        // Calculate the tank's dimensions
        // @ts-ignore
        const tankBBox = tankPathEl.getBBox();
        // @ts-ignore
        const horizontalLineBBox = horizontalLineEl.getBBox();

        // Calculate the usable height (from horizontal line to bottom of tank)
        const tankBottom = tankBBox.y + tankBBox.height;
        const tankTop = horizontalLineBBox.y;
        const maxLiquidHeight = tankBottom - tankTop;

        // Calculate the new height based on level percentage
        const newHeight = (maxLiquidHeight * level) / 100;

        // Calculate the y position (from bottom up)
        const newY = tankBottom - newHeight;

        // Animate the liquid element
        liquidEl?.animate(
            {
                height: [`${newHeight}px`],
                y: newY,
                fill: liquidColor,
            },
            {
                fill: 'forwards',
                duration: 800,
                easing: 'ease-in-out',
            }
        );
    }

    // Update the liquid level for tank 2 with animation
    updateLevel2() {
        const model = this.model;
        // Ensure level is between 0 and 100
        const level = Math.max(0, Math.min(100, model.get('level2') || 0));
        const liquidColor = model.get('liquidColor2') || '#3DA3F5';

        // Get the liquid element and tank path
        const liquidEl = this.getElement('tank2Liquid');
        const tankPathEl = this.getElement('tank2Path');
        const horizontalLineEl = this.getElement('horizontalLine');

        // Calculate the tank's dimensions\
        // @ts-ignore
        const tankBBox = tankPathEl.getBBox();
        // @ts-ignore
        const horizontalLineBBox = horizontalLineEl.getBBox();

        // Calculate the usable height (from horizontal line to bottom of tank)
        const tankBottom = tankBBox.y + tankBBox.height;
        const tankTop = horizontalLineBBox.y;
        const maxLiquidHeight = tankBottom - tankTop;

        // Calculate the new height based on level percentage
        const newHeight = (maxLiquidHeight * level) / 100;

        // Calculate the y position (from bottom up)
        const newY = tankBottom - newHeight;

        // Animate the liquid element
        liquidEl?.animate(
            {
                height: [`${newHeight}px`],
                y: newY,
                fill: liquidColor,
            },
            {
                fill: 'forwards',
                duration: 800,
                easing: 'ease-in-out',
            }
        );
    }
}
