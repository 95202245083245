import * as joint from '@joint/plus';
const LEVEL_FLAG = 'level-flag';

export class OS_TANK_SP extends joint.dia.Element {
  defaults() {
    return {
      ...super.defaults,
      type: "OS_TANK_SP",
      size: {
        width: 250,
        height: 250
      },
      attrs: {
        root: {
            magnet: true
        },
        liquid: {
            x: 4.00012,
            y: 105.617,
            width: 241.965,
            height: 112.072,
            fill: "#DBA670"
        }
      }
    };
  }

  preinitialize() {
    this.markup = joint.util.svg/* xml */ `
      <svg xmlns="http://www.w3.org/2000/svg" width="250" height="250" viewBox="0 0 250 250" fill="none">
        <rect @selector="liquid" />
        <path d="M4 32.3108V216.219C4 217.031 4.65806 217.689 5.46981 217.689H244.53C245.342 217.689 246 217.031 246 216.219V32.3108" stroke="#7A7A7A" stroke-width="2.93959" stroke-linecap="round"/>
    </svg>
       `;
  }
}

export class OS_TANK_SPView extends joint.dia.ElementView {
    // @ts-ignore
    presentationAttributes = joint.dia.ElementView.addPresentationAttributes({
        level: [LEVEL_FLAG],
        liquidColor: [LEVEL_FLAG],
    });

    // @ts-ignore
    // initFlag = [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    initFlag() {
        return [joint.dia.ElementView.Flags.RENDER, LEVEL_FLAG];
    }
    confirmUpdate(...args: any[]): any {
        // @ts-ignore
        let flags = joint.dia.ElementView.prototype.confirmUpdate.call(this, ...args);
        if (this.hasFlag(flags, LEVEL_FLAG)) {
            this.updateLevel();
            flags = this.removeFlag(flags, LEVEL_FLAG);
        }
        return flags;
    }

    getElement(element: any) {
        return this.findNode(element)
    }

    updateLevel() {
        const model = this.model;
        const level = Math.max(0, Math.min(100, model.get('level') || 0));
        const liquidColor = model.get('liquidColor') || '#DBA670';

        const liquidEl = this.getElement('liquid');
        const tankEl = this.getElement('tank');

        // @ts-ignore
        const tankBBox = tankEl.getBBox();
        const tankHeight = tankBBox.height;
        const tankBottom = tankBBox.y + tankHeight;
        const maxLiquidHeight = tankHeight * 0.85;
        const newHeight = (maxLiquidHeight * level) / 100;

        // Calculate the y position (from bottom up)
        const newY = tankBottom - newHeight;

        // Animate the liquid element
        liquidEl?.animate(
            {
                height: [`${newHeight}px`],
                y: newY,
                fill: liquidColor,
            },
            {
                fill: 'forwards',
                duration: 800,
                easing: 'ease-in-out',
            }
        );
    }
}