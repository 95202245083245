import { Autocomplete, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from "@mui/material"
import { useState } from "react";
import { NEW_BREAKPOINT_OPTIONS } from "../../constants/breakPoints.constants";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { dateInMilliSecond } from "../../utils/widget.utils";
import dayjs from "dayjs";

interface FormFieldProps {
  options: any,
  type: string,
  label: string,
  answer: any,
  placeholder?: string,
  comment?: string | null | undefined,
  onChange: (name: string, value: any) => void,
  error: string,
  disabled?: boolean,
  question: string,
  displayKey: string,
  keyToPick: string
}

interface Option {
  [key: string]: any;
}

const FormField = ({options, type, answer='', error, disabled=false, placeholder = '', comment = '', question, displayKey = 'name', keyToPick = 'id', onChange}: FormFieldProps) => {
  const [inputValue, setInputValue] = useState('');
  const label = question
  const renderFormField = () => {
    switch (type) {
      case "large":
        return (
          renderLargeField()
        )
      case "autocomplete":
        return (
        renderAutoComplete()
      )
      case "checkbox":
        return (
        renderCheckbox()
      )
      case "radio":
        return (
        renderRadio()
      )
      case "dropdown":
        return (
          renderDropdown()
        )
      case "checkbox_with_label":
        return (
          renderCheckboxWithLabel()
        )
      case "date" :
        return (
        renderDateDropDown()
      )
      default:  
        return (
          renderInputField()
        )
    }
  }

  const handleCheckboxChange = (e: any) => {
    let {checked, value, name} = e.target;
    let newValue = answer || [];
    if(checked) {
      newValue.push(value);
    }
    else {
        newValue = answer.filter((x: string) => x !== value)
    }
    onChange(name, newValue);
  }

  const handleChange = (e: any) => {
    let {name, value} = e.target;
    onChange(name, value);
  }

  const handleAutoCompleteChange = (newValue: any, name: string) => {
    onChange(name, newValue);
  }

  const handleDateChange = (newValue: any) => {
    const dateInMilliSec = dateInMilliSecond(newValue)
    onChange('answer', dateInMilliSec);
  }

  const renderInputField = () => {
    return (
      <Stack rowGap={4}>
        <Typography variant="body">{label}</Typography>
        <TextField
          autoComplete='off'
          value={answer}
          onChange={handleChange}
          name='answer'
          fullWidth
          label={label}
          error={!!error}
          helperText={error}
          disabled={disabled}
          placeholder={placeholder}
        />
      </Stack>
    )
  }
  const renderLargeField = () => {
    return (
      <Stack rowGap={4}>
        <Typography variant="body">{label}</Typography>
        <TextField
          autoComplete='off'
          value={answer}
          onChange={handleChange}
          multiline
          minRows={4}
          maxRows={4}
          fullWidth
          label={label}
          error={!!error}
          helperText={error}
          disabled={disabled}
          placeholder={placeholder}
          name='answer'
        />
      </Stack>
    )
  }
  const renderAutoComplete = () => {
    return (
      <Stack rowGap={4}>
        <Typography variant="body">{label}</Typography>
        <Autocomplete
          value={answer}
          onChange={(e, newValue) => handleAutoCompleteChange(newValue, 'answer')}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={label + '-auto-complete'}
          options={options}
          renderInput={(params) => <TextField {...params} error={!!error} helperText={error} label={label} placeholder={placeholder} />}
          fullWidth
          disabled={disabled}
          getOptionLabel={(option: Option | string) => {
            console.log()
            if (typeof option === 'string') return option;
            return option[displayKey];
          }}
        />
      </Stack>
    )
  }
  const renderCheckbox = () => {
    return (
      <FormControl
        variant="standard"
        error={!!error}
        disabled={disabled}
      >
        <Stack rowGap={2}>
          <Typography variant="body">{label}</Typography>
          <FormGroup>
            <Stack columnGap={6} rowGap={1} flexWrap={'wrap'} direction={"row"}>
              {
                options?.length &&
                options?.map((option: any, index: number) => {
                  return <FormControlLabel key={option + index} control={<Checkbox checked={answer?.includes(option)} onChange={handleCheckboxChange} value={option} name='answer' />} label={option} />
                })
              }
            </Stack>
          </FormGroup>
        </Stack>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    )
  }
  const renderRadio = () => {
    return (
      <FormControl error={!!error} disabled={disabled}>
        {/* <FormLabel id={label + "-radio-group"}>{label}</FormLabel> */}
        <Typography variant="body1">{label}</Typography>
        <RadioGroup
          row
          aria-labelledby={label + "-radio-group"}
          name={'answer'}
          value={answer}
          onChange={handleChange}
        >
          {
                options?.length &&
                 options?.map((option: any, index: number) => {
                  return <FormControlLabel key={option + index} value={option} control={<Radio value={option} />} label={option} />
                 })
          }
        </RadioGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    )
  }
  const renderDropdown = () => {
    return (
      <Stack rowGap={4}>
        <Typography variant="body">{label}</Typography>
        <Stack rowGap={4} columnGap={4} sx={{[NEW_BREAKPOINT_OPTIONS.tablet]: {flexDirection: 'row'}}}>
          <FormControl sx={{ minWidth: 120, flex: 1 }} fullWidth error={!!error} disabled={disabled}>
            <InputLabel id={label + "select-dropdown"}>{label}</InputLabel>
            <Select
              id={label + "select-dropdown"}
              value={answer}
              label={label}
              sx={{flex: 1}}
              name='answer'
              onChange={handleChange}
              fullWidth
            >
              {
                options?.length > 0 &&
                options?.map((option: any, index: number) => {
                  return <MenuItem key={option + index} value={option}>{option}</MenuItem>
                })
              }
            </Select>
            <FormHelperText>{error}</FormHelperText>
          </FormControl>
          {/* <CustomAutocomplete 
            value={formData.sensorTag || ''}
              onSelect={handlePageSelection}
              label={label}
              error={!!error}
              errorText={error}
              disabled={disabled}
              defaultOptions={options}
          /> */}
          {/* <CustomAsyncAutocomplete
              value={answer || ''}
              onSelect={handlePageSelection}
              label={label}
              error={!!error}
              errorText={error}
              disabled={disabled}
              defaultOptions={options}
              fullWidth={true}
          /> */}
          {
            answer === 'Other' &&
            <TextField
              autoComplete='off'
              value={comment}
              onChange={handleChange}
              fullWidth
              name="comment"
              label="Comment (Optional)"
              sx={{flex: 1}}
              disabled={disabled}
            />
          }
        </Stack>
      </Stack>
    )
  }

  const renderCheckboxWithLabel = () => {
    return (
      <FormControl
        variant="standard"
        error={!!error}
        disabled={disabled}
        fullWidth
      >
        {/* <Stack rowGap={2} justifyContent={'space-between'}> */}
          {/* <Typography variant="body">{label}</Typography> */}
          <FormGroup>
            <Stack columnGap={6} rowGap={1} flexWrap={'wrap'} direction={"row"} justifyContent={'space-between'} sx={{[NEW_BREAKPOINT_OPTIONS.tablet]: {flexDirection: 'row'}}}>
              <FormControlLabel sx={{width: '100%', [NEW_BREAKPOINT_OPTIONS.tablet]: {maxWidth: '50%'}}} control={<Checkbox name="answer" checked={answer?.checked} onChange={handleChange} />} label={label} />
              <TextField
                autoComplete='off'
                name="comment"
                label="Comment (Optional)"
                value={comment}
                onChange={handleChange}
                disabled={disabled}
                sx={{width: '100%', [NEW_BREAKPOINT_OPTIONS.tablet]: {maxWidth: '40%'}}}
              />
            </Stack>
          </FormGroup>
        {/* </Stack> */}
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    )
  }

  const renderDateDropDown = () => {
    return (
      <Stack rowGap={4}>
        <Typography variant="body">{label}</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker 
           disabled={disabled}
           value={answer ? dayjs(answer) : null}
           onChange={handleDateChange}
           label={label}
           name="answer"   
           format='DD/MM/YYYY'
          />
        </LocalizationProvider>
      </Stack>
    )
  }

  return (
    <div className="form-field-container">
      {renderFormField()}
    </div>
  )
}

export default FormField;